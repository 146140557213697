import React, { useState, useRef }  from "react";  
import { ScannerModal } from "../scanner/ScannerModal"; 

export default function Home(props) {
    
        const [code,setCode] = useState(false);

        function confirmBarcode(code) {
            setCode(code);
            props.setModalIsOpen(false);
        }
   
        function scan() {
            props.setModalContent({
                component: ScannerModal,
                params: {
                    confirmBarcode: confirmBarcode
                }
              }) 
             props.setModalIsOpen(true)
        }

       
   
        return (
             <> 
              
             
              <div className="mb-3 px-6 py-3 lg:px-12 ">

                <div className="relative">

                    <div className="  flex-shrink-0   lg:top-2 z-30 text-left"> 
                        <button onClick={() => scan()} className="bg-gray-100 px-5 py-2 rounded-md hover:bg-gray-200">Skenovat</button>
                    </div>
                        
                        <span className="text-4xl">{code}</span>
                </div>
            </div> 
            
  
       
            </>
        )
    }
 