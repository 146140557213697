

import React, { useState, useEffect } from "react";  
import {Transition} from "@headlessui/react"; 
import InvoicePrint from "../invoice/print"; 

export const Print = (props) => { 

    let Component = props.printContent?.component
    let dynamicProps = props.printContent?.params
    if(props.printContent?.component==='InvoicePrint') {
        Component = InvoicePrint
    }
        
    const printClose = props.printClose 
    const setPrintIsOpen = props.setPrintIsOpen 
    
    let printIsOpen = false;
    if(props.electron) {
       printIsOpen = true
    } else {
       printIsOpen = props.printIsOpen
    }

    function close() {
        setPrintIsOpen(false);
        printClose();
    }
      
    const [printCont ] = useState();
    const response = props.response;
    const setResponse = props.setResponse;
     
    useEffect(() => { 
        // eslint-disable-next-line
    }, [printCont]);

    async function callPrint() {
        await props.setPrintContent(JSON.parse(localStorage.getItem("print")))
        await setResponse(false)
    }
    
    useEffect(() => { 
        if(response)  {
          if(response.print && props.electron) {
            callPrint()
            setTimeout(function () {
                console.log('print')
            }, 2000);
            
          }
        }
        // eslint-disable-next-line
    }, [response]);
 

    return (
        <>
        {props.electron ? (
            <>
                            {Component &&
                                <Component  
                                {...dynamicProps}
                                {...props}
                                />  
                            }
            </>
        ) : (
            <>
 <Transition show={printIsOpen} className="absolute z-75 inset-0  overflow-y-auto h-full">
            <div className="  items-start  overflow-y-auto   pb-20 lg:pb-0 text-center sm:block sm:p-0 sm:pt-6">
             
                <Transition.Child
                    className=" fixed inset-0 transition-opacity"
                    aria-hidden="true"
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="absolute inset-0 bg-black opacity-50"  />
                </Transition.Child>
                <Transition.Child>
               
                </Transition.Child> 
                <span className={"hidden sm:inline-block sm:align-top " + (props.electron ? "sm:h-screen-electron" : "sm:h-screen")} aria-hidden="true" style={props.electron ? {height: "calc(100vh - 6rem - 30px)"} : {height: "calc(100vh - 6rem)"}}>&#8203;</span>
                 
                            
                <Transition.Child
                    className="inline-block align-bottom  rounded-lg sm:mx-2 text-left   transform transition-all lg:mb-10 sm:align-top sm:max-w-4xl w-full  h-full"
                    role="dialog" aria-modal="true" aria-labelledby="modal-headline"
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                    
                     <div className=" absolute sm:top-2  right-2 mx-auto  z-75" onClick={() => close()}>
                        <button className="ml-1 flex items-center justify-center h-10 w-10 z-75 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white ">
                            <span className="sr-only">Close sidebar</span>
                        
                            <svg className="h-6 w-6 " xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>
                        
                    <div  className=" align-top bg-white  rounded-lg  text-left  shadow-xl sm:transform  transition-all w-full   ">
                        <div className=" p-8 h-full">
                            <div id="printbuttons" className="grid  justify-items-end  pr-10 ">
                                <button className="bg-gray-200 px-5 py-3 rounded-md focus:outline-none flex items-center space-x-3 hover:bg-gray-300"  onClick={() => window.print()}>
                                    <svg className="w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z" />
                                    </svg>
                                    <span>Tisk</span>  
                               </button>
                            </div>
                                <div className="max-w-2xl">

                            {Component &&
                                <Component  
                                {...dynamicProps}
                                {...props}
                                />  
                            }
                                </div>
                        
                        </div>
                    </div>
                </Transition.Child>
            </div>
        </Transition>

            </>
        )}
           
        </>
    );
}
 