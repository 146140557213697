import React, { useState } from "react";
import UsersLaout from "./usersLayout";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { Error } from "../form/error";
import { axiosProtected } from "../../client";

export default function UsersCreate(props) {
  const history = useHistory();

  const user = props.user;

  const [selectedType, setType] = useState("personal");
  const [degree, setDegree] = useState();
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
  });

  const onSubmit = async (data) => {
    setLoading(true);
    await axiosProtected()
      .post("users_data/add/", data)
      .then((res) => {
        setLoading(false);
        history.push("/users/" + res?.data?.users_data_id);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const TypeButton = ({ text, type, ...props }) => {
    return (
      <>
        <button
          type="button"
          onClick={() => setType(type)}
          className={
            "focus:outline-none border-2 border-gray-200 px-5 py-2 w-36 rounded-md  font-medium flex  items-center   " +
            (selectedType === type
              ? "border-blue-200 text-white bg-blue-500 "
              : "bg-gray-100 text-gray-400")
          }
        >
          {props.children}
        </button>
      </>
    );
  };

  return (
    <>
      <UsersLaout {...props}>
        <main class=" lg:p-8 bg-gray-100 max-w-4xl">
          <div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
            <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-10">
              {/* <!-- Billing history --> */}
              <section aria-labelledby="billing_history_heading">
                <div className="bg-white shadow sm:rounded-md sm:overflow-hidden p-8">
                  <>
                    <div className="text-xl lg:text-2xl w-full ">
                      Nový kontakt
                    </div>

                    <form
                      key={1}
                      onSubmit={handleSubmit(onSubmit)}
                      className={"space-y-8  py-2"}
                    >
                      <div className="space-x-2 flex ">
                        <TypeButton type={"personal"}>
                          <svg
                            className="w-5 mx-2"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                            />
                          </svg>
                          Osobní
                        </TypeButton>
                        <TypeButton type={"business"}>
                          <svg
                            className="w-5 mx-2"
                            fill="currentColor"
                            viewBox="0 -31 512 512"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="m497.09375 60.003906c-.03125 0-.0625-.003906-.09375-.003906h-136v-15c0-24.8125-20.1875-45-45-45h-120c-24.8125 0-45 20.1875-45 45v15h-136c-8.351562 0-15 6.84375-15 15v330c0 24.8125 20.1875 45 
                                45 45h422c24.8125 0 45-20.1875 45-45v-329.683594c0-.019531 0-.039062 0-.058594-.574219-9.851562-6.632812-15.199218-14.90625-15.253906zm-316.09375-15.003906c0-8.269531 6.730469-15 15-15h120c8.269531 
                                0 15 6.730469 15 15v15h-150zm295.1875 45-46.582031 139.742188c-2.042969 6.136718-7.761719 10.257812-14.226563 10.257812h-84.378906v-15c0-8.285156-6.714844-15-15-15h-120c-8.285156 0-15 6.714844-15 
                                15v15h-84.378906c-6.464844 0-12.183594-4.121094-14.226563-10.257812l-46.582031-139.742188zm-175.1875 150v30h-90v-30zm181 165c0 8.269531-6.730469 15-15 15h-422c-8.269531 0-15-6.730469-15-15v-237.566406l23.933594 
                                71.796875c6.132812 18.40625 23.289062 30.769531 42.6875 30.769531h84.378906v15c0 8.285156 6.714844 15 15 15h120c8.285156 0 15-6.714844 15-15v-15h84.378906c19.398438 0 36.554688-12.363281 
                                42.6875-30.769531l23.933594-71.796875zm0 0"
                            />
                          </svg>
                          Firemní
                        </TypeButton>
                      </div>

                      <div className=" grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-6 relative ">
                        {selectedType === "personal" ? (
                          <>
                            <div className="sm:col-span-3">
                              <label
                                htmlFor="first_name"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Jméno
                              </label>
                              <div className="mt-1">
                                <input
                                  type="text"
                                  name="fname"
                                  id="first_name"
                                  {...register("fname", { required: true })}
                                  className="shadow-sm focus:ring-green-500 focus:border-green-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                />
                                {errors.fname && <Error text="Vyplňte jméno" />}
                              </div>
                            </div>

                            <div className="sm:col-span-3">
                              <label
                                htmlFor="last_name"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Příjmení
                              </label>
                              <div className="mt-1">
                                <input
                                  type="text"
                                  name="surname"
                                  id="last_name"
                                  {...register("surname", { required: true })}
                                  className="shadow-sm focus:ring-green-500 focus:border-green-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                />
                                {errors.surname && (
                                  <Error text="Vyplňte příjmení" />
                                )}
                              </div>
                            </div>

                            {degree && (
                              <>
                                <div className="sm:col-span-3">
                                  <label
                                    htmlFor="pre"
                                    className="block text-sm font-medium text-gray-700"
                                  >
                                    Titul před jménem
                                  </label>
                                  <div className="mt-1">
                                    <input
                                      type="text"
                                      name="pre"
                                      id="pre"
                                      {...register("pre")}
                                      className="shadow-sm focus:ring-green-500 focus:border-green-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                    />
                                  </div>
                                </div>
                                <div className="sm:col-span-3">
                                  <label
                                    htmlFor="post"
                                    className="block text-sm font-medium text-gray-700"
                                  >
                                    Titul za jménem
                                  </label>
                                  <div className="mt-1">
                                    <input
                                      type="text"
                                      name="post"
                                      id="post"
                                      {...register("post")}
                                      className="shadow-sm focus:ring-green-500 focus:border-green-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                    />
                                  </div>
                                </div>
                              </>
                            )}
                            <div className="sm:col-span-6 flex justify-end text-blue-500 absolute right-0">
                              {degree ? (
                                <button
                                  type="button"
                                  onClick={() => setDegree(!degree)}
                                  className="text-sm font-medium focus:outline-none flex items-center"
                                >
                                  <svg
                                    className="inline h-5 mr-1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth={2}
                                      d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                                    />
                                  </svg>{" "}
                                  Skrýt titul
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  onClick={() => setDegree(!degree)}
                                  className="text-sm font-medium focus:outline-none flex items-center "
                                >
                                  <svg
                                    className="inline h-5 mr-1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth={2}
                                      d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                                    />
                                  </svg>{" "}
                                  Přidat titul
                                </button>
                              )}
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="sm:col-span-3">
                              <label
                                htmlFor="company"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Název firmy
                              </label>
                              <div className="mt-1">
                                <input
                                  type="text"
                                  name="company"
                                  id="company"
                                  {...register("company", { required: true })}
                                  className="shadow-sm focus:ring-green-500 focus:border-green-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                />
                                {errors.fname && (
                                  <Error text="Vyplňte název firmy" />
                                )}
                              </div>
                            </div>

                            <div className="sm:col-span-3">
                              <label
                                htmlFor="inum"
                                className="block text-sm font-medium text-gray-700"
                              >
                                IČ
                              </label>
                              <div className="mt-1">
                                <input
                                  type="text"
                                  name="inum"
                                  id="inum"
                                  {...register("inum", { required: true })}
                                  className="shadow-sm focus:ring-green-500 focus:border-green-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                />
                                {errors.surname && <Error text="Vyplňte IČ" />}
                              </div>
                            </div>
                          </>
                        )}

                        <div className="sm:col-span-4">
                          <label
                            htmlFor="email"
                            className="block text-sm font-medium text-gray-700"
                          >
                            E-mail
                          </label>
                          <div className="mt-1">
                            <input
                              id="email"
                              name="email"
                              type="email"
                              {...register("email", { required: true })}
                              className="shadow-sm focus:ring-green-500 focus:border-green-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            />
                            {errors.email && <Error text="Vyplňte e-mail" />}
                          </div>
                        </div>
                        <div className="sm:col-span-2">
                          <label
                            htmlFor="tel"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Telefon
                          </label>
                          <div className="mt-1">
                            <input
                              type="text"
                              name="tel"
                              id="tel"
                              {...register("tel", { required: true })}
                              className="shadow-sm focus:ring-green-500 focus:border-green-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            />
                            {errors.tel && <Error text="Vyplňte telefon" />}
                          </div>
                        </div>
                        <div className="sm:col-span-6">
                          <label
                            htmlFor="street"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Ulice, číslo domu
                          </label>
                          <div className="mt-1">
                            <input
                              type="text"
                              name="street"
                              id="street"
                              {...register("street")}
                              className="shadow-sm focus:ring-green-500 focus:border-green-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-4">
                          <label
                            htmlFor="city"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Město
                          </label>
                          <div className="mt-1">
                            <input
                              type="text"
                              name="city"
                              id="city"
                              {...register("city")}
                              className="shadow-sm focus:ring-green-500 focus:border-green-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-2">
                          <label
                            htmlFor="last_name"
                            className="block text-sm font-medium text-gray-700"
                          >
                            PSČ
                          </label>
                          <div className="mt-1">
                            <input
                              type="text"
                              name="zip"
                              id="zip"
                              {...register("zip")}
                              className="shadow-sm focus:ring-green-500 focus:border-green-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-2 mt-3">
                          {loading ? (
                            <button
                              disabled
                              className="bg-gray-200 text-gray-800 font-bold py-4 px-4 w-full rounded cursor-default"
                            >
                              <svg
                                className="animate-spin -ml-1 mr-3 h-5 w-5 inline"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <circle
                                  className="opacity-25"
                                  cx="12"
                                  cy="12"
                                  r="10"
                                  stroke="currentColor"
                                  strokeWidth="4"
                                ></circle>
                                <path
                                  className="opacity-75"
                                  fill="currentColor"
                                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                ></path>
                              </svg>
                              <span>Odesílání...</span>
                            </button>
                          ) : (
                            <>
                              <button
                                type="submit"
                                className="bg-gray-700 text-white font-bold py-4 px-20  rounded hover:bg-gray-600"
                              >
                                Vložit
                              </button>
                            </>
                          )}
                        </div>
                      </div>
                    </form>
                  </>
                </div>
              </section>
            </div>
          </div>
        </main>
      </UsersLaout>
    </>
  );
}
