import { useState, useEffect } from "react";
import {
  format,
  subMonths,
  addMonths,
  subYears,
  addYears,
  getDaysInMonth,
  getDay,
  isWeekend
} from "date-fns";
import { cs } from 'date-fns/locale'
import moment from 'moment'
import 'moment/locale/cs'; 
import StudioSubmenu from "./StudioSubmenu";

const types = ["date","month","year"];

export const SmallCalendar = ({startDate,changeDate,view,setView,today,slideModalOpen,disabledResources,setDisabledResources}) => {
  const DAYS = ["Po", "Út", "St", "Čt", "Pá", "So", "Ne"];
  const [dayCount, setDayCount] = useState([]);
  const [previousDays, setPreviousDays] = useState([]);
  const [nextDays, setNextDays] = useState([]);
  const [blankDays, setBlankDays] = useState([]);
  const [showDate, setShowDate] = useState(false);
  const [datepickerHeaderDate, setDatepickerHeaderDate] = useState(startDate);
  const [selectedDate, setSelectedDate] = useState(startDate);
  const [type, setType] = useState(types[0]);
 
  const decrement = () => {
      // eslint-disable-next-line
    switch (type) {
      case "date":
        setDatepickerHeaderDate((prev) => subMonths(prev, 1));
        break;
      case "month":
        setDatepickerHeaderDate((prev) => subYears(prev, 1));
        break;
      case "year":
        setDatepickerHeaderDate((prev) => subMonths(prev, 1));
        break;
    }
  };

  const increment = () => {
      // eslint-disable-next-line
    switch (type) {
      case "date":
        setDatepickerHeaderDate((prev) => addMonths(prev, 1));
        break;
      case "month":
        setDatepickerHeaderDate((prev) => addYears(prev, 1));
        break;
      case "year":
        setDatepickerHeaderDate((prev) => subMonths(prev, 1));
        break;
    }
  };

  
  const isToday = (date,type) => {
      let res = false;
      let finalDate = new Date(datepickerHeaderDate.getFullYear(),datepickerHeaderDate.getMonth(),date);
      if(type==='previous') {
        finalDate = subMonths(finalDate,1);
      } else if(type==='next') {
        finalDate = addMonths(finalDate,1);
      }
      if(format(selectedDate, 'yyyy-MM-dd', {locale: cs}) === format(finalDate, 'yyyy-MM-dd', {locale: cs})) {
          res = true;
      }
     
      return res
  }

  const todayMark = (date) => {
      let res = false;
      let finalDate = new Date(datepickerHeaderDate.getFullYear(),datepickerHeaderDate.getMonth(),date);
      if(format(new Date(), 'yyyy-MM-dd', {locale: cs}) === format(finalDate, 'yyyy-MM-dd', {locale: cs})) {
          res = true;
      }
     
      return res
  }

  const isDayWeekend = (date,type) => {
    let finalDate
    let subDate
    if(type==='previous') {
      finalDate = new Date(datepickerHeaderDate.getFullYear(),datepickerHeaderDate.getMonth(),1);
      subDate = subMonths(finalDate,1);
      
      finalDate = new Date(subDate.getFullYear(),subDate.getMonth(),date);
    } else if(type==='next') {
      finalDate = new Date(datepickerHeaderDate.getFullYear(),datepickerHeaderDate.getMonth(),1);
      subDate = addMonths(finalDate,1);

      finalDate = new Date(subDate.getFullYear(),subDate.getMonth(),date);
    } else {
      finalDate = new Date(datepickerHeaderDate.getFullYear(),datepickerHeaderDate.getMonth(),date);
    }
    return isWeekend(finalDate);  
  };

  const setDateValue = (date,type) => () => {
    let finalDate
    let subDate
    if(type==='previous') {
      finalDate = new Date(datepickerHeaderDate.getFullYear(),datepickerHeaderDate.getMonth(),1);
      subDate = subMonths(finalDate,1);
      
      finalDate = new Date(subDate.getFullYear(),subDate.getMonth(),date);
    } else if(type==='next') {
      finalDate = new Date(datepickerHeaderDate.getFullYear(),datepickerHeaderDate.getMonth(),1);
      subDate = addMonths(finalDate,1);

      finalDate = new Date(subDate.getFullYear(),subDate.getMonth(),date);
    } else {
      finalDate = new Date(datepickerHeaderDate.getFullYear(),datepickerHeaderDate.getMonth(),date);
    }
    
    changeDate(finalDate);  
  };

  useEffect(() => {
        setDatepickerHeaderDate(startDate) 
        setSelectedDate(startDate) 
    }
  ,[startDate]);

  const getDayCount = (date) => {
    let daysInMonth = getDaysInMonth(date);
    let daysInPreviousMonth = getDaysInMonth(subMonths(date,1));

    // find where to start calendar day of week
    let dayOfWeek = getDay(new Date(date.getFullYear(), date.getMonth(), 1));
    if(dayOfWeek===0) {
        dayOfWeek = 7
    } 

    let lastDayOfWeek = getDay(new Date(date.getFullYear(), date.getMonth(), daysInMonth));
    if(lastDayOfWeek===0) {
      lastDayOfWeek = 7
    } 
    
    let blankdaysArray = [];
    for (let i = 1; i < dayOfWeek; i++) {
      blankdaysArray.push(i);
    } 

    
    let previousArray = [];
    for (let i = daysInPreviousMonth-blankdaysArray.length+1; i <= daysInPreviousMonth; i++) {
      previousArray.push(i);
    }
    
    let daysArray = [];
    for (let i = 1; i <= daysInMonth; i++) {
      daysArray.push(i);
    }

    let nextArray = [];
    for (let i = 1; i <= 7-lastDayOfWeek; i++) {
      nextArray.push(i);
    }
 
    setPreviousDays(previousArray);
    setNextDays(nextArray);
    setBlankDays(blankdaysArray);
    setDayCount(daysArray);
  };

  const isSelectedMonth = (month) => {
    let res = false;
    if(format(selectedDate, 'yyyy-MM-dd', {locale: cs}) === format(new Date(datepickerHeaderDate.getFullYear(), month, datepickerHeaderDate.getDate()), 'yyyy-MM-dd', {locale: cs})) {
        res = true;
    }
   
    return res
  }
     

  const setMonthValue = (month) => () => {
    setDatepickerHeaderDate(
      new Date(
        datepickerHeaderDate.getFullYear(),
        month,
        datepickerHeaderDate.getDate()
      )
    );
    setType("date");
  };
 

  const showMonthPicker = () => setType("month");
 

  useEffect(() => {
    getDayCount(datepickerHeaderDate);

    if(format(selectedDate, 'yyyy-MM', {locale: cs}) === format(new Date(datepickerHeaderDate.getFullYear(),datepickerHeaderDate.getMonth(), datepickerHeaderDate.getDate()), 'yyyy-MM', {locale: cs})) {
        setShowDate(true)
    } else {
        setShowDate(false)
    }
    // eslint-disable-next-line
  }, [datepickerHeaderDate]);

  const goToDayView = () => {
    setView('day');
  }
  const goToWeekView = () => {
    setView('week');
  }
    
 
  return (
    <div className="max-w-sm mx-auto" >

        <div className=" space-y-4  justify-center  items-center  w-full px-3 pb-3 mt-2">
           
            <div className="flex justify-center w-full relative">
                     
                   
                    <button className={"    px-3 focus:outline-none mx-2 w-auto  h-10    hover:bg-gray-300 "
                    + (view==='day' ? "border-blue-600 text-blue-600" : "text-gray-600 ")  } 
                    onClick={goToDayView}><span className="label-filter-off">
                          <svg version="1.1" className="w-5 -mt-1   h-5 inline " fill="currentColor" stroke="none" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 29.121 29.121"  > 
                            <path d="M21.706,6.146c1.116,0,2.02-0.898,2.02-2.016V2.02c0-1.119-0.903-2.02-2.02-2.02
                                s-2.019,0.9-2.019,2.02v2.111C19.688,5.248,20.59,6.146,21.706,6.146z"/>
                            <path  d="M28.882,3.494h-4.066v1.027c0,1.695-1.377,3.076-3.075,3.076c-1.7,0-3.074-1.381-3.074-3.076V3.494
                                h-8.205v1.027c0,1.695-1.379,3.076-3.076,3.076s-3.075-1.38-3.075-3.076V3.494L0.208,3.443v25.678H2.26h24.604l2.049-0.006
                                L28.882,3.494z M26.862,27.076H2.26V10.672h24.604v16.404H26.862z"/>
                            <path  d="M7.354,6.146c1.116,0,2.021-0.898,2.021-2.016V2.02C9.375,0.9,8.47,0,7.354,0S5.336,0.9,5.336,2.02
                                v2.111C5.336,5.248,6.237,6.146,7.354,6.146z"/>
                            <text x="14.2" y="18.2" textAnchor="middle" dominantBaseline="central" >{moment(startDate).format('D')}</text> 
                        </svg>


                    </span>
                    </button>

        
                    
                    <button className={"  mx-2 focus:outline-none w-auto px-3 mr-0   h-10 ml-0   hover:bg-gray-300 "
                    + (view==='week' ? "border-blue-600 text-blue-600" : "text-gray-600 ")} onClick={goToWeekView}><span className="label-filter-off">
                            <svg version="1.1" className="w-5 -mt-1   h-5 inline " fill="currentColor" stroke="none" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
                        viewBox="0 0 29.121 29.121"  >
                        
                        <path d="M21.706,6.146c1.116,0,2.02-0.898,2.02-2.016V2.02c0-1.119-0.903-2.02-2.02-2.02
                            s-2.019,0.9-2.019,2.02v2.111C19.688,5.248,20.59,6.146,21.706,6.146z"/>
                        <path  d="M28.882,3.494h-4.066v1.027c0,1.695-1.377,3.076-3.075,3.076c-1.7,0-3.074-1.381-3.074-3.076V3.494
                            h-8.205v1.027c0,1.695-1.379,3.076-3.076,3.076s-3.075-1.38-3.075-3.076V3.494L0.208,3.443v25.678H2.26h24.604l2.049-0.006
                            L28.882,3.494z M26.862,27.076H2.26V10.672h24.604v16.404H26.862z"/>
                        <path  d="M7.354,6.146c1.116,0,2.021-0.898,2.021-2.016V2.02C9.375,0.9,8.47,0,7.354,0S5.336,0.9,5.336,2.02
                            v2.111C5.336,5.248,6.237,6.146,7.354,6.146z"/>
                        <rect x="10.468" y="12.873"  width="3.231" height="2.852" fill="#ccc"/>
                        <rect x="15.692" y="12.873"  width="3.234" height="2.852" fill="#ccc" />
                        <rect x="20.537" y="12.873"  width="3.231" height="2.852" fill="#ccc"/>
                        <rect x="10.468" y="17.609"  width="3.231" height="2.85"/>
                        <rect x="15.692" y="17.609"  width="3.234" height="2.85"/>
                        <rect x="20.537" y="17.609"  width="3.231" height="2.85"/>
                        <rect x="10.468" y="22.439"  width="3.231" height="2.85" fill="#ccc"/>
                        <rect x="5.336" y="17.609"  width="3.229" height="2.85"/>
                        <rect x="5.336" y="22.439"  width="3.229" height="2.85" fill="#ccc"/>
                        <rect x="15.692" y="22.439"  width="3.234" height="2.85" fill="#ccc"/>
                        <rect x="20.537" y="22.439"  width="3.231" height="2.85" fill="#ccc"/>
                        </svg>
                        </span>
                        </button>
                        <div className="absolute right-0 top-0 items-center">
                            <StudioSubmenu slideModalOpen={slideModalOpen} disabledResources={disabledResources} setDisabledResources={setDisabledResources}   />

                      </div>
                    </div>
                  <div className="relative flex justify-center w-full">
                    <span className="text-lg lg:text-xl xl:text-3xl pt-2">{format(selectedDate, "d. MMMM yyyy", {locale: cs})}</span>

                   
                  </div>
                </div>
      <div >
        <div>
          <div className="container mx-auto px-4 py-2 mt-4 w-full  ">
            <div className="mb-5 w-full">
               
              <div className="relative"> 
              
                  <div className="bg-white rounded-lg shadow p-4  top-0 left-0" >
                    <div className="xl:flex justify-between text-center xl:text-left xl:items-center mb-4 ">
                          {showDate && 
                            <span className="font-bold ">
                                
                            </span>
                          }
                        <div
                          onClick={showMonthPicker}
                          className="inline flex-grow p-1 text-lg  text-gray-800 cursor-pointer hover:bg-gray-200  rounded-lg space-x-2"
                        >
                          <span  >
                           {format(datepickerHeaderDate, "LLLL yyyy", {locale: cs})}
                          </span> 
                        </div>
                        <div className="flex justify-center">
                          
                          {format(datepickerHeaderDate, 'yyyy-MM-dd', {locale: cs}) !== format(new Date(), 'yyyy-MM-dd', {locale: cs}) && 
                         <div className=" w-14" >
                          
                              <button className="  btn btn-next text-gray-600 lg:px-3  lg:mr-2 mr-1 mt-0 h-8 focus:outline-none rounded-md focus:bg-gray-300 hover:bg-gray-200" title="Dnes" onClick={() => changeDate(new Date())}>
                                  <svg className="w-5 h-5" fill="currentColor" stroke="none" viewBox="0 0 551.13 551.13" xmlns="http://www.w3.org/2000/svg">
                                      <path d="m275.531 172.228-.05 120.493c0 4.575 1.816 8.948 5.046 12.177l86.198 86.181 24.354-24.354-81.153-81.136.05-113.361z"/>
                                      <path d="m310.011 34.445c-121.23 0-221.563 90.033-238.367 206.674h-71.644l86.114 86.114 86.114-86.114h-65.78c16.477-97.589 101.355-172.228 203.563-172.228 113.966 0 206.674 92.707 206.674 206.674s-92.707 206.674-206.674 206.674c-64.064 0-123.469-28.996-162.978-79.555l-27.146 21.192c46.084 58.968 115.379 92.808 190.124 92.808 132.955 0 241.119-108.181 241.119-241.119s-108.164-241.119-241.119-241.12z"/>
                                  </svg> 
                                  
                              </button>
                              
                          </div>
                          }

                        
                        <button
                          type="button"
                          className="transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-gray-200 p-1 mx-2 rounded-full focus:outline-none"
                          onClick={decrement}
                        >
                          <svg
                            className="h-6 w-6 text-gray-500 inline-flex"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M15 19l-7-7 7-7"
                            />
                          </svg>
                        </button>
                       
                        <button
                          type="button"
                          className="transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-gray-200 p-1 mx-2 rounded-full focus:outline-none"
                          onClick={increment}
                        >
                          <svg
                            className="h-6 w-6 text-gray-500 inline-flex"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M9 5l7 7-7 7"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                    {type === "date" && (
                      <>
                        <div className="flex flex-wrap mb-3 -mx-1">
                          {DAYS.map((day, i) => (
                            <div
                              key={i}
                              style={{ width: "14.26%" }}
                              className="px-1"
                            >
                              <div className={" font-medium text-center text-xs " + (i===5 || i===6 ? " text-blue-600" : "text-gray-800")}>
                                {day}
                              </div>
                            </div>
                          ))}
                        </div>
                        <div className="flex flex-wrap -mx-1">
                          
                          {previousDays.map((d, i) => (
                            <div
                              key={i}
                              style={{ width: "14.26%" }}
                              className="px-1 mb-1"
                            >
                              <div
                                onClick={setDateValue(d,'previous')}
                                className="cursor-pointer text-center text-sm rounded-full leading-loose transition ease-in-out duration-100 text-gray-700 hover:bg-blue-200  opacity-50 " >
                                {d} 
                              </div>
                            </div>
                          ))}
                          {dayCount.map((d, i) => (
                            <div
                              key={i}
                              style={{ width: "14.26%" }}
                              className="px-1 mb-1"
                            >
                              <div
                                onClick={setDateValue(d)}
                                className={"cursor-pointer text-center text-sm rounded-full leading-loose transition ease-in-out duration-100 " + 
                                  (isToday(d)
                                    ? "bg-blue-500 text-white " + (todayMark(d) && "font-bold")
                                    : (todayMark(d) 
                                      ? "text-gray-800 bg-gray-200 font-bold"
                                      : (isDayWeekend(d) 
                                        ? "text-blue-500 hover:bg-blue-200"
                                        : "text-gray-700 hover:bg-blue-200"
                                    ))
                                 )}
                              >
                                {d} 
                              </div>
                            </div>
                          ))}
                           {nextDays.map((d, i) => (
                            <div
                              key={i}
                              style={{ width: "14.26%" }}
                              className="px-1 mb-1"
                            >
                              <div
                                onClick={setDateValue(d,'next')}
                                className="cursor-pointer text-center text-sm rounded-full leading-loose transition ease-in-out duration-100 text-gray-700 hover:bg-blue-200 opacity-50 " >
                                {d} 
                              </div>
                            </div>
                          ))}
                        </div>
                      </>
                    )}
                    {type === "month" && (
                      <div className="flex flex-wrap -mx-1">
                        {Array(12)
                          .fill(null)
                          .map((_, i) => (
                            <div
                              key={i}
                              onClick={setMonthValue(i)}
                              style={{ width: "25%" }}
                            >
                              <div
                                className={`cursor-pointer p-5 px-2 font-semibold text-center text-sm rounded-lg hover:bg-gray-200 ${
                                  isSelectedMonth(i)
                                    ? "bg-blue-500 text-white"
                                    : "text-gray-700 hover:bg-blue-200"
                                }`}
                              >
                                {format(
                                  new Date(
                                    datepickerHeaderDate.getFullYear(),
                                    i,
                                    datepickerHeaderDate.getDate()
                                  ),
                                  "LLLL"
                                  , {locale: cs})}
                              </div>
                            </div>
                          ))}
                      </div>
                    )}{" "}
                    {/* {type === "year" && (
                      <Datepicker
                        datepickerHeaderDate={datepickerHeaderDate}
                        selectedDate={selectedDate}
                        setSelectedDate={setSelectedDate}
                        closeDatepicker={() => setShowDatepicker(false)}
                      />
                    )} */}
                  </div>
                 
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
