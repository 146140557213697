import { UserGroupIcon, LockClosedIcon } from "@heroicons/react/solid";
import { Event } from "./event";

export const WeekEventComponent = ({handleSelect,resources, location, getEvents, dialog, setSlideModalContent, setSlideModalIsOpen}, event, ...props) => {
    let item = event.event
    let past = false;
    let active = false;
    let disabled = false;
    let now = new Date();
    if(item.end < now) {
        past = true;
    }
    if(item.start < now && item.end > now) {
        active = true;
    }
    if(item.private !== 0 || item.free === 0) {
        disabled = true
    }

   return(
     <>
     <div className={"group relative p-2 pt-8 leading-normal h-full bg-gray-100 hover:bg-gray-50 "  +( past ? "text-gray-500" : "text-gray-800")} onClick={() => handleSelect(
             {
                resources,
                location,
                getEvents,
                dialog,
                ...props
            },item,false)}>
              {/* {disabled && <>
                <div className="bg-white w-full h-full z-50 opacity-60 absolute top-0 left-0 right-0">

                </div>
              </>} */}
             <div className={"absolute  top-0 left-0 w-full text-xs  h-7 flex items-center justify-between rounded-t-sm text-center  px-2 " 
            + (active ? "bg-yellow-600 group-hover:bg-yellow-700   text-white " : 
                past ? "bg-gray-400 text-gray-100 group-hover:bg-gray-600" : 
                item.free === 0 ? "bg-gray-600 text-white group-hover:bg-gray-700" :
                item.type === 'virtual' ? "bg-violet-500 group-hover:bg-violet-600   text-white " : 
                "bg-blue-500 group-hover:bg-blue-600   text-white ")
              }>
                <div className="font-medium truncate pr-1">
           {item.startTime} - {item.endTime} 
           </div>
           <div className="flex space-x-1 lg:space-x-2 items-center divide-x divide-gray-50 divide-opacity-50">
              <div className="flex items-center space-x-1"><UserGroupIcon className="w-4 " /> <span className="font-medium">{item.occupied}</span></div>
              <div className="items-center space-x-1 flex lg:pl-1"><span className="hidden lg:flex ">volno</span> <span className="font-medium">{item.free}</span></div>
           </div>
        </div>
        
        <div className=" lg:text-md  ">
           <p className="font-medium truncate">{event.title}</p>  
        </div>
        <div>
          <p className="truncate text-xs text-gray-500">{item.instructor}</p>
        </div>

        {/* <div className={"absolute  top-10 right-0  text-xs font-medium h-7 flex items-center justify-between rounded-t-sm text-center    text-white px-2 " + (past ? " filter grayscale " : " ")}>
          <div className="w-10 rounded-full border border-gray-300 h-10 flex items-start overflow-hidden"><img src={item.instructor_img} alt={item.instructor}  /></div>
        </div> */}
        <div className="flex-none ">
            
             <div className="flex space-x-2 mt-1">
                     
                     {item.type === 'virtual' && <>
                     <svg className="w-4 text-violet-600" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" fill="currentColor"  
                        viewBox="0 0 97.385 97.385"  >
                      
                          <path d="M97.265,71.261c-0.093-0.108-0.23-0.172-0.374-0.172H0.494c-0.144,0-0.28,0.063-0.374,0.172
                            c-0.094,0.108-0.136,0.253-0.115,0.396c0.014,0.09,1.468,9.016,12.753,9.016h71.869c11.284,0,12.738-8.926,12.753-9.016
                            C97.4,71.514,97.358,71.369,97.265,71.261z M55.942,77.547h-14.5v-3.334h14.5V77.547z"/>
                          <path d="M10.507,67.619h76.37c1.104,0,2-0.896,2-2V18.713c0-1.104-0.896-2-2-2h-76.37c-1.104,0-2,0.896-2,2v46.906
                            C8.507,66.723,9.403,67.619,10.507,67.619z M15.507,23.713h66.37v36.906h-66.37V23.713z"/>
                        </svg>
                     </>}
  
                     {item.private !== 0 && <LockClosedIcon className="w-4 text-red-600" />} 
  
                  </div>
        </div>
        
     </div>
     
     </>
   )
   }    