import Home from './components/layout/home.js';
import './App.css';
import React, { useState, useEffect, useCallback } from "react";
import { useHistory, Switch, Route, Redirect } from "react-router-dom"; 
import Login from "./components/login";
import Client from "./components/client";
import Location from "./components/location";
import Carts from "./components/cart/carts";
import Layout from "./components/layout"; 
import Studio from "./components/studio/studio";
import OrderHome from "./components/order/orderHome";
import OrderDetail from "./components/order/orderDetail";
import OrderCreate from "./components/order/orderCreate";
import UsersDetail from "./components/users/usersDetail";
import UsersHome from "./components/users/usersHome";
import UsersCreate from "./components/users/usersCreate";
import jwtDecode from "jwt-decode";
import {Print} from "./components/layout/print";    
import { AuthContext } from "./context/context";
  
import { io } from "socket.io-client"; 
import {API_URL, SCOMOS_URL, NODE_URL, DOMAIN} from "./config"; 
import Settings from './services/Settings'; 
import { axiosProtected } from './client/index.js';
 

let logoutTimer;
let electron = false;
let userAgent = navigator.userAgent.toLowerCase();
    if(userAgent.indexOf(' electron/') > -1) {
       electron = true
    }
 
export default function App() { 
  
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let auth = params.get('auth');
  const [response, setResponse] = useState(null);

  const [userData, setUser] = useState(JSON.parse(localStorage.getItem('user')));
  const [settings, setSettings] = useState(JSON.parse(localStorage.getItem('settings')));
  const [clientId, setClientId] = useState(JSON.parse(localStorage.getItem('clientId')));
  const [location, setLocation] = useState(JSON.parse(localStorage.getItem('location')));
  const [printContent,setPrintContent] = useState(false);  
  const [print] = useState(); 
  const [token, setToken] = useState(userData?.token);
   // eslint-disable-next-line 
  const [tokenExpirationDate, setTokenExpirationDate] = useState();  
 
  const history = useHistory();
  
  function updatePrint(data) { 
    if(data) {
      localStorage.setItem("print", JSON.stringify(data));
    //  setPrint(data)
      (axiosProtected()).post('socket/send/',
      {
          endpoint: NODE_URL,
          message: 'print',
          client: clientId,
      })
      .then(res => {
          
      })
    }
  }
  
  function updateUser(token) {
    if (token) { 
      const jwt = jwtDecode(token);
      const data = {
        accessToken: token,
        eml: jwt.eml,
        fnm: jwt.fnm,
        snm: jwt.snm,
        img: jwt.img,
        exp: jwt.exp
      }
      localStorage.setItem("user", JSON.stringify(data));
      
      setUser(data);
    } else {
      setUser(false);
    }
  }

  const login = useCallback((token, expirationTime) => {
    setToken(token); 
    const expiration =
      expirationTime || new Date(new Date().getTime() + 1000 * 60 * 60 * 24);
    const data = {
      token,
      data: jwtDecode(token),
      expirationTime: expiration.toISOString()
    }
    setTokenExpirationDate(expiration);
    localStorage.setItem(
      "user",
      JSON.stringify(data)
    );
    setUser(data)
  }, []); 
 
  const logout = useCallback(() => {
    setToken(null);
    setUser(null); 
    localStorage.removeItem("user");
    localStorage.removeItem("settings");
    localStorage.removeItem("logo");
  }, []);
  
  function updateLoc(location) {
    if (location) { 
      localStorage.setItem("location", JSON.stringify(location));
      setLocation(location);
      history.push("/");
    }
  }

  function updateClientId(clientId) {
    if (clientId) { 
      localStorage.setItem("clientId", JSON.stringify(clientId));
      setClientId(clientId); 
      <Redirect to='/'/>
    }
  }

  async function getSettings() {
    !settings && userData &&
    await Settings.getSettings()
    .then((res) => {
      setSettings(res)
    })
    .catch((err) => {
      console.log(err)
    })
  }

  useEffect(() => {  

    if(auth) {
      login(auth);
      history.push("/"); 
    }
    
    const socket = io(NODE_URL); 

    socket.on("code", data => {  
      if(data?.query?.client === clientId || !data?.query?.client) {
        setResponse(data.query);  
      }
    }); 
 
    getSettings();
    // eslint-disable-next-line
  }, [userData]);

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("user"));
    let expiration
    if(storedData) {
      expiration = new Date(storedData.expirationTime);
    }
    if (token && expiration) {
      const remainingTime =
      expiration.getTime() - new Date().getTime();
      logoutTimer = setTimeout(logout, remainingTime);
    } else {
      clearTimeout(logoutTimer);
    }
  }, [token, logout, userData]);

  
    return (
      <AuthContext.Provider value={{
        isLoggedIn: !!token,
        token: token, 
        login: login,
        logout: logout
      }}> 
          <Switch>
            <Route exact path="/login"  render={(props) => <Login {...props} component={Login} setUser={updateUser} API_URL={SCOMOS_URL} electron={electron} print={print} setPrint={updatePrint} setPrintContent={setPrintContent} printContent={printContent} />} />
            <Route path="/client/:clientId"  render={(props) => <Client {...props} component={Client} updateClientId={updateClientId} electron={electron} print={print} setPrint={updatePrint} setPrintContent={setPrintContent} printContent={printContent} />} />
            {!userData ? <Redirect to="/login"/> : null}
            <Route exact path="/location"  render={(props) => <Location {...props} component={Location} setLoc={updateLoc} API_URL={API_URL} electron={electron}  print={print} setPrint={updatePrint} setPrintContent={setPrintContent} printContent={printContent} />} />
            {!location ? <Redirect to="/location"/> : null}
            <Redirect exact from="/" to="/studio" />
            <Route exact path="/" render={(props) => <Layout {...props}  setUser={setUser} component={Home} response={response} setResponse={setResponse}  user={userData} electron={electron} print={print} setPrint={updatePrint} setPrintContent={setPrintContent} printContent={printContent}  />} />
            <Route exact path="/order" render={(props) => <Layout {...props}  setUser={setUser}component={OrderHome}  response={response} setResponse={setResponse}  user={userData} electron={electron}  print={print} setPrint={updatePrint} setPrintContent={setPrintContent} printContent={printContent} />} /> 
            <Route exact path="/order/create" render={(props) => <Layout {...props}  setUser={setUser}component={OrderCreate}  response={response} setResponse={setResponse}  user={userData} electron={electron} print={print} setPrint={updatePrint} setPrintContent={setPrintContent} printContent={printContent}  />} /> 
            <Route path="/order/:number" render={(props) => <Layout {...props}  setUser={setUser}component={OrderDetail}  response={response}  setResponse={setResponse} user={userData} electron={electron} print={print} setPrint={updatePrint} setPrintContent={setPrintContent} printContent={printContent}  />} /> 
            <Route exact path="/users" render={(props) => <Layout {...props}  setUser={setUser}component={UsersHome}  response={response} setResponse={setResponse} user={userData} electron={electron} print={print} setPrint={updatePrint} setPrintContent={setPrintContent} printContent={printContent}  />} /> 
            <Route exact path="/users/create" render={(props) => <Layout {...props}  setUser={setUser}component={UsersCreate}  response={response}  setResponse={setResponse} user={userData} electron={electron} print={print} setPrint={updatePrint} setPrintContent={setPrintContent} printContent={printContent}  />} /> 
            <Route path="/users/:id" render={(props) => <Layout {...props}  setUser={setUser}component={UsersDetail}  response={response}  setResponse={setResponse} user={userData} electron={electron}  print={print} setPrint={updatePrint} setPrintContent={setPrintContent} printContent={printContent} />} /> 
            <Route exact path="/studio" render={(props) => <Layout {...props}  setUser={setUser}component={Studio} settings={settings} response={response}  setResponse={setResponse} user={userData} electron={electron} print={print} setPrint={updatePrint} setPrintContent={setPrintContent} printContent={printContent}  />} /> 
            <Route path="/studio/:location/:date" render={(props) => <Layout {...props}  setUser={setUser} settings={settings} component={Studio}  response={response}  setResponse={setResponse} user={userData} electron={electron} print={print} setPrint={updatePrint} setPrintContent={setPrintContent} printContent={printContent}  />} /> 
            <Route path="/studio/:location" render={(props) => <Layout {...props}  setUser={setUser}component={Studio} settings={settings} response={response}  setResponse={setResponse} user={userData} electron={electron}  print={print} setPrint={updatePrint} setPrintContent={setPrintContent} printContent={printContent} />} /> 
            <Route path="/prodej/:type/:catAlias" render={(props) => <Layout {...props}  setUser={setUser}component={Carts}  response={response}  setResponse={setResponse}  user={userData} electron={electron} print={print} setPrint={updatePrint} setPrintContent={setPrintContent} printContent={printContent}  />} /> 
            <Route path="/prodej/:type" render={(props) => <Layout {...props}  setUser={setUser}component={Carts}  response={response}  setResponse={setResponse}  user={userData} electron={electron} print={print} setPrint={updatePrint} setPrintContent={setPrintContent} printContent={printContent}  />} /> 
            <Route exact path="/prodej" render={(props) => <Layout {...props}  setUser={setUser}component={Carts}  response={response} user={userData}  setResponse={setResponse}  electron={electron} print={print} setPrint={updatePrint} setPrintContent={setPrintContent} printContent={printContent}  />} /> 
            <Route exact path="/print" render={(props) => <Print {...props} response={response} user={userData}  setResponse={setResponse}  electron={electron} print={print} setPrint={updatePrint} setPrintContent={setPrintContent} printContent={printContent} printIsOpen={false} />} /> 
          </Switch>
      </AuthContext.Provider>
    );
  
}
