import React, { useState, useEffect, useRef } from "react";
import ReactPaginate from "react-paginate";
import { useForm } from "react-hook-form";
import UsersLayout from "./usersLayout";
import { Link } from "react-router-dom";
import { axiosProtected } from "../../client";

export default function UsersHome(props) {
  const user = props.user;
  const token = user.accessToken;
  const config = {
    headers: {
      Authorization: `Bearer ` + token,
    },
  };
  const myRef = useRef(null);
  const [offset, setOffset] = useState(1);
  const [data, setData] = useState([]);
  const [perPage] = useState(50);
  const [pageCount, setPageCount] = useState(0);
  const [tempData, setTempData] = useState();

  const { register, handleSubmit } = useForm();
  const onSubmit = async (data) => {
    console.log(data);
  };

  const executeScroll = () => myRef.current.scrollIntoView();

  const getData = async (order) => {
    if (!order) {
      order = "new";
    }
    const res = await axiosProtected().get("users_data/list/?order=" + order);
    const data = res.data;
    setTempData(data);
    updateData(data);
    setPageCount(Math.ceil(data.length / perPage));
  };
  function updateData(data) {
    if (data) {
      const slice = data.slice(
        (offset - 1) * perPage,
        (offset - 1) * perPage + perPage
      );
      setData(slice);
    }
  }
  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    setOffset(selectedPage + 1);
    executeScroll();
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    updateData(tempData);
    // eslint-disable-next-line
  }, [offset]);

  return (
    <>
      <div ref={myRef}></div>
      <UsersLayout {...props}>
        <div className=" lg:grid lg:grid-cols-12 ">
          <div className="hidden lg:block lg:col-span-3 bg-gray-200">
            <nav
              aria-label="Sidebar"
              className="sticky top-6 divide-y divide-gray-300"
            >
              <div className="p-6">
                <h2
                  id="billing_history_heading"
                  className="text-lg leading-6 font-medium text-gray-900 mb-5"
                >
                  Filtry
                </h2>
                <form key={1} onSubmit={handleSubmit(onSubmit)}>
                  <label className="block text-sm font-medium text-gray-700">
                    Řazení
                  </label>
                  <div className="mt-1">
                    <select
                      id="typ"
                      onChange={(e) => getData(e.target.value)}
                      ref={register()}
                      name="typ"
                      className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    >
                      <option value="new">od nejnovějších</option>
                      <option value="alphabet">podle abecedy</option>
                    </select>
                  </div>
                </form>
              </div>
            </nav>
          </div>
          <main className="lg:col-span-9 ">
            <div className=" ">
              <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-10">
                {/* <!-- Billing history --> */}
                <section aria-labelledby="billing_history_heading">
                  <div
                    className="bg-white pt-6 shadow   sm:overflow-hidden"
                    style={{ height: "calc(100vh - 5rem - 30px)" }}
                  >
                    <div className="px-4 sm:px-6">
                      <h2
                        id="billing_history_heading"
                        className="text-lg leading-6 font-medium text-gray-900 mb-5"
                      >
                        Kontakty
                      </h2>
                    </div>

                    <div className="bg-white shadow overflow-hidden sm:rounded-md">
                      <ul className="divide-y divide-gray-200">
                        {data &&
                          data.map((client) => (
                            <li key={client.id}>
                              <Link
                                to={"/users/" + client.id}
                                className={
                                  "block hover:bg-gray-100 w-full text-left "
                                }
                              >
                                <div className="flex items-center px-4 py-4 sm:px-6">
                                  <div className="min-w-0 flex-1 flex items-center">
                                    <div className="flex-shrink-0">
                                      <div
                                        className={
                                          "hidden lg:flex h-12 w-12 overflow-hidden  border-2    rounded-full  flex-wrap content-center justify-center " +
                                          (client.nick === "company"
                                            ? " bg-gray-200 border-gray-200 text-gray-400 "
                                            : " bg-blue-200 border-blue-300 text-blue-700")
                                        }
                                      >
                                        {client.img ? (
                                          <img
                                            src={client.img}
                                            alt=""
                                            className="h-10 w-12 fill-current   rounded-full"
                                          />
                                        ) : client.nick === "company" ? (
                                          <>
                                            <div className="h-10 w-10 bg-gray-100 rounded-full ring-8 ring-white flex items-center justify-center">
                                              <svg
                                                className="w-8 rounded-full bg-gray-400 text-gray-100 p-1"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="currentColor"
                                                viewBox="0 0 484.738 484.738"
                                                stroke="currentColor"
                                              >
                                                <path
                                                  d="M131.012,438.372h85.624v-87.557h51.467v87.557h85.624V46.366H131.012V438.372z M257.993,92.564h51.466v51.467h-51.466
                                            V92.564z M257.993,175.279h51.466v51.467h-51.466V175.279z M257.993,257.992h51.466v51.467h-51.466V257.992z M175.279,92.564
                                            h51.466v51.467h-51.466V92.564z M175.279,175.279h51.466v51.467h-51.466V175.279z M175.279,257.992h51.466v51.467h-51.466V257.992
                                            z"
                                                />
                                                <path
                                                  d="M373.719,81.482V358.66h33.065v79.712h77.954V81.482H373.719z M444.437,321.009H397.58v-46.856h46.856V321.009z
                                            M444.437,245.703H397.58v-46.855h46.856V245.703z M444.437,170.398H397.58v-46.855h46.856V170.398z"
                                                />
                                                <path
                                                  d="M0,438.372h77.954V358.66h33.065V81.482H0V438.372z M40.302,123.543h46.856v46.855H40.302V123.543z M40.302,198.848
                                            h46.856v46.855H40.302V198.848z M40.302,274.151h46.856v46.855H40.302V274.151z"
                                                />
                                              </svg>
                                            </div>
                                          </>
                                        ) : (
                                          <span className="truncate block uppercase text-xl">
                                            {client.nick}
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                    <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                                      <div className="flex items-center">
                                        <p className="text-sm font-medium text-gray-700 truncate w-full">
                                          {client.name}
                                          {client.email && (
                                            <span className="truncate font-normal block">
                                              {client.email}
                                            </span>
                                          )}
                                        </p>
                                      </div>
                                      <div className="hidden md:block">
                                        <div>
                                          <p className="text-sm text-gray-900"></p>
                                          <p className="mt-2 flex items-center text-sm text-gray-500"></p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div>
                                    <svg
                                      className="h-5 w-5 text-gray-400"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 20 20"
                                      fill="currentColor"
                                      aria-hidden="true"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                        clipRule="evenodd"
                                      />
                                    </svg>
                                  </div>
                                </div>
                              </Link>
                            </li>
                          ))}
                      </ul>
                    </div>
                    <div className="bg-white px-4 py-10 grid items-center justify-items-center border-t border-gray-200 sm:px-6">
                      <ReactPaginate
                        previousLabel={
                          <>
                            <svg
                              className="h-5 w-5 inline"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                fillRule="evenodd"
                                d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                                clipRule="evenodd"
                              />
                            </svg>
                            <span className="inline-flex sm:hidden">
                              Předchozí
                            </span>
                          </>
                        }
                        nextLabel={
                          <>
                            <span className="inline-flex sm:hidden">
                              Následující
                            </span>
                            <svg
                              className="h-5 w-5"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                fillRule="evenodd"
                                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </>
                        }
                        previousClassName={
                          "relative rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:outline-none flex-1 flex justify-between"
                        }
                        previousLinkClassName={
                          " px-2 py-2  focus:outline-none  inline-flex items-center"
                        }
                        nextClassName={
                          "relative  rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:outline-none"
                        }
                        nextLinkClassName={
                          "px-2 py-2 focus:outline-none inline-flex items-center"
                        }
                        breakLabel={"..."}
                        breakClassName={
                          "relative  sm:inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none"
                        }
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageClick}
                        containerClassName={
                          "relative z-0 inline-flex  rounded-md shadow-sm -space-x-px select-none "
                        }
                        subContainerClassName={""}
                        pageClassName={
                          "hidden sm:inline-flex relative    border border-gray-300 bg-white text-sm font-medium  hover:bg-gray-50 "
                        }
                        pageLinkClassName={
                          "px-4 py-2 text-gray-700 focus:outline-none"
                        }
                        activeClassName={"bg-blue-200 hover:bg-blue-300 "}
                      />

                      <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between"></div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </main>
        </div>
      </UsersLayout>
    </>
  );
}
