import React, { useState, useEffect, useRef } from "react"; 
import { useParams, useHistory } from "react-router-dom";
import { Calendar, momentLocalizer  } from 'react-big-calendar' 
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './calendar.css';
import moment from 'moment'
import 'moment/locale/cs'; 
import {NewEvent} from './newEvent'; 
import { WeekEventComponent } from "./WeekComponent";  
import { CalendarMenu } from "./CalendarMenu";
import { SmallCalendar } from "./SmallCalendar";
import { LoginIcon, UserGroupIcon, LockClosedIcon } from "@heroicons/react/solid";
import { ResourceHeader } from "./ResourceHeader";
import { endOfWeek } from "date-fns";
import { cs } from 'date-fns/locale' 
import { axiosProtected } from "../../client";
import { ImBook, ImVideoCamera } from "react-icons/im";
import StudioSubmenu from "./StudioSubmenu";

const localizer = momentLocalizer(moment);


export default function EvCalendar(props) { 
    let { location } = useParams(); 
    const history = useHistory();
    const events = props.events;
    const getEvents = props.getEvents; 
    const changeDate = props.changeDate; 
    const startDate = props.selectedDate;  
    const response = props.response;  
    const dialog = props.dialog; 
    const setSlideModalContent = props.setSlideModalContent;
    const setSlideModalIsOpen = props.setSlideModalIsOpen;
    const calendarView = props.calendarView;
    const setCalendarView = props.setCalendarView;
    const settings = props.settings;
    // eslint-disable-next-line 
    
    // eslint-disable-next-line
    const [resourceId, setResourceId] = useState();
    // eslint-disable-next-line
    const [roomData, setRoomData] = useState();
    const [padding, setPadding] = useState(0);
    const [spaceItems, setSpaceItems] = useState([]);
    const [resources, setResources] = useState([]);
    const [visibleResources, setVisibleResources] = useState([]);
    const [width, setWidth] = useState(window.innerWidth);
    const [showResources, setShowResources] = useState(false);
    const [disabledResources, setDisabledResources] = useState(settings?.timetable?.disable_resources);

    const space = props.space
    const setEvents = props.setEvents 
    const selStudio = props.selStudio
  
    const myRef = useRef(null) 
    const executeScroll = () => myRef.current.scrollIntoView()  
 
    let today = false;
    if(moment(startDate).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')) {
        today = true;
    } 
 

     
   const slideModalOpen = (id,data) => {
     
    //setDate(new Date(selectedDate));
    id && setResourceId(id)
    data && setRoomData(data)
   
    props.setSlideModalContent({
      component: NewEvent,
      params: {
        start: startDate,
        getEvents: getEvents,
        resourceId: id,
        roomData: data,
        setEvents: setEvents,
        settings: settings,
        selStudio: selStudio,
      }
    })

    props.setSlideModalIsOpen(true) 
 
    
  }
   

  useEffect(() => { 
    window.addEventListener("resize", () => setWidth(window.innerWidth)); 
  }, []);
  
    
  useEffect(() => { 
    disabledResources ? setShowResources(false) : calendarView === 'day' ? setShowResources(true) : setShowResources(false)
  }, [disabledResources]);

    async function handleSelect(props,event,type) { 
        //event.id && history.push("#" + event.id);
        
        const resourceId = event.resourceId
        const resources = props.resources 
        const setSlideModalContent = props.setSlideModalContent
        const setSlideModalIsOpen = props.setSlideModalIsOpen

        let spaceId;
        resources &&
        resources.forEach((resource, index) =>  {
            if(resourceId===resource.resourceId) spaceId = resource.spaceId
        }) 
        let roomVal;
        resourceId && (
            roomVal = settings.locations[location].space[spaceId].rooms[resourceId]
        )
      
        setSlideModalContent({
            component: NewEvent,
            params: {
                roomData: roomVal,
                room: roomVal,
                resources: props.resources,
                start: event.start,
                end: event.end,
                event: event,
                title: event?.title,
                resourceId: event.resourceId,
                location: props.location,
                spaceId: spaceId,
                settings: settings,
                getEvents: getEvents,
                selStudio: selStudio,
                changeDate: changeDate
            }
        })
    
        setSlideModalIsOpen(true) 
      
            
    }


    

   const Space = ({room,spaceItem, ...props}) => { 
    const roomId = space[spaceItem]?.rooms[room].timetable_studio_room_id
    const roomData = space[spaceItem]?.rooms[room]
    const [resourceEvents,setResourceEvents] = useState(props.events)
    const events = props.events
    const unlimited = roomData.timetable_studio_room_unlimited
    //const calendar = roomData.timetable_studio_room_calendar_resource

    useEffect(() => {   
     const filtered = []
     events.map((event,i) => (
       roomId === event.resourceId &&
       filtered.push(event)
     ))
     setResourceEvents(filtered);
   }
   // eslint-disable-next-line 
   , [events]);

    return (
      <>
       
       
         <div  className="pb-2 bg-white my-3 rounded-md " onClick={() => slideModalOpen(roomId,roomData) }>
            
               <div className="p-3 bg-blue-800 text-gray-100 rounded-t-md">
                   
                 {roomData.timetable_studio_room_name}
               </div>
             
             <div className="p-3">
             {resourceEvents?.map((event,i) => (
                 <div key={i}>
                   {unlimited ? (
                     event?.clients?.present?.map((client,z) => (
                     
                        <div key={z} className={"flex rounded-md  gap-y-4  border-gray-200"}>
                        
                            <div className="flex col-span-4 font-medium space-x-2" >
                                <span className="w-8 text-center text-gray-400">{z+1} </span>
                                <span className="font-medium">{client.name} </span>
                            </div>
                        </div>
                           
                     ))
                   ) : (
                     <>
                     {event.title}
                     </>
                   )}
                 </div>
               ))
             }
               <div className="mt-2">
                 <button type="button flex items-center">
                   <svg className="text-gray-500 w-8 hover:text-gray-800" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                     <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                   </svg>

                 </button>

               </div>
             </div>
           </div>
       
           

      </>
    )
  }
  
    const EventComponent = (props,event) => {
        let item = event.event
        let response = props.response
        let events = props.events
        let setEvents = props.setEvents
        let past = false;
        let active = false;
        let disabled = false;
        let now = new Date();
        if(item?.end < now) {
            past = true;
        }
        if(item?.start < now && item?.end > now) {
            active = true;
        }
        if(item?.private !== 0 || item?.free === 0) {
            disabled = true
        }

       async function fetchData() { 
          await (axiosProtected()).post('timetable/event/',
          {
              id: item?.id
          })
          .then(res => {
             let newEvents = [];
             events.forEach((ev) => {
               if(ev.id === res.data[0].id) {
                  newEvents.push(res.data[0]) 
               } else {
                 newEvents.push(ev)
               }
             })
             setEvents(newEvents); 
          })
          .catch(err => {
              console.log(err);
          });
       }
       
       useEffect(() => { 
          if(response)  {
            if(response?.timetable_id && response?.timetable_id === item?.id) {
              fetchData(); 
              props.setResponse(false)
            }
          }
          // eslint-disable-next-line
       }, [response]);
 
       return(
         <>
         <div className={"group relative p-2 pt-8 leading-normal h-full bg-gray-100 hover:bg-gray-50  "   } onClick={() => handleSelect(
             {
                resources,
                location,
                getEvents,
                dialog,
                ...props
            },item,false)}>
              {/* {disabled && <>
                <div className="bg-white w-full h-full z-50 opacity-60 absolute top-0 left-0 right-0">

                </div>
              </>} */}
            <div className={"absolute  top-0 left-0 w-full text-xs   h-7 flex items-center justify-between rounded-t-sm text-center  px-2 " 
            + (active ? "bg-yellow-600 group-hover:bg-yellow-700   text-white " : 
                past ? "bg-gray-400 text-gray-100 group-hover:bg-gray-600" : 
                //item?.free === 0 ? "bg-gray-600 text-white group-hover:bg-gray-700" :
                item?.type === 'virtual' ? "bg-violet-500 group-hover:bg-violet-600   text-white " : 
                "bg-blue-500 group-hover:bg-blue-600   text-white ")
              }>
           <div className="font-medium truncate pr-1">
               {item?.startTime} - {item?.endTime}  
               </div>
               <div className="flex space-x-1 lg:space-x-2 items-center divide-x divide-gray-50 divide-opacity-50">
                  <div className="flex items-center space-x-1"><UserGroupIcon className="w-4 " /> <span className="font-medium">{item?.arrived}</span></div>
                  <div className="flex items-center space-x-1 pl-1"><ImBook className="w-4 " /> <span className="font-medium">{item?.booking}</span></div>
                  <div className="items-center space-x-1 flex  pl-1"><span className=" flex ">
                    <svg className="w-3" fill="currentColor"  viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">  
                                <path d="M400.084,252H364V121.9c16-4.956,24-18.26,24-33.9V32c0-19.848-12.068-32-31.916-32h-192C144.236,0,124,12.152,124,32v56
                                    c0,15.64,12,28.94,24,33.9V252h-27.916c-6.616,0-16.084,1.388-16.084,8v24c0,6.616,4,12,12,12v208c0,2.208,5.876,8,8.084,8h24
                                    c2.208,0-0.084-5.792-0.084-8V364h216v140c0,2.208,5.876,8,8.084,8h24c2.208,0-0.084-5.792-0.084-8V296c8,0,12-5.384,12-12v-24
                                    C408,253.388,406.704,252,400.084,252z M172,128h168v124H172V128z M364,340H148v-40h216V340z"/>
                        
                        </svg></span> {item?.free > 0 ? <><span className="font-medium">{item?.free}</span></> : <><span className="ml-1">0</span></>}</div>
               </div>

            </div>
            <div className={"absolute top-8 right-1 w-1 rounded-md   bottom-1 " 
          + (
            past ? "hidden " : 
            item?.type === 'virtual' ? "bg-violet-500 " : 
            item?.private !== 0  ? "bg-red-600" :
            item?.free === 0 ? "bg-red-600 " :
            (item?.free > 0 && (item?.free/item?.capacity*100) < 20) ? "bg-yellow-500 " :
            active ? "  " : 
            " ")
             }>
            </div>
            
            <div className=" lg:text-md flex items-center ">
               <div className="flex items-center">
                     
                   {item?.type === 'virtual' && <> 
                    <ImVideoCamera className={"w-6  pr-1 " + (past ? "text-gray-500" : "text-violet-600")} /> 
                   </>}

                   {item?.private !== 0 && <LockClosedIcon className={"w-5  pr-1 " + (past ? "text-gray-500" : "text-red-600")} />} 

                </div> 
               <p className="font-medium truncate pr-10">{event.title}</p> 
            </div>
            <div>
              <p className="truncate text-xs text-gray-500">{item?.instructor} {item?.sub && " - záskok"}</p>
            </div>
            {item?.instructor_img && <> 
              <div className={"absolute  top-10 right-2  text-xs font-medium h-7 flex items-center justify-between rounded-t-sm text-center    text-white px-2 " + (past ? " filter grayscale " : " ")}>
                <div className="w-10 rounded-full border border-gray-300 h-10 flex items-start overflow-hidden"><img src={item?.instructor_img} alt={item?.instructor}  /></div>
              </div>
              </>
            }
            <div className="flex-none ">
                
                
            </div>
            
         </div>
         
         </>
       )
      }    

      

    const mapToRBCFormat = e => Object.assign({}, e, {
        start: new Date(e.start),
        end: new Date(e.end)
    })
   
    const height = 1200; //odečtení záhlaví
   
    function changePadding() {
        let start = new Date().setHours(settings?.timetable?.hour,0,0,0)
        let end = new Date().setHours(settings?.timetable?.end_hour,0,0,0)
        let day = (end-start)/1000/60/60;
        let unit = (height-35)/day-4
        let now = parseInt(new Date().getTime().toString());
        now = (now-start)/1000/60/60 
        if(today) {
           setPadding(Math.round(now*unit-100))
        } else {
           setPadding(0);
        } 
        
    }
 
   
    useEffect(() => {  
        settings && location &&
        setSpaceItems(settings.locations[location].no_calendar); 
        if(settings && location && settings.locations) {
          setResources(settings.locations[location].resources); 
          setVisibleResources([settings.locations[location].resources[0]]); 
       }
      }
      // eslint-disable-next-line 
    , [settings,location]); 

    useEffect(() => {  
      let start, end;
      let date = props.selectedDate;
      if (calendarView === 'day') {
          props.setCalendarView('day')
          start = moment(date).startOf('day')
          end = moment(date).endOf('day')
          !disabledResources && setShowResources(true);
      }
      if (calendarView === 'week') {
          props.setCalendarView('week')
          start = moment(date).startOf('isoWeek')
          end = endOfWeek(date, { weekStartsOn: 1 })
          setShowResources(false);
      }
      
      getEvents(start,end,calendarView);
    }
      // eslint-disable-next-line 
    , [calendarView]); 

    useEffect(() => {  
      
        if(width < 768) {  
          visibleResources.length > 1 && 
          setVisibleResources([settings.locations[location].resources[0]]); 
        } else {
           visibleResources.length === 1 && resources.length > 1 &&
           setVisibleResources(settings.locations[location].resources); 
         }
      }
      // eslint-disable-next-line 
    , [width, resources]); 

    useEffect(() => {  
        executeScroll();
      }
      // eslint-disable-next-line 
    , [padding]); 

    useEffect(() => {  
        changePadding();           
      }
      // eslint-disable-next-line 
    , [startDate]); 

    
  
    return(
        <>
        
        <div className={"   overflow-y-auto  lg:grid  bg-white lg:grid-cols-12 h-full "}> 
          <div className={"hidden lg:block  bg-gray-200 lg:col-span-3 "}> 

          

              <SmallCalendar startDate={startDate} changeDate={changeDate} view={calendarView} today={today} slideModalOpen={slideModalOpen} setView={props.setCalendarView} disabledResources={disabledResources} setDisabledResources={setDisabledResources} />
              <div className="p-6 space-y-3 ">  
                {spaceItems && spaceItems.length > 0 &&
                    spaceItems.map((spaceItem, i) => ( 
                        <div  key={i}>   
                            <Space {...props} room={spaceItem?.resourceId} spaceItem={spaceItem?.spaceId} events={events} />  
                        </div>    
                    ))}
              </div> 
          </div>
          <main className={"min-h-0 flex-1 overflow-y-auto lg:col-span-9   "} style={props.electron ? {height: "calc(100vh - 9rem - 30px)"} 
              : width < 1024 ? {height: "calc(100vh - 8rem)"} : {height: "calc(100vh - 4rem)"}}> 
                
                <div className=" relative   pt-0 h-full " style={props.electron ? {minHeight: "calc(100vh - 9rem - 30px)"} : {minHeight: height + 'px'}} >
               
                    <Calendar
                        style={calendarView === 'week' ? {minWidth: '70em'} : {}}
                        selectable
                        view={calendarView}
                        localizer={localizer} 
                        events={events.map(mapToRBCFormat)}
                        startAccessor="start"
                        endAccessor="end"
                        date={props.selectedDate}
                        scrollToTime={new Date(1970, 1, 1, 16)}
                        onNavigate={(date, view) => {
                            
                            changeDate(date,view);
                            
                        }
                        
                        // date => {changeDate(date)},
                        // view => {updateTimes(props,view)}
                        }
                        // onView={(view) => { 
                        //      updateTimes(props,view); 
                        // }}
                        components={{ 
                            resourceHeader: props => ( <ResourceHeader {...props} resources={resources} setVisibleResources={setVisibleResources} />),
                            toolbar: props => ( <><CalendarMenu {...props} selStudio={selStudio} settings={settings} startDate={startDate} changeDate={changeDate} setCalendarView={setCalendarView} slideModalOpen={slideModalOpen} disabledResources={disabledResources} setDisabledResources={setDisabledResources} /></> ), 
                            event: event => EventComponent({
                                getEvents,
                                dialog,
                                response,
                                events,
                                setEvents,
                                ...props
                            },event),
                            week: {
                                event: event => WeekEventComponent({
                                  getEvents,
                                  dialog,
                                  handleSelect,
                                  resources,
                                  location,
                                  getEvents,
                                  setSlideModalContent,
                                  setSlideModalIsOpen,
                                  dialog,
                                  ...props
                              },event), // with the agenda view use a different component to render events
                            },
                            timeGutter: ResourceHeader
                        }} 
                        min={settings?.timetable?.hour && new Date(0, 0, 0, settings?.timetable?.hour, 0, 0)}
                        max={settings?.timetable?.end_hour && new Date(0, 0, 0, settings?.timetable?.end_hour, 0, 0)}
                        resources={showResources ? visibleResources : null} 
                        resourceIdAccessor="resourceId"
                        resourceTitleAccessor="resourceTitle"
                        onSelectSlot={(event) => handleSelect(
                            {
                                resources,
                                location,
                                getEvents,
                                dialog,
                                ...props
                            }
                        ,event,true)} 
                        />
                         <div className={"absolute top-0 left-9 lg:left-32 lg:-ml-2 z-0 h-0 overflow-hidden  opacity-0 " + (!today ? "opacity-0" : "")}  style={{paddingTop: padding + 'px'}}><span className="w-2 h-2 rounded-full bg-red-600 block p-1.5 border border-red-500" ref={myRef}></span></div> 
                    </div>
                </main>
            </div>
        </>
    )
}