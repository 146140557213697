import React, { useState, useEffect, useCallback } from "react";   
import { useForm } from "react-hook-form";  
import { GoodsCard } from "./goods";  
import { Notification } from "../layout/notification";  
import { axiosProtected } from "../../client";
 
export function goodSearch(search) {
    return (axiosProtected()).post('goods/search/',
    {
        query: search
    })
    .then(res =>   
        res.data 
     );
 }

export const GoodsSearch = (props) => {  
    const response = props.response
    const setResponse= props.setResponse
    const user = props.user
    const selectGoods = props.selectGoods
    const setToastOff = props.setToastOff
    
     
    const [results, setResults] = useState([]);   
    const [loading, setLoading] = useState(false);   
    const [active, setActive] = useState(); 
    const [notification, setNotification] = useState(false); 
    const [notificationText, setNotifText] = useState(false); 
    const [formId] = useState(parseInt(Math.round((Math.random()*10000000))));
     
     const { register, handleSubmit, reset } = useForm();
 

     const onSubmit = async data => { 
        if(data.search) { 
            setLoading(true)
            const searchResults = await goodSearch(data.search);
            await setResults(searchResults);  
            if(searchResults?.length > 0) {
                setActive(searchResults[0]?.id)
            }
            setLoading(false); 
        } else {
            setResults([])
        }
     };

     
     const submit = (data) => {
        selectGoods(active);
        setResults([]);
        reset({});
      };

     function setEan() {
        props.setEanActive(!props.eanActive)
     }

     function useKeyPress(key,action) {
        useEffect(() => {
          function onKeyup(e) {
            if (e.key === key) action() 
          }
          window.addEventListener('keyup', onKeyup);
          return () => window.removeEventListener('keyup', onKeyup);
          // eslint-disable-next-line
        }, [active,results]);
      } 

      useKeyPress('Enter', () => { 
          if(active) { 
          //  handleSubmit(submit);
        } 
        // eslint-disable-next-line
      });


      useEffect(() => {  
        setToastOff(props.eanActive);
        // eslint-disable-next-line
      }, [props.eanActive]); 
     
      useEffect(() => {  
        props.setFormId(formId)
        // eslint-disable-next-line
      }, [props.formId]); 

      useKeyPress('ArrowUp', () => { 
        let activeKey 
        if(results) {
            results.forEach((user, index) =>  {
              if(active===user.id) activeKey = index
            }) 
            activeKey = activeKey-1
            if(activeKey<0) {
              activeKey = 0;
            }
            setActive(results[activeKey]?.id);
        }
        return false;
      });

      useKeyPress('ArrowDown', () => { 
       
        let activeKey
        let lastItem
        if(results) {

            results.forEach((user, index) =>  {
              if(active===user.id) activeKey = index
              lastItem = index
            }) 
            activeKey = activeKey+1
            if(activeKey>lastItem) {
              activeKey = lastItem;
            }
            setActive(results[activeKey]?.id);
        }
        return false;
         
      });
     
     
      useEffect(() => { 
        if(response)  {
          if(response.ean && props.formId === formId) {
            (axiosProtected()).post('goods/search/',
              {
                  query: response.ean
              })
              .then(res => {
                if(res?.data[0]?.id) {
                    selectGoods(res?.data[0]?.id);
                } else {
                    setNotifText('Nenalezeno')
                    setNotification(true)
                }
                setResults([]);
                reset({});
                setResponse(false)
              }
              );
               
          }
        }
        // eslint-disable-next-line
    }, [response]);
 

     const debounce = (func, debounceTimeout) => {
        let timeout;
        return function executedFunction(...args) {
            const delayedFunction = () => {
                clearTimeout(timeout);
                func(...args);
            };
            clearTimeout(timeout);
            timeout = setTimeout(delayedFunction, debounceTimeout);
        };
    };

    // eslint-disable-next-line
     const debouncedSubmit = useCallback(
        debounce(name => {
            handleSubmit(onSubmit)();
        }, 700),
        []
     );
 

    return (
        <>
        <div className="relative ">
            
        <form  onSubmit={handleSubmit(submit)}   >
            <label htmlFor="search" className="sr-only">Vyhledávání</label>
                    <div className="relative max-w-xl">
                        <input autoComplete="off" {...register('search')} id="search" onChange={debouncedSubmit} onClick={debouncedSubmit} name="search" 
                        className="block w-full bg-white border border-gray-300 rounded-md py-2 pl-10 pr-3 text-sm placeholder-gray-500 focus:outline-none focus:text-gray-900 focus:placeholder-gray-400 focus:shadow-lg focus:ring-1 focus:ring-blue-500 focus:border-blue-500 sm:text-sm" placeholder="Hledat" type="search" />
                        <div className="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center"> 
                            
                                <svg className="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
                                </svg> 
                            
                        </div>
                        <div className="pointer-events-none absolute inset-y-0 right-14 pr-5 flex items-center"> 
                            {loading && 
                                <svg className="animate-spin -ml-1 mr-3 h-5 w-5 inline" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                            }
                            
                        </div>
                        <button onClick={
                            () => setEan()
                        } type="button" className={" bg-white hover:bg-gray-100  px-2 border-transparent absolute border-2 right-8 rounded-md top-1 focus:outline-none " + (props.eanActive ? (" text-gray-800") : (" text-gray-400"))}>
                            <div className="relative w-7 h-7">

                                <svg version="1.1" fill="currentColor" className="h-7 w-7 absolute" id="Layer_1" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 200 200"  >
                                    <path d="M49.666,129v17h4.104h4.104v-17v-77H53.77h-4.104V129z"/>
                                    <path d="M67.254,129v17h9.381h9.38v-17v-77h-9.38h-9.381V129z"/>
                                    <path d="M94.224,129v17h4.69h4.69v-17v-77h-4.69h-4.69V129z"/>
                                    <path d="M112.984,129v17h4.69h4.69v-17v-77h-4.69h-4.69V129z"/>
                                    <path d="M130.573,129v17h9.38h9.381v-17v-77h-9.381h-9.38V129z"/> 
                                    <polygon points="27,30 17,30 13.5,30 10,30 10,33.5 10,37 10,47 10,55.303 13.5,55.303 17,55.303 17,47 17,37 27,37 35.314,37 
                                        35.314,33.5 35.314,30 "/>
                                    <polygon points="186.651,46.994 186.651,36.994 186.651,33.494 186.651,29.994 183.151,29.994 179.651,29.994 169.651,29.994 
                                        161.349,29.994 161.349,33.494 161.349,36.994 169.651,36.994 179.651,36.994 179.651,46.994 179.651,55.309 183.151,55.309 
                                        186.651,55.309 "/>
                                    <polygon points="169.657,170 179.657,170 183.157,170 186.657,170 186.657,166.5 186.657,163 186.657,153 186.657,144.697 
                                        183.157,144.697 179.657,144.697 179.657,153 179.657,163 169.657,163 161.343,163 161.343,166.5 161.343,170 "/>
                                    <polygon points="10.006,153.006 10.006,163.006 10.006,166.506 10.006,170.006 13.506,170.006 17.006,170.006 27.006,170.006 
                                        35.309,170.006 35.309,166.506 35.309,163.006 27.006,163.006 17.006,163.006 17.006,153.006 17.006,144.691 13.506,144.691 
                                        10.006,144.691 "/>
                                     
                                </svg>
                                {props.eanActive &&
                                    <svg version="1.1" fill="currentColor" className="  w-7 animate-move  absolute" id="Layer_1" xmlns="http://www.w3.org/2000/svg"   viewBox="0 -42.106 620 110" >
                                        
                                    <path fill="#FF0000" d="M83.41,20.946v20.947h221.742h221.741V20.946V0H305.152H83.41V20.946z"/>
                                    </svg>
                                }

                            </div>
                            </button>
                </div>
             
              </form>
 
                    
                {results.length > 0 && 
                    <div className="min-w-full table-auto mt-1 absolute z-50">
                        <div className="bg-white shadow-lg overflow-hidden sm:rounded-md">
                            <ul className="divide-y divide-gray-200">
                                {results.map(good => (
                                    <GoodsCard submit={handleSubmit(submit)} item={good} key={user.id} setResults={setResults} active={active} setActive={setActive} />
                                ))}
                            </ul>
                        </div>
                    </div>
                 }
                 
            </div>
            <Notification text={notificationText} notification={notification} setNotification={setNotification} /> 
                
        </>
    )
}

export default GoodsSearch