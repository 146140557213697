
import React, { useState, useEffect  } from "react";  
import {   Menu, Transition } from '@headlessui/react'
import { useForm } from "react-hook-form";
import {HiOutlineAdjustments} from "react-icons/hi"
import { ChevronDownIcon } from "@heroicons/react/solid";
import { GoodsTagComponent } from "./GoodsTagComponent";

export default function GoodsTags({cat, catTags, params, setParams, openStatus,setOpenStatus}) {
    const { register, handleSubmit, setValue } = useForm();
    const onSubmit = data => {}; 
    
    const [tagList, setTagList] = useState([]);  
    const [tagBackup, setTagBackup] = useState([]);   
 
    const [defaultOpen, setDefaultOpen] = useState(false);
     
    const [settings] = useState(JSON.parse(localStorage.getItem('settings')));
    
    let tags
    if(catTags) {
        tags = catTags
    } else {
        tags = settings?.shop?.tags
    }
     
    useEffect(() => {
        setTagList(tags);
        setTagBackup(tags);
    }, [tags]); 
    
    useEffect(() => {
        setValue('filter');
    }, [cat]); 
 
    function search(e) { 
        const val = e.target.value.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
        let tags = tagBackup;
        tags = tags.map((section) => {
            return ({
                id: section.id,
                name: section.name,
                alias: section.alias,
                values: section.values.filter((tag) => {
                    let tagName = tag.value.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase() 
                    return tagName.indexOf(val) !== -1
                })
            })
        }) 
        if(val) {
            setTagList(tags)
            setDefaultOpen(true)
        } else {
            setTagList(tagBackup)
            setDefaultOpen(false)
        }
        //let result = cartList.filter(t=>t.id < 120); 
        
    }
   

      
      return ( 
        <> 
        <div className="relative z-50"> 
            <Menu>
                {({ open }) => (
                    <> 
                    <Menu.Button className={" rounded-md flex space-x-2 items-center text-sm focus:outline-none   text-gray-600  hover:bg-gray-200   hover:text-gray-700 border " 
                        + (open ? ' bg-gray-200  '  : ' ')} id="user-menu" >
                    <div className="relative flex space-x-2 items-center py-2 px-3 " >
                        
                             
                        <HiOutlineAdjustments className="w-5 h-5" />
                        <span className="truncate">
                            Parametry
                        </span>
                        <ChevronDownIcon className="w-4 h-4" />
                    </div>
                    </Menu.Button> 
                
                    <Transition className="absolute lg:left-0 top-18 w-72  lg:w-96  max-w-2xl "
                    show={open}
                    enter="transition ease-out duration-100 transform"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="transition ease-in duration-75 transform"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                    >
                    <Menu.Items
                    static
                    className=" mt-1 mb-2 bg-white  rounded-md shadow-lg outline-none"
                    >
                    
                   <div className="px-4 py-3 bg-gray-100 rounded-md">
                         <div className="mb-2 font-medium">
                           Parametry
                        </div>
                        <form onSubmit={handleSubmit(onSubmit)} className="relative">
                    
                            <input {...register('filter')} onChange={search}   
                            type="search" name="filter" autoComplete="off"   placeholder="Hledat" 
                            className="block w-full bg-white border border-gray-300 rounded-md py-2 pl-10 pr-3 text-sm placeholder-gray-500 focus:outline-none focus:text-gray-900 focus:placeholder-gray-400  focus:ring-1 focus:ring-blue-500 focus:border-blue-500 sm:text-sm" placeholder="Hledat" type="search" />
                        <div className="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center"> 
                            
                                <svg className="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
                                </svg> 
                            
                            </div> 
                        </form>
                    </div> 

                   
                           {params && <GoodsTagComponent  defaultOpen={defaultOpen} tagList={tagList} openStatus={openStatus} setOpenStatus={setOpenStatus} params={params} setParams={setParams}  />}
                        
                      
                    </Menu.Items>
                </Transition>
                </>
            )}
                    
            </Menu>
        </div>
        </>
      )

   }