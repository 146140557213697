import React, {useEffect, useState} from 'react'; 
import axios from "axios"; 
import {Transition} from "@headlessui/react";
import {UserCard} from "../users/users"
import { useHistory } from "react-router-dom"; 

const API_URL = localStorage.getItem('domain');


const NotFound = () => {
    return (
        <>
        <div className="flex items-start m-3 p-3">
            <div className="flex-shrink-0">
            
            <svg className="h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
            </svg>
            </div>
            <div className="ml-3 w-0 flex-1 pt-0.5">
            <p className="text-sm font-medium text-gray-900">
               Nenalezeno
            </p>
            
            </div>
            <div className="ml-4 flex-shrink-0 flex">
             
            </div>
        </div>
        </>
    )
}

export const InfoPanel = (props) => {
    const toast = props.toast
    const setToast = props.setToast 
    const toastData = props.toastData
    const setToastData = props.setToastData
    const response = props.response
    const setResponse = props.setResponse
    const setModalIsOpen = props.setModalIsOpen
    const toastOff = props.toastOff
    const history = useHistory();
    const [active, setActive] = useState(); 
    const [color, setColor] = useState(); 

    const user = props.user;   
    const token = user?.accessToken;
    const config = {
        headers: {
            "Authorization" : `Bearer ` + token
        }
    }  
 
    function selectUser(id) { 
        history.push("/users/" + id);
        setToast(false); 
        setResponse(false); 
        setModalIsOpen(false); 
    }

    function useKeyPress(key,action) {
        useEffect(() => {
          function onKeyup(e) {
            if (e.key === key) action() 
          }
          window.addEventListener('keyup', onKeyup);
          return () => window.removeEventListener('keyup', onKeyup);
          // eslint-disable-next-line
        }, [active,toastData]);
    } 

    useKeyPress('Enter', () => { 
        if(active && toast && !toastOff) { 
          selectUser(active)
      } 
    });

     useEffect(() => { 
        let timer1 = setTimeout(() => setToast(false), 10000);

        return () => {
            clearTimeout(timer1);
        };
        // eslint-disable-next-line
     }, [toastData])

     useEffect(() => { 
          if(response && !toastOff)  {
            if(response.ean || response.rfid) {
                axios.post(API_URL + 'users_data/search/',
                {
                    ean: response.ean,
                    rfid: response.rfid
                },config)
                .then(res => {
                    if(res?.data[0]?.id) { 
                        setColor("border-blue-600")
                        setActive(res?.data[0]?.id)
                        setToastData(
                            <>
                                <UserCard user={res.data[0]} submit={selectUser} />
                            </>
                        );
                        setToast(true)
                        setResponse(false) 
                    } else { 
                        setColor("border-red-600")
                        setToastData(
                            <>
                                <NotFound />
                            </>
                        ); 
                        setToast(true)
                        setResponse(false)  
                    }

                    }
                );
                 
            }
          }
          // eslint-disable-next-line
      }, [response]);
   

    return (
        <>
                    
            <Transition
                show={toast}
                className="fixed inset-0 z-50 flex items-end justify-center px-4 py-6 pointer-events-none sm:p-6 sm:px-10 sm:items-start sm:justify-end top-8" 
                enter="transform ease-out duration-300 transition"
                enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-20"
                enterTo="translate-y-0 opacity-100 sm:translate-x-0"
                leave="transform ease-in duration-300 transition"
                leaveFrom="opacity-100 "
                leaveTo="opacity-0 translate-x-20 "
            > 
            <div className={"max-w-sm w-full bg-gray-100 shadow-lg  pointer-events-auto border-l-8   " + (color ? (color) : ("border-blue-600 ")) }>
                <div className="py-4 relative">
                <div className="flex items-start">
                <button onClick={() => setToast(false)} className=" rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 absolute top-2 right-2">
                        <span className="sr-only">Close</span>
                        
                        <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                        </svg>
                    </button>
                    <ul className="divide-y divide-gray-200">   
                    {toastData}
                    </ul>
                     
                </div>
                </div>
            </div>
            </Transition>
             
        </>
    )
}