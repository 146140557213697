import axiosInstance, {axiosProtected} from "../client";

// eslint-disable-next-line
export default {
    getSettings: async function() {
        try { 
            let res = await (axiosProtected()).post('settings/');
            localStorage.setItem("settings", JSON.stringify(res.data)); 
            return res.data;
        } catch (err) {  
            throw err;
        }
    },
    getLogo: async function() {
        try { 
            let res = await axiosInstance.post('settings/logo/');
            localStorage.setItem("logo", JSON.stringify(res.data)); 
            return res.data;
        } catch (err) {  
            throw err;
        }
    }
}