//import { DayList } from "./DayList";
import { StudioSelect } from "./StudioSelect";
import moment from 'moment'
import 'moment/locale/cs';
import { DateSelect } from "./DateSelect";
import StudioSubmenu from "./StudioSubmenu";

export const CalendarMenu = ({changeDate,startDate,selStudio,settings,slideModalOpen,disabledResources,setDisabledResources,...props}) => { 
     
    let today = false;
    if(moment(startDate).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')) {
        today = true;
    } 
    
    let view = props.view;

        return (
            <>
          
                
                
           <div className="mx-auto mb-1 z-20 w-full p-3   fixed left-0  h-16  top-16 justify-between lg:hidden bg-white shadow-md border-b border-gray-200  ">

                {/* <div className="block lg:hidden">
                    <DayList {...props} changeDate={changeDate} date={startDate} /> 

                </div> */}
               <div className="flex w-full justify-between items-center">
                {settings && Object.keys(settings?.locations).length > 1 && 
                   <div className=" flex"> 
                    <StudioSelect {...props} selStudio={selStudio} settings={settings} /> 
                    </div>
                }
               
                 
                <div className="flex lg:hidden   text-sm justify-end relative "> 
                    <DateSelect changeDate={changeDate} startDate={startDate} />
                </div>  
                <div >
                 <div className="flex">
                    <div className=" w-8" >
                    {!today &&
                        
                        <button className="  btn btn-next text-gray-600 lg:px-3  lg:mr-2 mr-1 mt-0  h-10 focus:outline-none rounded-md focus:bg-gray-300 hover:bg-gray-200" title="Dnes" onClick={() => changeDate(new Date())}>
                            <svg className="w-5 inline" fill="currentColor" stroke="none" viewBox="0 0 551.13 551.13" xmlns="http://www.w3.org/2000/svg">
                                <path d="m275.531 172.228-.05 120.493c0 4.575 1.816 8.948 5.046 12.177l86.198 86.181 24.354-24.354-81.153-81.136.05-113.361z"/>
                                <path d="m310.011 34.445c-121.23 0-221.563 90.033-238.367 206.674h-71.644l86.114 86.114 86.114-86.114h-65.78c16.477-97.589 101.355-172.228 203.563-172.228 113.966 0 206.674 92.707 206.674 206.674s-92.707 206.674-206.674 206.674c-64.064 0-123.469-28.996-162.978-79.555l-27.146 21.192c46.084 58.968 115.379 92.808 190.124 92.808 132.955 0 241.119-108.181 241.119-241.119s-108.164-241.119-241.119-241.12z"/>
                            </svg> 
                            
                        </button>
                        }

                    </div>
                   
                    <button className={"btn btn-back text-gray-600  px-3 focus:outline-none mx-2 w-auto rounded-md h-10    hover:bg-gray-200 "
                    + (view==='day' && "bg-gray-200") } 
                    onClick={() => props.setCalendarView('day')}><span className="label-filter-off">
                          <svg version="1.1" className="w-5 -mt-1   h-5 inline " fill="currentColor" stroke="none" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 29.121 29.121"  > 
                            <path d="M21.706,6.146c1.116,0,2.02-0.898,2.02-2.016V2.02c0-1.119-0.903-2.02-2.02-2.02
                                s-2.019,0.9-2.019,2.02v2.111C19.688,5.248,20.59,6.146,21.706,6.146z"/>
                            <path  d="M28.882,3.494h-4.066v1.027c0,1.695-1.377,3.076-3.075,3.076c-1.7,0-3.074-1.381-3.074-3.076V3.494
                                h-8.205v1.027c0,1.695-1.379,3.076-3.076,3.076s-3.075-1.38-3.075-3.076V3.494L0.208,3.443v25.678H2.26h24.604l2.049-0.006
                                L28.882,3.494z M26.862,27.076H2.26V10.672h24.604v16.404H26.862z"/>
                            <path  d="M7.354,6.146c1.116,0,2.021-0.898,2.021-2.016V2.02C9.375,0.9,8.47,0,7.354,0S5.336,0.9,5.336,2.02
                                v2.111C5.336,5.248,6.237,6.146,7.354,6.146z"/>
                            <text x="14.2" y="18.2" textAnchor="middle" dominantBaseline="central" >{moment(props.date).format('D')}</text> 
                        </svg>


                    </span>
                    </button>

        
                    
                    <button className={"btn btn-back text-gray-600 mx-2 focus:outline-none w-auto px-3 mr-0 rounded-md h-10 ml-0   hover:bg-gray-200 "
                    + (view==='week' && "bg-gray-200")} onClick={() => props.setCalendarView('week')}><span className="label-filter-off">
                            <svg version="1.1" className="w-5 -mt-1   h-5 inline " fill="currentColor" stroke="none" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
                        viewBox="0 0 29.121 29.121"  >
                        
                        <path d="M21.706,6.146c1.116,0,2.02-0.898,2.02-2.016V2.02c0-1.119-0.903-2.02-2.02-2.02
                            s-2.019,0.9-2.019,2.02v2.111C19.688,5.248,20.59,6.146,21.706,6.146z"/>
                        <path  d="M28.882,3.494h-4.066v1.027c0,1.695-1.377,3.076-3.075,3.076c-1.7,0-3.074-1.381-3.074-3.076V3.494
                            h-8.205v1.027c0,1.695-1.379,3.076-3.076,3.076s-3.075-1.38-3.075-3.076V3.494L0.208,3.443v25.678H2.26h24.604l2.049-0.006
                            L28.882,3.494z M26.862,27.076H2.26V10.672h24.604v16.404H26.862z"/>
                        <path  d="M7.354,6.146c1.116,0,2.021-0.898,2.021-2.016V2.02C9.375,0.9,8.47,0,7.354,0S5.336,0.9,5.336,2.02
                            v2.111C5.336,5.248,6.237,6.146,7.354,6.146z"/>
                        <rect x="10.468" y="12.873"  width="3.231" height="2.852" fill="#ccc"/>
                        <rect x="15.692" y="12.873"  width="3.234" height="2.852" fill="#ccc" />
                        <rect x="20.537" y="12.873"  width="3.231" height="2.852" fill="#ccc"/>
                        <rect x="10.468" y="17.609"  width="3.231" height="2.85"/>
                        <rect x="15.692" y="17.609"  width="3.234" height="2.85"/>
                        <rect x="20.537" y="17.609"  width="3.231" height="2.85"/>
                        <rect x="10.468" y="22.439"  width="3.231" height="2.85" fill="#ccc"/>
                        <rect x="5.336" y="17.609"  width="3.229" height="2.85"/>
                        <rect x="5.336" y="22.439"  width="3.229" height="2.85" fill="#ccc"/>
                        <rect x="15.692" y="22.439"  width="3.234" height="2.85" fill="#ccc"/>
                        <rect x="20.537" y="22.439"  width="3.231" height="2.85" fill="#ccc"/>
                        </svg>
                        </span>
                        </button>
                       <div className="relative flex items-center">
                            <StudioSubmenu slideModalOpen={slideModalOpen} disabledResources={disabledResources} setDisabledResources={setDisabledResources}   />

                       </div>

                       </div> 
                 </div>
                     
               </div>


            </div >
            
                 </>
          
         
        )
    }