
import React, { useState, useEffect } from "react"; 
import { useParams, useHistory, NavLink } from "react-router-dom";
import { useForm } from "react-hook-form";   
import Goods from './goods';
import Cart, { addToCart }  from "./cart";    
import CartLayout from './cartLayout'; 
import { axiosProtected } from "../../client";
import {GiCoffeeCup} from "react-icons/gi"
import {IoTicketOutline} from "react-icons/io5"
import { Services } from "../order/services/Services";
import Products from "../goods/Products";
import {RiShoppingBagLine} from "react-icons/ri"
import { XIcon } from "@heroicons/react/solid";
import { Shimmer } from "../layout/Shimmer";

const menuItems = [
  { name: 'Služby', id: 1, current: true, icon: IoTicketOutline, link: 'sluzby' },
  { name: 'Bar', id:2, current: false, icon: GiCoffeeCup, link: 'bar' },
  { name: 'Produkty', id:3, current: false, icon: RiShoppingBagLine, link: 'produkty' },
]

export default function Carts(props) { 
   
    const electron = props.electron
     
    let { type, catAlias } = useParams();  
    const [settings] = useState(JSON.parse(localStorage.getItem('settings')));

    let categories = settings?.shop?.goods_categories
 
    const [services, setServices] = useState([]);  
    const [activeTab, setActiveTab] = useState(0); 
 
    const [searchQuery, setSearchQuery] = useState();
    const price = props.price 
    
    const [cartItems, setItems] = useState([]); 
    
    const [cart, updateCart] = useState(); 
    const [catData, setCatData] = useState();
    const [searchString, setSearchString] = useState();
   
    const response = props.response;
    const setResponse = props.setResponse;
 
    const [setRfid] = useState('Přiřadit košík'); 
    const [isWaiting, setWaiting] = useState(false);
    const [eanValue, setEan] = useState();
    // eslint-disable-next-line
    const [alert, setAlert] = useState(false); 
    // eslint-disable-next-line
    const [alertText, setAlerttext] = useState(); 
    // eslint-disable-next-line
    const [alertColor, setAlertColor] = useState('blue'); 
    const [reset, setReset] = useState(false); 
    const [cartId, setCartId] = useState(localStorage.getItem("cartId"));
 
      const history = useHistory(); 
       
      const [redir, setRedir] = useState(false); 
   
      useEffect(() => {
          if(!cartId) {
            // eslint-disable-next-line
            let id = 'cart_' + Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
            localStorage.setItem("cartId", id);
            setCartId(id)
         }
      });
    

      useEffect(() => {
        if(type==='bar') { 
            setCatData({
                name: 'Bar'
            })
         } else if (type==='produkty') { 
            if(catAlias && categories) {
               let activeCat = categories.filter((category) => {
                   let catName = category.url.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase() 
                   return catName.indexOf(catAlias) !== -1
               }) 
               if(activeCat && activeCat.length > 0) {
                  setCatData(activeCat[0])
               } else {
                    setCatData({
                        name: 'Prodej'
                    }) 
               }
            } else {
                setCatData({
                    name: 'Prodej'
                }) 
            }
           
        } else if (type==='sluzby') {
            setCatData({
                name: 'Služby'
            }) 
        }  
      }, [catAlias, type]);

      useEffect(() => { 
         if(!type) {
            // eslint-disable-next-line
            settings?.shop?.shop_goods ? history.push('/prodej/produkty') : history.push('/prodej/produkty/bar')
         }
        
      }, [type]);
 
      function getItems(cartId) {
        cartId &&
        (axiosProtected()).post('cart/items/' + cartId + '/',
          { 
            data: {
                'cart': cartId
            }   
          })
          .then(res => {
            setItems(res.data.items);
            setCart(res.data.cart);
          })
          .catch(err => {
            console.log(err);
          });
      }
  
      useEffect(() => {
        if (isWaiting) {
          if(response)  {
            if(response.query.rfid) {
              setRfid(response.query.rfid);
              setWaiting(false);    
              setResponse(false);  
            }
           
          }
        }
        if(response?.carts === 'update') {
          getItems(cartId);
        }
        // eslint-disable-next-line
      }, [response]);
    
  
      function displayAlert(text) {
          text = JSON.parse(text);
          setAlert(true); 
          setAlerttext(text.text);
          if(text.color) {
              setAlertColor(text.color);
          } 
          setTimeout(() => {
              setAlert(false); 
          }, 10000);
      }
  
      function addEan(ean) {
          ean &&
          (axiosProtected())
          .get('cart/item_add/' + cartId + '/?ean=' + ean).then(res => {
              if(res.data) {
                  setItems(res.data.items);
                  //redir && history.push('/prodej/' + cartId);
                  //setRedir(false);
                  setEan(false);
              }
          }); 
      } 
    
       
  
      useEffect(() => {
          addEan(eanValue);  
          // eslint-disable-next-line
      }, [eanValue])
      
      useEffect(() => { 
          if(response)  {
              if(response.ean) {
                  setEan(response.ean); 
              }
              if(response.alert) {
                  displayAlert(response.alert);
              }
          } 
          setResponse(false);
          // eslint-disable-next-line
        }, [response]);
   
      useEffect(() => { 
        getItems(cartId)
      }
      // eslint-disable-next-line
      , [cartId]);
  
 
    function setCart(data) {
        updateCart(data)
    }

    async function getServices() {
        try {
            const res = await (axiosProtected()).post('timetable/services/',
            {
                serviceId: price?.serviceId,
                roomData: props.roomData,
            }); // Fetch the resource 
            res.data.services &&
            setServices(res.data.services); 
        // Do your JSON handling here
        } catch(err) {
            console.log(err);
            // This probably means your response is text, do you text handling here
        }
    }

    async function addItem(id) { 
        let result = await addToCart(cartId,{id:id},false,cart?.client)
        //redir && history.push('/prodej/' + cartId);
        setItems(result.items)
        setRedir(false);
        setCart(result.cart)
    }

    async function selectGoods(id,services) {
        let result = await addToCart(cartId,{id:id},services,cart?.client)
        //history.push('/prodej/' + cartId);
        setItems(result.items)
        setCart(result.cart)
    }
 
    useEffect(() => {  
        getServices()
      }
      // eslint-disable-next-line 
    , []);

    function resetSearchQuery() {
        setReset(true)
        setSearchQuery(false);
        setSearchString(false);
    }


    return (
    <>
    
    <CartLayout {...props} menuItems={menuItems} type={type}  selectGoods={selectGoods} setRedir={setRedir} cat={catAlias}  setSearchQuery={setSearchQuery} reset={reset} setReset={setReset} setSearchString={setSearchString} >

  
        <div  className={"  lg:grid md:grid-cols-2  2xl:grid-cols-5 h-screen bg-white"}  style={electron ? {height:'calc(100vh - 5rem - 30px)'} : {height:'calc(100vh - 5rem)'}} >  
            <div className="p-8 2xl:col-span-3 ">
                <div className="flex items-center">{searchQuery ? <><div className=" text-2xl inline">Vyhledávání: <span className="font-bold">{searchString}</span></div><NavLink to={'/prodej/produkty'} className="p-2" onClick={() => resetSearchQuery()}><XIcon className="w-6 h-6 ml-3 inline text-gray-400" /></NavLink></> : catData && <div className="mb-5 text-2xl ">{catData?.name}</div>}</div>
                  
                  {!searchQuery ? 
                  <>
                    <div className={type === 'sluzby' ? "block" : "hidden"}>
                        <Services cartId={cartId}  activeTab={activeTab} setActiveTab={setActiveTab} price={price} setItems={setItems} setCart={setCart}  services={services} />
                                    
                    </div>  
                    {type === 'bar' && 
                        <div >
                            <Products cartId={cartId} addItem={addItem} cat={'bar'}  hideHeader={true} />
                        </div>                      
                    }
                    {type === 'produkty' && 
                    <div >
                        <Products cartId={cartId} addItem={addItem} cat={catAlias}  />
                    
                    </div>  
                    }
                  </>
                    :
                    <>
                        <Products cartId={cartId} addItem={addItem} cat={catAlias} hideHeader={true} searchQuery={searchQuery} />
                    
                    </>

                  }
                 
                  

            </div> 
            <div className="bg-gray-100 2xl:col-span-2 rounded-br-lg p-5 pb-10  border-l border-gray-300" >
                <Cart {...props} cartId={cartId} client={cart?.client} cartItems={cartItems} setItems={setItems} redir={true} cart={cart} setCart={setCart}  />

            </div>

        </div>
    
    
                 
                    
</CartLayout>           
                      
    </> )
}