
import {IoImageOutline} from "react-icons/io5"
import { useForm } from "react-hook-form"; 
import { PlusCircleIcon } from "@heroicons/react/solid";

export const Product = ({item, list, itemIdx, addItem, hasMore, lastElementRef, loading}) => {

    const { register, handleSubmit } = useForm({mode: 'onSubmit'}); 
    let selectedClone = false;
    if(item.clone) {
        selectedClone = item.id
    }

    const onSubmit = async data => {  
      try {  
          addItem(data.id)
      } catch (err) {  
        //loading = false
      }  
    }; 

    return(
        <div key={itemIdx} className="flex justify-between items-center group hover:bg-gray-100">
                        <div className="py-3 pr-8 w-3/5">
                          <div className="flex items-center">
                            <div className="w-16 h-16 mr-6 ml-2 overflow-hidden flex items-center justify-center border rounded">
                                   
                                {item.img ?
                                    <img
                                    src={item.img}
                                    alt={item.name}
                                    className="h-16   "
                                    />
                                    : 
                                    <>
                                    <div className="flex items-center justify-center bg-gray-50 rounded w-16 h-16 border">
                                       <IoImageOutline className="w-9 h-9 text-gray-400" />

                                    </div>
                                    </>
                                }
                                
                                </div>
                            <div>
                              <div className="font-medium text-gray-900">{item.name}</div>
                              <div className="mt-1 sm:hidden text-right ">{item.price_vat.toLocaleString()} Kč</div>
                            </div>
                          </div>
                        </div>
                        <div className="hidden py-3 pr-3 sm:table-cell text-right w-32">{item.price_vat.toLocaleString()} Kč</div>
                        <div className="hidden py-3 pr-8 sm:table-cell w-32">
                          
                        </div>
                        <div className="py-3 font-medium text-right whitespace-nowrap  w-32">
                          <form onSubmit={handleSubmit(onSubmit)} className="flex items-center justify-end space-x-2 pr-2">
                              {item?.clones ? 
                                <select  {...register('id',{ required: true })} defaultValue={selectedClone}  className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block sm:text-sm border-gray-300 rounded-md pr-8 ">
                                  {item?.clones.map((clone,i) => (
                                      <option key={i} value={clone.id}>{clone.name}</option>
                                  ))}                                         
                                </select>
                              : <input type="hidden" {...register("id",{ required: true })} name="id" defaultValue={item.id} /> }
                              <button type="submit" className=" text-blue-600   hover:text-blue-800 flex items-center focus:outline-none">
                                  <PlusCircleIcon className={"w-6 " + (loading && "opacity-50")} />
                              </button>
                               
                          </form>
                          {list.length === parseInt(itemIdx+1) && hasMore && <span ref={lastElementRef}></span>}
                        </div>
                      </div> 
                  
    )
}