 

function CartItemslist({cartItems,deleteItem,updateItem}) {
    
    
    return(
        <div className="divide-y divide-gray-300"> 
        
        { cartItems && cartItems.length > 0 &&  
            <>  
                {cartItems.map(item => (
                <div key={item.id} className="grid grid-cols-10 w-full text-gray-800 items-center  pin-r pin-y  font-medium " cellSpacing="0">
                    <div >
                        <button type="button" onClick={() => deleteItem(item.id)} className="text-gray-400 hover:text-gray-900 focus:outline-none py-3">
                        <svg className="h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                            </svg>
                        </button>
                        </div>
                    
                    <div className="col-span-5 text-sm" title={item.name}>
                     
                        <p className="truncate">{item.name}</p>
                        {item.note && <p className="truncate">{item.note}</p>}
                        
                     
                    </div>
                    <div className="text-center col-span-2"  > 
                        <div className="flex flex-row w-full h-8 text-center text-gray-400 m-2 ">
                        <button type="button" onClick={() => updateItem(item.id,parseInt(item.count)-1)} className="focus:outline-none hover:text-gray-900">
                            <svg className="h-8" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                        </button>
                        <span className="inline-block w-8 pt-1 text-gray-800 ">{item.count}</span>
                        <button type="button" onClick={() => updateItem(item.id,parseInt(item.count)+1)} className="focus:outline-none hover:text-gray-900">
                            <svg  className="h-8" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                        </button>
                        </div> 
                    </div>
                    <div className="text-right  col-span-2">
                        <span className="text-sm font-medium">
                            {parseFloat(item.price).toLocaleString('cs')} Kč
                        </span>
                    </div> 
                </div>
                ))}
            </>
       
       
        
        } 
        <div />
    </div>
    )

}

export default CartItemslist;