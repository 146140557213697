
import React, { useState } from "react";
import OrderMenu from "./orderMenu"; 
import Wizard from '../wizard/wizard';
import { useForm } from "react-hook-form"; 
import TextEditor from '../layout/editor';

export default function OrderCreate() {
  const [state, setState] = useState({
    first: {},
    second: {},
    third: {}
  }); 
  const { register, handleSubmit, getValues, errors, trigger } = useForm({
      mode: "onBlur"
  });
  const onSubmit = data => console.log(state); 

  const [currentStep, setCurrentStep] = useState(1);
  const handleClick = async (clickType) => {
      let values = [];
      let result = false;
      if(currentStep===1 && clickType) { 
          values = getValues();
          setState({
              first: values,
              second: state.second,
              third: state.third,
          });
          result = await trigger(); 
      }
      if(currentStep===2 && clickType) {
          values = getValues2();
          setState({
              first: state.first,
              second: values,
              third: state.third,
          });
          result = await trigger2(); 
      }
      if(currentStep===3 && clickType) {
          values = getValues3();
          setState(
              first => state.first,
              second => state.second,
              third => values
          ); 
          result = await trigger3(); 
      }
 
      if(result || !clickType) {
          let newStep = currentStep;
          (clickType === "next") ? newStep++ : newStep--;   
          

          // Check if steps are within the boundary
          if (newStep > 0 && newStep <= stepArray.length) {
              setCurrentStep(newStep)
          } 

      }

  }

  const {
      register: register2,
      errors: errors2,
      getValues: getValues2,
      trigger: trigger2
  } = useForm({mode: "onChange"});

  const {
      getValues: getValues3,
      trigger: trigger3
  } = useForm({mode: "onChange"});


  const stepArray = [
      "Označení, čas",
      // "Location",
      // "Service Offered",
      "Cena",
      "Další nastavení"
  ];

  return(
    <>
    <main class=" mx-auto pb-10 lg:py-12 lg:px-8 bg-gray-100">
      
    <div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
    <aside className="py-6 px-2 sm:px-6 lg:py-0 lg:px-0 lg:col-span-2">
       <OrderMenu />
    </aside>
 
    <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-10">
      {/* <!-- Billing history --> */}
      <section aria-labelledby="billing_history_heading">
        <div className="bg-white shadow sm:rounded-md sm:overflow-hidden p-10 pt-0">
        <Wizard  stepArray={stepArray} setState={setState} handleClick={handleClick}  currentStep={currentStep} />
          
          <>

          

          <div  className="flex">
          <div className="text-xl lg:text-2xl w-full max-w-2/3">Nový</div>
          <div className="text-lg w-full max-w-1/3 text-right p-1 lg:text-xl"></div>
          </div>
       

                  <form key={1} onSubmit={handleSubmit(onSubmit)}
                    className={"space-y-8 divide-y divide-gray-200 " + (currentStep===1 ? '' : 'hidden') }
                   >
                  <div className="mt-4">
                      <label className="block text-gray-700 text-sm font-bold mb-2">E-mail</label>
                      <input name="login" type="text" ref={register({ required: true })} className="bg-gray-200 text-gray-700 focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2 px-4 block w-full "   />
                      {errors.login && 
                      <div className="rounded-md bg-red-50 mt-1 p-2"> 
                          <svg className="h-5 w-5 text-red-400 inline mr-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                              <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                          </svg> 
                          <span className="text-sm text-red-700">
                              Vyplňte přihlašovací jméno
                          </span>
                      </div>
                      }
                  </div> 
                   

                  <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
                      <div>
                          <h3 className="text-lg leading-6 font-medium text-gray-900">
                          Personal Information
                          </h3>
                          <p className="mt-1 max-w-2xl text-sm text-gray-500">
                          Use a permanent address where you can receive mail.
                          </p>
                      </div>
                      <div className="space-y-6 sm:space-y-5">
                          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                          <label htmlFor="first_name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                              First name
                          </label>
                          <div className="mt-1 sm:mt-0 sm:col-span-2">
                              <input type="text" name="first_name" id="first_name"  className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md" />
                          </div>
                          </div>

                          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                          <label htmlFor="last_name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                              Last name
                          </label>
                          <div className="mt-1 sm:mt-0 sm:col-span-2">
                              <input type="text" name="last_name" id="last_name"  className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md" />
                          </div>
                          </div>

                          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                          <label htmlFor="email" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                              Email address
                          </label>
                          <div className="mt-1 sm:mt-0 sm:col-span-2">
                              <input id="email" name="email" type="email"  className="block max-w-lg w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md" />
                          </div>
                          </div>

                          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                          <label htmlFor="country" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                              Country / Region
                          </label>
                          <div className="mt-1 sm:mt-0 sm:col-span-2">
                              <select id="country" name="country"  className="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md">
                              <option>United States</option>
                              <option>Canada</option>
                              <option>Mexico</option>
                              </select>
                          </div>
                      </div>
                      </div>
                      </div>
                      <TextEditor ref={register} />
                  </form>

              
                  <form key={2} onSubmit={handleSubmit(onSubmit)} className={ (currentStep===2 ? '' : 'hidden') }>
                  <div className="mt-4">
                      <label className="block text-gray-700 text-sm font-bold mb-2">step dva</label>
                      <input name="test" type="text" ref={register2({ required: true })} className="bg-gray-200 text-gray-700 focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2 px-4 block w-full appearance-none"   />
                      {errors2.test && 
                      <div className="rounded-md bg-red-50 mt-1 p-2"> 
                          <svg className="h-5 w-5 text-red-400 inline mr-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                              <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                          </svg> 
                          <span className="text-sm text-red-700">
                              Vyplňte 
                          </span>
                      </div>
                      }
                  </div> 
                  </form>
              

              <div className="container flex justify-around my-8 ">
              <div className="w-1/2">
                  {currentStep > 1 
                      ? ( <button onClick={() => handleClick()} className="transition duration-300 ease-in-out focus:outline-none focus:shadow-outline bg-orange-700 hover:bg-orange-900 font-normal py-2 px-4 mr-1 rounded"> Previous </button>)
                      : (<></>)
                  }
              </div>
              <div className="w-1/2 text-right">
                  {stepArray.length === currentStep
                  ? ( <button onClick={() => console.log(state)} className="bg-gray-700 text-white font-bold py-2 px-4 w-full rounded hover:bg-gray-600">Potvrdit</button> )
                  : ( <button onClick={() => handleClick("next")} className="btn-outline-primary transition duration-300 ease-in-out focus:outline-none focus:shadow-outline border border-orange-700 hover:bg-orange-700 text-orange-700 hover:text-white font-normal py-2 px-4 rounded"> Next </button>)
                  }
                  
              </div>
                  
              </div> 
          <span className="pull-right">
          </span>
          </>
        </div>
      </section>
    </div> 
    </div>
    </main>
  </>

  )
};
