
import React, { useState, useEffect, useRef } from "react"; 
import { NavLink } from "react-router-dom"; 
import { Menu,Transition } from '@headlessui/react';
import { useForm } from "react-hook-form";
import {RiShoppingBagLine} from "react-icons/ri"
import { ChevronDownIcon, ViewGridIcon } from "@heroicons/react/solid";

export default function GoodsCategories({cat, settings}) {
    const { register, handleSubmit, setValue } = useForm();
    const onSubmit = data => {}; 
    const [catList, setCatList] = useState([]);  
    const [catBackup, setCatBackup] = useState([]);   

    let categories = settings?.shop?.goods_categories
     
    useEffect(() => {
        setCatList(categories);
        setCatBackup(categories);
    }, [categories]); 
    
    useEffect(() => {
        setValue('filter');
    }, [cat]); 

    function search(e) { 
        const val = e.target.value.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
        let cats = catBackup;
        cats = cats.filter((category) => {
            let catName = category.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase() 
            return catName.indexOf(
                val) !== -1
        }) 
        val 
        ? setCatList(cats)
        : setCatList(catBackup)
        //let result = cartList.filter(t=>t.id < 120); 
        
    }

    function resetForm() {
        setTimeout(() => { 
            setCatList(catBackup)
        }, 1000);
    }
  
      
      return ( 
        <> 
        <div className="relative z-50"> 
            <Menu>
                {({ open }) => (
                    <> 
                    <Menu.Button className={" rounded-md flex space-x-2 items-center text-sm focus:outline-none   text-gray-600  hover:bg-gray-200   hover:text-gray-700 border " 
                        + (open ? ' bg-gray-200  '  : ' ')} id="user-menu" >
                    <div className="relative flex space-x-2 items-center py-2 px-3 " >
                        
                             
                        <ViewGridIcon className="w-5 h-5" />
                        <span className="truncate">
                            Kategorie
                        </span>
                        <ChevronDownIcon className="w-4 h-4" />
                    </div>
                    </Menu.Button> 
                
                    <Transition className="absolute lg:left-0 top-18 w-72  lg:w-96  max-w-2xl "
                    show={open}
                    enter="transition ease-out duration-100 transform"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="transition ease-in duration-75 transform"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                    >
                    <Menu.Items
                    static
                    className=" mt-1 mb-2 bg-white  rounded-md shadow-lg outline-none"
                    >
                    
                   <div className="px-4 py-3 bg-gray-100 rounded-md">
                         <div className="mb-2 font-medium">
                           Kategorie
                        </div>
                        <form onSubmit={handleSubmit(onSubmit)}  onBlur={() => resetForm()} className="relative">
                    
                            <input {...register('filter')} onChange={search}  onClick={() => {}} 
                            type="search" name="filter" autoComplete="off"   placeholder="Hledat" 
                            className="block w-full bg-white border border-gray-300 rounded-md py-2 pl-10 pr-3 text-sm placeholder-gray-500 focus:outline-none focus:text-gray-900 focus:placeholder-gray-400  focus:ring-1 focus:ring-blue-500 focus:border-blue-500 sm:text-sm" placeholder="Hledat" type="search" />
                        <div className="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center"> 
                            
                                <svg className="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
                                </svg> 
                            
                            </div> 
                        </form>
                    </div> 

                    <div className="py-4 px-4   max-h-96  overflow-auto origin-top-right grid grid-cols-2 "> 
                            {catList && catList.map((category, itemIdx) => (
                                <Menu.Item key={itemIdx} 
                                //onClick={() => setCat(category)} 
                                >
                                    {({ active }) => (  
                                        <NavLink to={'/prodej/produkty/' + category.url} className={"truncate cursor-pointer inline-block p-2 rounded-md text-sm mr-2 hover:bg-gray-100 " + (category === cat && "bg-gray-200")}>
                                        {category.name}
                                        </NavLink>  
                                    )}
                                </Menu.Item>  
                            ))} 
                    </div> 
                    </Menu.Items>
                </Transition>
                </>
            )}
                    
            </Menu>
        </div>
        </>
      )

   }