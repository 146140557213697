import { format } from 'date-fns'
import { cs } from 'date-fns/locale'

export const OrderGoods = ({data,...props}) => {
  

    return (
        <>
        <div className="divide-y">
            {data && data.map((item,itemIdx) => (
                <div key={itemIdx} className={"flex w-full justify-between py-1 text-gray-800 " }>
                    <div>
                        {item?.name}
                    </div>
                    
                    <div className=" w-36 flex justify-between items-center">
                        <div className="w-10">
                            {item?.count}

                        </div>
                        <div className="w-16">
                        {item?.price_vat.toLocaleString()} {item?.currency}
                        </div>
                    </div>
                     
                </div>
            ))
            
            }
        </div>
        </>
    )
}