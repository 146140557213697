import React, { useState } from "react"; 
import GoodsCategories from "./GoodsCategories"
import GoodsTags from "./GoodsTags";
import { useHistory } from "react-router-dom";


export const ProductFilter = ({cat,catTags,params,setParams,openStatus,setOpenStatus}) => {

    let activeTags = [...params];
    const history = useHistory(); 

    const [settings] = useState(JSON.parse(localStorage.getItem('settings')));
    let categories = settings?.shop?.goods_categories

    function removeCat() {
        history.push('/prodej/produkty')
    }

    let activeFilters = [];
    if(cat && categories) {
        let activeCat = categories.filter((category) => {
            let catName = category.url.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase() 
            return catName.indexOf(cat) !== -1
        }) 
        if(activeCat && activeCat.length > 0) {
            activeFilters = [
                { id: 0, value: activeCat[0].url, label: activeCat[0].name, remove: removeCat },
            ]
        }
    }
     
    if(activeTags && activeTags.length > 0) { 

        function removeTag(id) {
            const index = activeTags.indexOf(id);
            if (index > -1) {
                activeTags.splice(index, 1);
            }  
            setParams(activeTags)
        }

        activeTags.map((aTag) => { 
           catTags.map((section) => { 
             section.values.filter((tag) => {
                if(tag.id === aTag) { 
                    activeFilters.push({
                        value: tag.id,
                        label: tag.value,
                        remove: removeTag
                  })
                }
            })
          }) 
        })  
    } 

  
    activeFilters = activeFilters.filter(value => Object.keys(value).length !== 0);
 
    return (
        <>
         
              <div className="flex space-x-4">
                <GoodsCategories cat={cat} settings={settings} />

                <GoodsTags catTags={catTags} params={params} setParams={setParams} openStatus={openStatus} setOpenStatus={setOpenStatus} />
            </div>      

                {activeFilters && activeFilters.length > 0 &&    
                <div className="bg-gray-100 -ml-8 -mr-8 mt-3">
                    <div className="  py-3 px-8 sm:flex sm:items-center ">
                           

                        <div className="mt-2 sm:mt-0 ">
                        <div className="-m-1 flex flex-wrap items-center">
                            {activeFilters.map((activeFilter,filterIdx) => (
                            <span
                                key={filterIdx}
                                className="m-1 inline-flex rounded-full border border-gray-200 items-center py-1.5 pl-3 pr-2 text-sm font-medium bg-white text-gray-900"
                            >
                                <span>{activeFilter?.label}</span>
                                <button
                                type="button"
                                onClick={() => activeFilter?.remove(activeFilter.value)}
                                className="flex-shrink-0 ml-1 h-4 w-4 p-1 rounded-full inline-flex text-gray-400 hover:bg-gray-200 hover:text-gray-500 focus:outline-none "
                                >
                                <span className="sr-only">Odebrat filtr {activeFilter?.label}</span>
                                <svg className="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                                    <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
                                </svg>
                                </button>
                            </span>
                            ))}
                        </div>
                        </div>
                    </div>
                </div>
                }
        </>
    )
}