import React, { useState, useCallback, useEffect, Fragment, useRef } from "react";
import { ModalLayout } from "../layout/modal";
import { useForm, useFormState } from "react-hook-form"; 
import { axiosProtected } from "../../client";
import { Loading } from "../layout/Loading"; 
import { OrderList } from "../order/OrderList";
import { Order } from "../order/OrderModal"; 
import { format } from 'date-fns'
import { cs } from 'date-fns/locale'
import { UserCardDefault } from "../users/UserCardDefault";

export const ClientDetail = ({client, setModalIsOpen, setClients, updateUser, setExtraServices,setCartGoodsCount,eventData,...props}) => { 

    const [loading, setLoading] = useState();  
    const [data, setData] = useState();   
 
    const { 
        register, 
        handleSubmit, 
        setValue,
        control 
    } = useForm(); 

    const { errors,isValid } = useFormState({control}); 
 
    const clientData = data?.lesson_user

    const onSubmit = async data => { 
        try { 
            await setLoading(true);
            let res = await (axiosProtected()).post('timetable/lesson_user_update/',{
                note: data.note,
                amount: data.amount,
                order_item_id: clientData.order_item_id,
                id: clientData.id
            });
            setClients(res.data.clients);
            await setLoading(false); 
        } catch (err) {  
            setLoading(false);  
        } 
    }; 
 
    function openModal(cartId) {
   
        props.setModalContent({
          component: Order,
          params: {
            client: client, 
            timetableUserId: clientData.id,  
            settings: props.settings,
            roomData: props.roomData,
            cartId: cartId,
            updateUser: updateUser,
            setCartGoodsCount: setCartGoodsCount,
            setExtraServices: setExtraServices
          }
        })  
     }

    useEffect(() => {  
        try { 
            setLoading(true);
            (axiosProtected()).post('timetable/clientDetail/',{
                id: client?.id,
                orderId: client?.orderId,
                lesson: eventData?.timetable_lessons_items_id,
                udataId: client?.udataId
            }).then((res) => {
                setData(res.data)
                setValue('amount',res?.data?.lesson_user?.count)
                setValue('note',res?.data?.lesson_user?.note)
            }); 
            setLoading(false); 
        } catch (err) {  
            setLoading(false);  
        }
      }
      // eslint-disable-next-line 
    , []);
 
    function confirmChanges() {

        handleSubmit(onSubmit)()
        if(isValid) {
              setModalIsOpen(false);
        }
        
     }

    return (
        <>
      
    <ModalLayout title={clientData?.order_items_name ? clientData?.order_items_name : clientData?.name}>
    <div className="pb-20" >
      
                
                <div className="grid grid-cols-3 mb-6">
                    <div className="col-span-2">
                     <UserCardDefault user={clientData} />
                     </div>
                     <div className="my-3 font-medium text-gray-600">
                        {clientData?.clenstvi &&
                        <p className="truncate font-normal flex space-x-2 items-center text-sm ">
                            <svg version="1.1" fill="currentColor" className="h-7 w-7" id="Layer_1" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 200 200"  >
                        <path d="M49.666,129v17h4.104h4.104v-17v-77H53.77h-4.104V129z"/>
                        <path d="M67.254,129v17h9.381h9.38v-17v-77h-9.38h-9.381V129z"/>
                        <path d="M94.224,129v17h4.69h4.69v-17v-77h-4.69h-4.69V129z"/>
                        <path d="M112.984,129v17h4.69h4.69v-17v-77h-4.69h-4.69V129z"/>
                        <path d="M130.573,129v17h9.38h9.381v-17v-77h-9.381h-9.38V129z"/> 
                        <polygon points="27,30 17,30 13.5,30 10,30 10,33.5 10,37 10,47 10,55.303 13.5,55.303 17,55.303 17,47 17,37 27,37 35.314,37 
                            35.314,33.5 35.314,30 "/>
                        <polygon points="186.651,46.994 186.651,36.994 186.651,33.494 186.651,29.994 183.151,29.994 179.651,29.994 169.651,29.994 
                            161.349,29.994 161.349,33.494 161.349,36.994 169.651,36.994 179.651,36.994 179.651,46.994 179.651,55.309 183.151,55.309 
                            186.651,55.309 "/>
                        <polygon points="169.657,170 179.657,170 183.157,170 186.657,170 186.657,166.5 186.657,163 186.657,153 186.657,144.697 
                            183.157,144.697 179.657,144.697 179.657,153 179.657,163 169.657,163 161.343,163 161.343,166.5 161.343,170 "/>
                        <polygon points="10.006,153.006 10.006,163.006 10.006,166.506 10.006,170.006 13.506,170.006 17.006,170.006 27.006,170.006 
                            35.309,170.006 35.309,166.506 35.309,163.006 27.006,163.006 17.006,163.006 17.006,153.006 17.006,144.691 13.506,144.691 
                            10.006,144.691 "/>
                         
                    </svg>
                    <span>{clientData?.clenstvi}</span></p>
                        
                        }
                     </div>  
                </div>
                

                        <form key={1} onSubmit={handleSubmit(onSubmit)} > 

                        <div className="grid md:flex items-start lg:space-x-3 ">
                            {clientData?.order_item_id && 
                            <div className="grid">
                                <div className="mb-2 ">
                                    <label className="block text-gray-700 text-sm font-medium mb-2">Odečteno</label>
                                        <div className="flex items-center space-x-2 ">
                                        {clientData?.amount !== '0' ? 
                                                    <>
                                            <div className=" relative rounded-md shadow-sm"> 
                                                <input name="amount" type="number" {...register("amount",{required: true})} defaultValue="0" min="0"  
                                                className={" text-gray-700 block   pr-16 w-40 focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2  " } 
                                                /> 
                                                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                                <span className="text-gray-500 sm:text-sm" id="price-currency">
                                                kreditů
                                                </span>
                                                </div>
                                            </div> 
                                        </> : <>
                                            <div className=" relative rounded-md shadow-sm"> 
                                                <input name="amount" type="number" {...register("amount")} min="0"
                                                className={" text-gray-700  block   pr-16 w-40 focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2  "} 
                                                /> 
                                                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                                <span className="text-gray-500 sm:text-sm" id="price-currency">
                                                vstup
                                                </span>
                                                </div>
                                            </div> 

                                            
                                        </>}
                                        </div>
                                    </div>
                                </div>
                                }
                                <div className="w-full">
                                    <label className="block text-gray-700 text-sm font-medium mb-2">Poznámka</label>
                                        
                                            <div className="mt-1 ">
                                                <input {...register("note")} name="note" className={" text-gray-700 focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2 px-4 w-full  "} />
                                                
                                            </div>   
                                </div>
                            </div> 
                        </form> 
                        {data?.orders && data?.orders.length > 0 && 
                        <div className="my-4">
                            
                            <OrderList orders={data?.orders} client={data?.lesson_user} openModal={openModal} />
                        </div>
                        }
                        <div className="flex justify-between mt-2"> 
                            <button  onClick={() => openModal()}  type="button" className=" relative inline-flex items-center px-6 py-1 border border-transparent 
                                shadow-sm  rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none " >
                                Nová objednávka
                            </button>
                        </div>

                      
                        <div className="px-6 py-5  bg-gray-100 bg-opacity-60  border-t border-gray-200  text-right  absolute bottom-0 right-0 w-full flex">
                        <div className="w-1/2 text-left text-xs text-gray-500 pl-3 grid">
                             <p>Vytvořeno {clientData?.inserted && format(new Date(clientData.inserted), 'dd. MM. yyyy HH:mm', {locale: cs})}  uživatelem {clientData?.inserted_by} </p>
                        </div>
                        <div className="w-1/2">

                            {loading ? (
                                        
                                        <button disabled className="bg-gray-400 text-gray-100 font-bold  px-10 py-2 border border-transparent  rounded cursor-default">
                                        <svg className="animate-spin -ml-1 mr-3 h-5 w-5 inline" fill="none" viewBox="0 0 24 24">
                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                        </svg>
                                        <span>Odesílání...</span></button>
                                    ) : (
                                        <>
                                        
                                        <button  onClick={() => confirmChanges()}  type="submit" className=" relative inline-flex items-center px-10 py-2 border border-transparent 
                                        shadow-sm  font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none " >
                                            Uložit změny
                                        </button>
                                        
                                        
                                        </>
                                    )}  
                        </div>
                                
                    </div>
    </div>
    </ModalLayout>
    <Loading loading={loading} />
   
        </>
    )
}
