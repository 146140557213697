import React, { useState, useEffect } from "react"; 
import { useHistory, useParams } from "react-router-dom"; 
import EvCalendar from './calendar'; 
import moment from 'moment';
import 'moment/locale/cs'; 
import { axiosProtected } from "../../client";
import { format } from 'date-fns'
import { Loading } from "../layout/Loading";

export default function Studio(props) { 
  
    const dialog = props.dialog; 
    let settings = props.settings
    let { location, date } = useParams();
 
    
     
    const history = useHistory();  
    const [selStudio, setStudio] = useState();  
    const [events, setEvents] = useState([]);
    const [selectedDate, setDate] = useState();
    const [loading, setLoading] = useState(false);
    
    //const [settings, setSettings] = useState(JSON.parse(localStorage.getItem('settings'))); 
    const [space, setSpace] = useState();
    const [calendarView, setCalendarView] = useState('day');

    // useEffect(() => {  
    //   !settings &&
    //   setSettings(props.settings); 
      
    // }
    // // eslint-disable-next-line 
    // , []);

    
    useEffect(() => {   
      date && setDate(new Date(date));
    }
    // eslint-disable-next-line 
    , [date]);
     
    useEffect(() => {  
      if(!location && settings) {
        history.push("/studio/" + settings?.loc);
      }  
      if(location && !date) {
        history.push("/studio/" + location + '/' + format(new Date(), 'yyyy-MM-dd'));
      }  
      settings?.locations &&
      setStudio(settings.locations[location]); 
      getEvents(date);
      setSpace(settings?.locations[location]?.space);
      
    }
    // eslint-disable-next-line 
    , [location, settings, date]);

    useEffect(() => {  
      settings?.locations &&
      setSpace(settings?.locations[location]?.space);

    }
    // eslint-disable-next-line 
    , [events]);

    // useEffect(() => {  
    //   getEvents(moment(selectedDate),moment(selectedDate).endOf('isoWeek'));

    // }
    // // eslint-disable-next-line 
    // , [selectedDate]);
    

    function changeDate(val,view) {
      let date = new Date(val)
      let start,end;
      start = date;
      
      if(calendarView === 'week') {
        start = moment(start).startOf('isoWeek')
        end = moment(start).endOf('isoWeek')
       
      } else {
        end = start;
      }

      history.push("/studio/" + location + '/' + moment(date).format('YYYY-MM-DD'));
    }


     
    async function getEvents(start,end,view) { 
      setLoading(true);
      let calendar;
      if(view) {
        calendar = view;
      } else {
        calendar = calendarView
      }
      
       if(calendar === 'day') {
         start = moment(start)
         end = moment(start)
       } else {
         start = moment(start).startOf('isoWeek')
         end = moment(start).endOf('isoWeek')

       }
      if(!end) {
        end = start;
      }

      if(settings && location && settings?.locations) {
        await (axiosProtected()).get('timetable/events/?start=' + format(new Date(start), 'yyyy-MM-dd') + '&end=' + format(new Date(end), 'yyyy-MM-dd') + '&location_id=' + settings.locations[location].location_id)
        .then(res => {
          if(res) {
            setEvents(res.data);
            setSpace(settings?.locations[location]?.space);
          } else {
            setEvents([]);
          }
        })
        .catch(err => {
          console.log(err);
        });
      }
      setLoading(false);
    }





    return(

        <>
      {settings && location && selectedDate && 
        <div className="  bg-gray-100 min-h-0 flex-1  overflow-hidden pt-16 lg:pt-0 " style={props.electron ? {height: "calc(100vh - 9rem - 30px)"} : {height: "calc(100vh - 4rem)"}}  >
      
      
            <EvCalendar 
              {...props} 
              events={events} 
              selectedDate={selectedDate} 
              changeDate={changeDate} 
              getEvents={getEvents}
              dialog={dialog}   
              selStudio={selStudio}
              calendarView={calendarView} 
              setCalendarView={setCalendarView} 
              settings={settings} 
              space={space}
              setEvents={setEvents}
            />

            
            <Loading loading={loading} />
 
         
         
        </div>    
      }         
        </>
    )
}