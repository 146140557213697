import { LoginIcon } from '@heroicons/react/solid'
import { format } from 'date-fns'
import { cs } from 'date-fns/locale'
import { addToCart }  from "../cart/cart";  

export const OrderList = ({orders,client,openModal}) => {
    const order_id = client.order_id

    function changeOrder() {

    }

    async function payOrder(id,price) {
        let cartId
        try {
            const res = await addToCart('',{ 
                id: id,
                price: price
            },'order',client) 
            if(res.cart) {
              cartId = res.cart.cartHash
              openModal(cartId)
            }
          // Do your JSON handling here
        } catch(err) {
              console.log(err);
              // This probably means your response is text, do you text handling here
        }
 
        
    }

    return (
        <>
        <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                
                <div className="min-w-full divide-y divide-gray-200 ">
                    {orders.map((order, orderIdx) => (
                    <div key={orderIdx} className={"grid grid-cols-3 lg:grid-cols-8 gap-2 border text-gray-600 "  + (order?.order_sum < 0 ? "bg-yellow-100 border-yellow-200 text-yellow-700" : order_id === order.order_id ? 'bg-blue-100 border-blue-200 text-blue-700' : orderIdx % 2 === 0 ? 'bg-white border-gray-50' : 'bg-gray-50 border-gray-50')}>
                        <div className="px-3 py-4 whitespace-nowrap text-sm font-medium  ">{order.number}</div>
                        <div className="px-3 py-4 whitespace-nowrap text-sm  col-span-2 lg:col-span-3"><p className="truncate">{order.service_name}</p></div>
                        <div className="px-3 py-4 whitespace-nowrap text-sm   space-x-1">
                            {order?.order_sum < 0 
                            ?  <span className="font-bold ">{order?.order_sum} Kč</span> 
                            : <>
                            <span className="font-bold ">{order?.remaining}</span> 
                            <span>
                                {order.type === 'count' && (order.remaining > 5 ? "vstupů" : (order.remaining > 1 && order.remaining < 5) ? 'vstupy' : order.remaining === 1 ? 'vstup' : 'vstupů')}
                                {order.type === 'credit' && (order.remaining > 5 ? "kreditů" : order.remaining > 1 ? 'kredity' : order.remaining === 1 ? 'kredit' : 'kreditů')}
                            
                            </span>
                            </>
                            }
                         </div>
                        <div className="px-3 py-4 whitespace-nowrap text-sm  flex items-center col-span-2 space-x-1">{order?.valid_to && <><LoginIcon className="w-5 h-5 transform rotate-180 "  /><span title="Platnost do">{format(new Date(order?.valid_to), 'dd. MM. yyyy', {locale: cs})}</span></>}</div>
                        <div className="px-6 flex justify-center items-center whitespace-nowrap text-right text-sm font-medium">
                            {order?.order_sum < 0 ? 
                                <>
                                  <button  onClick={() => payOrder(order?.order_id,Math.abs(order?.order_sum))}  type="button" className="items-center px-5 py-1 bg-yellow-600 text-white rounded-md focus:outline-none hover:bg-yellow-700 " >
                                    Zaplatit
                                  </button>
                                </>
                            : 
                            <>
                                {order.order_id !== order_id && 
                                    <button  onClick={() => changeOrder()}  type="submit" className="items-center px-5 py-1 bg-gray-200 rounded-md focus:outline-none hover:bg-gray-300 " >
                                    Vybrat
                                    </button>
                                }
                            </>
                            }
                        </div>
                    </div>
                    ))} 
                </div>
            </div>
            </div>
        </div>
        </div>

        </>
    )
}