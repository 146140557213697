import React, { Fragment } from "react";  
import { Transition} from "@headlessui/react"; 
import { XIcon } from '@heroicons/react/outline'
 

export const SlideModal = (props) => { 
    let Component = props.slideModalContent?.component
    let dynamicProps = props.slideModalContent?.params
        
    const slideModalClose = props.slideModalClose 
    const setSlideModalIsOpen = props.setSlideModalIsOpen 
    const slideModalIsOpen = props.slideModalIsOpen

    function close() {
        setSlideModalIsOpen(false);
        slideModalClose();
    }

     
    return (
        <>

        <Transition show={slideModalIsOpen} className="absolute  z-50 inset-0  overflow-y-auto">
            <div className="absolute inset-0 overflow-hidden  ">
                <div className=" inset-0 overflow-hidden">
                <Transition.Child
                    className="fixed inset-0 transition-opacity"
                    aria-hidden="true"
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="absolute inset-0 bg-black opacity-25" onClick={() => close()} />
                </Transition.Child>
                
                <span className={"hidden sm:inline-block sm:align-middle " + (props.electron ? "sm:h-screen-electron" : "sm:h-screen")} aria-hidden="true">&#8203;</span>
                 
                 
                <div className="absolute top-0 inset-y-0 right-0 ml-10 max-w-full flex sm:ml-16 ">
                        <Transition.Child
                        as={Fragment}
                        enter="transform transition ease-in-out duration-500 sm:duration-0"
                        enterFrom="translate-x-full "
                        enterTo="translate-x-0"
                        leave="transform transition ease-in-out duration-300 sm:duration-0"
                        leaveFrom="translate-x-0"
                        leaveTo="translate-x-full"
                        >
                        <div className="w-screen max-w-6xl">
                            <div className="h-full flex flex-col bg-white shadow-xl overflow-y-auto">
                       
                                
                            <div className="relative" >
                                    <button
                                    className="  rounded-md  text-gray-100 hover:bg-blue-900 focus:ring-2 focus:ring-indigo-500  absolute right-6 top-2 z-50 p-2"
                                    onClick={() => close()}
                                    >
                                    <span className="sr-only">Close panel</span>
                                    <XIcon className="h-6 w-6" aria-hidden="true" />
                                    </button>
                                    {Component &&
                                            <Component  
                                            {...dynamicProps}
                                            {...props}
                                            />  
                                        }

                            </div>
                            
                            </div>
                        </div>
                        </Transition.Child>
                   
                    
                    
                    </div>
                    </div> 
                    </div>
            </Transition> 
        </>
    );
}

export const ModalLayout = (props) => {
    return (
        <>
        <div className="  rounded-lg overflow-hidden " >
            {props?.title && 
            
                <div className="  p-5 lg:px-8 lg:py-5 bg-gray-100 border-b  rounded-t-lg overflow-hidden ">
                        <div className="overflow-hidden items-center justify-between flex-wrap sm:flex-nowrap"> 
                                <h3 className="text-lg leading-6 font-medium text-gray-600 overflow-hidden w-full pr-10 ">
                                    <p >{props.title}</p>
                                </h3> 
                        </div>
                    </div>
                    
            }
                    <div className=" p-5 lg:p-8 align-bottom rounded-b-lg  text-left transform transition-all  sm:align-middle sm:w-full  bg-white " >
                        {props.children}
                    </div>
                    
            </div>
            
        </>
    )
}

export const Modal = (props) => { 

    let Component = props.modalContent?.component
    let dynamicProps = props.modalContent?.params
        
    const modalClose = props.modalClose 
    const setModalIsOpen = props.setModalIsOpen 
    const modalIsOpen = props.modalIsOpen
    const outsideClose = props.outsideClose

    function close() {
        setModalIsOpen(false);
        modalClose();
    }
      
    return (
        <>
            <Transition show={modalIsOpen} className="fixed z-50 inset-0 pt-4 overflow-y-auto  h-full">
            <div className="flex flex-wrap h-full items-end  overflow-y-auto   pb-20 lg:pb-0 text-center sm:block sm:p-0 ">
             
                <Transition.Child
                    className="fixed inset-0 transition-opacity"
                    aria-hidden="true"
                    enter="ease-out duration-200"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="absolute inset-0 bg-black opacity-50" onClick={
                        outsideClose ? (
                            () => close()
                        ) : (
                            () => ''
                        )} />
                </Transition.Child>
                <Transition.Child>
               
                </Transition.Child> 
                <span className={"hidden sm:inline-block sm:align-middle " + (props.electron ? "sm:h-screen-electron" : " ")} aria-hidden="true">&#8203;</span>
                 
                            
                <Transition.Child
                    className="inline-block align-bottom  rounded-lg mx-2 text-left   transform transition-all lg:mb-10 sm:align-top sm:max-w-5xl w-full "
                    role="dialog" aria-modal="true" aria-labelledby="modal-headline"
                    enter="ease-out duration-200"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-100"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                    
                     <div className="absolute top-16 p-3 right-2 mx-auto  z-50" onClick={() => close()}>
                        <button className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white  text-gray-500">
                            <span className="sr-only">Close sidebar</span>
                        
                            <svg className="h-6 w-6 " xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>
                        
                    <div className=" align-bottom bg-white rounded-lg  text-left  shadow-xl transform transition-all mt-16 sm:align-middle sm:w-full  overflow-hidden ">
                            {Component &&
                                <Component  
                                {...dynamicProps}
                                {...props}
                                />  
                            }
                        
                    </div>
                </Transition.Child>
            </div>
        </Transition>
        </>
    );
}

