

 
import { NavLink } from "react-router-dom"; 

export default function UsersMenu() {

  return(
    <>
     
                
                  

    <div className="">
      <div className=" ">
        <div className="  ">
        <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            <NavLink exact to={'/users'} activeClassName="border-blue-500 text-blue-800" className="border-transparent text-gray-500 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm">
           
            
            <span className="truncate">
                Seznam
            </span>
            </NavLink>
            <NavLink to={'/users/create'} activeClassName="border-blue-500 text-blue-800 " className="border-transparent text-gray-500 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm">
            
            
            <span className="truncate">
                Nový klient
            </span>
            </NavLink>
       
                   
            </nav>
            </div>
        </div>
    </div>
        </>
  )
};