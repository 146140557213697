import { CheckCircleIcon, ExclamationCircleIcon } from "@heroicons/react/solid"
import { format } from 'date-fns'
import { cs } from 'date-fns/locale'
import * as FaIcons from 'react-icons/fa'

export const OrderHeader = ({item, data}) => {
    let code = data?.status?.code;

    const Icon = FaIcons[code];

    return (
        <>
        <div className="flex items-center space-x-5"> 
            <div>
              <div className="inline-flex items-center space-x-4">
                <h1 className="text-2xl ">Objednávka <span className="font-bold text-gray-900">{item.number}</span></h1>
                {item?.paid ?
                  <div className="rounded-md flex items-center px-3 py-1 space-x-2 bg-green-300 bg-opacity-50 text-green-800">
                    <CheckCircleIcon className="w-5 h-5" /><span>Uhrazeno</span>
                  </div>
                
                : 
                <div className="rounded-md flex items-center px-3 py-1 space-x-2  bg-red-300 bg-opacity-50 text-red-600">
                    <ExclamationCircleIcon className="w-5 h-5" /><span>Neuhrazeno</span>
                  </div>
                }

                {data?.status &&
                  <div className="rounded-md flex items-center px-3 py-1 space-x-2 text-white " style={{backgroundColor: '#'+data?.status?.colour}}>
                    {Icon && <Icon className="w-4 h-4" />}<span>{data?.status?.name}</span>
                  </div>
                }
                
              </div>
              <p className="text-sm  text-gray-500">
                 
                Vytvořeno {format(new Date(item?.date), 'd. MM. yyyy HH:mm', {locale: cs})}
              </p>
            </div>
          </div>
          <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
            {/* <button
              type="button"
              className="inline-flex items-center justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
            >
              Disqualify
            </button>
            <button
              type="button"
              className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
            >
              Advance to offer
            </button> */}
          </div>
          </>
    )
}