
import React from "react";  

export default function Goods(props) {
  
    const goods = props.gds;
    const addItem = props.addItem;


    return(
        <div> 
        { goods.length > 0 && 
            <>
            <ul className="mt-5  ">
                {goods.map(item => ( 
                <li onClick={() => {addItem(item.id)}} key={item.id} className=" flex  border border-gray-200  items-center  bg-white  shadow   cursor-pointer hover:bg-gray-200">
                    <div className="flex-1 p-3 align-middle ">
                        <h3 className="  text-gray-900 text-md ">{item.name}</h3>                 
                    </div>
                    <div> 
                    </div>
                </li>
                ))}
            </ul>
            </>
        }
        {
            /*

        { goods.length > 0 && 
            <>
            <ul className="grid grid-cols-1  sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 mt-5 h-full ">
                {goods.map(item => ( 
                <li key={item.id} className="col-span-1 flex flex-col text-center bg-white  shadow divide-y divide-gray-200 mb-2">
                    <div className="flex-1 flex flex-col p-3 ">
                        <div className="h-32 flex flex-wrap content-center">
                        { item.img && 
                            <img className="max-h-32 w-auto mx-auto " src={item.img} alt="" />
                        }

                        </div>
                    <h3 className="mt-6 text-gray-900 text-sm font-medium">{item.name}</h3>
                     
                    </div>
                    <div>
                    <div className="-mt-px flex divide-x divide-gray-200">
                        <div className="w-0 flex-1 flex">
                            <span className="relative w-0 flex-1 inline-flex items-center justify-center py-2 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg ">

                            {item.price_vat} Kč
                            </span>

                        </div>
                        <div className="-ml-px w-0 flex-1 flex hover:bg-gray-300">
                        <button 
                        onClick={() => {addItem(item.id)}}
                        className="relative w-0 flex-1 inline-flex items-center justify-center py-2 text-gray-500 border border-transparent rounded-br-lg hover:text-black focus:outline-none">
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                            
                        </button>
                        </div>
                    </div>
                    </div>
                </li>
                ))}
            </ul>
            </>
        }
        
            */
        }
        </div>
    )
}