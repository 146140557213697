

 
import { NavLink } from "react-router-dom"; 

export const UserCardBig = (props) => { 
    const client = props.client;   
 

    return (

    <div key={client?.id}>
        <button type="button" className="block  w-full text-left border-l-4 border-transparent bg-gray-200 p-4 rounded-md">
        <div className="flex items-center ">
            <div className="min-w-0 flex-1 flex items-center">
            <div className="flex-shrink-0">
                <div className={"hidden lg:flex h-12 w-12 overflow-hidden  border-2    rounded-full  flex-wrap content-center justify-center " 
                + (client?.nick==='company' ? " bg-gray-200 border-gray-200 text-gray-400 " : " bg-blue-200 border-blue-300 text-blue-700")
                }>

                {client?.img ? (
                    <img src={client?.img} alt="" className="h-10 w-12 fill-current   rounded-full"  />
                    ) : ( 
                    client?.nick === 'company' ? (
                        <>
                        <div className="h-10 w-10 bg-gray-100 rounded-full ring-8 ring-white flex items-center justify-center">
                        
                        <svg className="w-8 rounded-full bg-gray-400 text-gray-100 p-1" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 484.738 484.738" stroke="currentColor"> 
                            <path d="M131.012,438.372h85.624v-87.557h51.467v87.557h85.624V46.366H131.012V438.372z M257.993,92.564h51.466v51.467h-51.466
                            V92.564z M257.993,175.279h51.466v51.467h-51.466V175.279z M257.993,257.992h51.466v51.467h-51.466V257.992z M175.279,92.564
                            h51.466v51.467h-51.466V92.564z M175.279,175.279h51.466v51.467h-51.466V175.279z M175.279,257.992h51.466v51.467h-51.466V257.992
                            z"/>
                            <path d="M373.719,81.482V358.66h33.065v79.712h77.954V81.482H373.719z M444.437,321.009H397.58v-46.856h46.856V321.009z
                            M444.437,245.703H397.58v-46.855h46.856V245.703z M444.437,170.398H397.58v-46.855h46.856V170.398z"/>
                            <path d="M0,438.372h77.954V358.66h33.065V81.482H0V438.372z M40.302,123.543h46.856v46.855H40.302V123.543z M40.302,198.848
                            h46.856v46.855H40.302V198.848z M40.302,274.151h46.856v46.855H40.302V274.151z"/>
                        </svg>  
                        </div>
                        
                        </>
                    ) : (
                        <span className="truncate block uppercase text-xl">{client?.nick}</span>
                    ) 
                    )
                }
                </div>
            </div>
            <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                <div className="flex items-center">
                <p className="text-md font-medium text-gray-700 truncate w-full">{client?.name}
                {client?.email &&
                    <span className="truncate font-normal block">{client?.email}</span>
                    
                    }
                    </p>
                </div>
                <div className="hidden md:block">
                <div>
                    <p className="text-sm text-gray-900">
                    
                    </p>
                    <p className="mt-2 flex items-center text-sm text-gray-500">
                    
                    </p>
                </div>
                </div>
            </div>
            </div>
            <div>
            
            <svg className="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
            </svg>
            </div>
        </div>
        </button>
    </div>
    )
}


export const UserCard = (props) => { 
    const user = props.user;  
    const active = props.active; 

    
    const submit = props.submit;

    return (

    <li key={user?.id}>
        <button onClick={() => submit(user.id)}  className={"block hover:bg-gray-100 w-full text-left border-l-4 border-transparent " + (active === user?.id ? (" bg-gray-100 border-blue-600") : (""))}>
        <div className="flex items-center px-4 py-4 sm:px-6">
            <div className="min-w-0 flex-1 flex items-center">
            <div className="flex-shrink-0">
                <div className={"hidden lg:flex h-12 w-12 overflow-hidden  border-2    rounded-full  flex-wrap content-center justify-center " 
                + (user?.nick==='company' ? " bg-gray-200 border-gray-200 text-gray-400 " : " bg-blue-200 border-blue-300 text-blue-700")
                }>

                {user?.img ? (
                    <img src={user?.img} alt="" className="h-10 w-12 fill-current   rounded-full"  />
                    ) : ( 
                    user?.nick === 'company' ? (
                        <>
                        <div className="h-10 w-10 bg-gray-100 rounded-full ring-8 ring-white flex items-center justify-center">
                        
                        <svg className="w-8 rounded-full bg-gray-400 text-gray-100 p-1" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 484.738 484.738" stroke="currentColor"> 
                            <path d="M131.012,438.372h85.624v-87.557h51.467v87.557h85.624V46.366H131.012V438.372z M257.993,92.564h51.466v51.467h-51.466
                            V92.564z M257.993,175.279h51.466v51.467h-51.466V175.279z M257.993,257.992h51.466v51.467h-51.466V257.992z M175.279,92.564
                            h51.466v51.467h-51.466V92.564z M175.279,175.279h51.466v51.467h-51.466V175.279z M175.279,257.992h51.466v51.467h-51.466V257.992
                            z"/>
                            <path d="M373.719,81.482V358.66h33.065v79.712h77.954V81.482H373.719z M444.437,321.009H397.58v-46.856h46.856V321.009z
                            M444.437,245.703H397.58v-46.855h46.856V245.703z M444.437,170.398H397.58v-46.855h46.856V170.398z"/>
                            <path d="M0,438.372h77.954V358.66h33.065V81.482H0V438.372z M40.302,123.543h46.856v46.855H40.302V123.543z M40.302,198.848
                            h46.856v46.855H40.302V198.848z M40.302,274.151h46.856v46.855H40.302V274.151z"/>
                        </svg>  
                        </div>
                        
                        </>
                    ) : (
                        <span className="truncate block uppercase text-xl">{user?.nick}</span>
                    ) 
                    )
                }
                </div>
            </div>
            <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                <div className="flex items-center">
                <p className="text-sm font-medium text-gray-700 truncate w-full">{user?.name}
                {user?.email &&
                    <span className="truncate font-normal block">{user?.email}</span>
                    
                    }
                    </p>
                </div>
                <div className="hidden md:block">
                <div>
                    <p className="text-sm text-gray-900">
                    
                    </p>
                    <p className="mt-2 flex items-center text-sm text-gray-500">
                    
                    </p>
                </div>
                </div>
            </div>
            </div>
            <div>
            
            <svg className="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
            </svg>
            </div>
        </div>
        </button>
    </li>
    )
}


export default function Users() {

  return(
    <>
     
                
                  

    <div className="mt-6 sm:mt-2 2xl:mt-5">
      <div className="border-b border-gray-200">
        <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
        <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            <NavLink exact to={'/order'} activeClassName="border-blue-500 text-blue-800" className="border-transparent text-gray-500 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm">
            {/* <!-- Heroicon name: key --> */}
            <svg className="text-gray-400 group-hover:text-gray-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z" />
            </svg>
            <span className="truncate">
                Dnešní přehled
            </span>
            </NavLink>
            <NavLink to={'/order/create'} activeClassName="border-blue-500 text-blue-800" className="border-transparent text-gray-500 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm">
            {/* <!-- Heroicon name: bell --> */}
            <svg className="text-gray-400 group-hover:text-gray-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
            </svg>
            <span className="truncate">
                Nová objednávka
            </span>
            </NavLink>
            
            <NavLink to={'/'}  activeClassName="border-blue-500 text-blue-800" className="border-transparent text-gray-500 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm">
            <svg className="text-orange-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z" />
            </svg>
            <span className="truncate">
                Plan &amp; Billing
            </span>
            </NavLink>
                   
            </nav>
            </div>
        </div>
    </div>
        </>
  )
};