import { ChevronUpIcon } from '@heroicons/react/solid';
import React, {useState, useEffect} from 'react';
import {FaArrowCircleUp} from 'react-icons/fa'; 
  
const ScrollButton = () =>{
    const [isVisible, setIsVisible] = useState(false);
    let container
 
  // Top: 0 takes us all the way back to the top of the page
  // Behavior: smooth keeps it smooth!


  useEffect(() => {
   
    container = document.getElementById('container');
    // Button is displayed after scrolling for 500 pixels
    const toggleVisibility = () => { 
      if (container.scrollTop > 100) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    container.addEventListener("scroll", toggleVisibility);

    return () => container.removeEventListener("scroll", toggleVisibility);
  }, []);

  const scrollToTop = () => {
    container.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };
  
  return (
    <div className="fixed bg-pink bottom-3 right-3  z-150 cursor-pointer">
      
        <div
          onClick={scrollToTop}
          className={"fixed p-2 rounded-full bg-blue-600 hover:bg-blue-700 bottom-3 right-3 lg:bottom-5 lg:right-7 cursor-pointer transition duration-500 ease-in-out  text-white " + (isVisible ? "opacity-100" : "opacity-0")}
        >
          <ChevronUpIcon className="w-8 h-8" />
        </div>
       
    </div>
  );
}
  
export default ScrollButton;