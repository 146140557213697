import { Menu,Transition } from '@headlessui/react';
import { LocationMarkerIcon } from '@heroicons/react/outline';
import { NavLink } from "react-router-dom";

   export const StudioSelect = ({selStudio,settings,...props}) => { 
    
    return (
     <> 
     
     <div className="relative w-full">
          
           <Menu >
           {({ open }) => (
               <>
               
               <Menu.Button
             
               className=" bg-white rounded-full h-11 flex items-center  focus:outline-none lg:px-5 lg:rounded-md lg:hover:bg-gray-50 overflow-hidden" aria-label="Notifications">
                   
                   <span className="text-gray-700 text-sm hidden lg:block lg:text-xl">{selStudio && selStudio.location_name }</span>
                   <span className="text-gray-700 text-sm block lg:hidden lg:text-xl"><LocationMarkerIcon className="w-4" /></span>
                   <svg className="flex-shrink-0 ml-1 h-5 w-5 text-gray-400 " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                     <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                   </svg>
               </Menu.Button>

               <Transition className="absolute left-0 top-12 z-50"
               show={open}
               enter="transition ease-out duration-100 transform"
               enterFrom="opacity-0 scale-95"
               enterTo="opacity-100 scale-100"
               leave="transition ease-in duration-75 transform"
               leaveFrom="opacity-100 scale-100"
               leaveTo="opacity-0 scale-95"
               >
               <Menu.Items
               static
               className=" w-56 origin-top-right z-50 bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg outline-none"
               >
                
               <div className="py-1">
               {selStudio && settings?.locations &&
                 
                   Object.keys(settings.locations).map((location_name, i) => (
                     <Menu.Item key={i}>
                     
                         <NavLink
                         key={i}
                         to={ '/studio/' + settings.locations[location_name].location_alias }
                         className={`${
                             selStudio.location_alias === settings.locations[location_name].location_alias
                             ? "bg-gray-100 text-gray-900"
                             : "text-gray-700"
                         } flex justify-between w-full px-4 py-2 text-sm leading-5 text-left outline-none hover:bg-gray-200`}
                         >
                         <span className="truncate">
                           {selStudio.location_alias === settings.locations[location_name].location_alias && 
                           <svg className="inline h-4 mr-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                             <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                           </svg>
                           }
                         {settings.locations[location_name].location_name}
                         </span>
                         
                         </NavLink>
                     
                     </Menu.Item>
                       )) 
                   
                     }
               </div>
               </Menu.Items>
             </Transition>
             </>
             )}
           </Menu>
           
   
   </div>
   
   </>
    )
  }