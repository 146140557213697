
import React, { useState, useCallback, useEffect, Fragment, useRef } from "react";
import moment from 'moment'
import 'moment/locale/cs';
import { Menu, RadioGroup, Transition } from '@headlessui/react'
import { CalendarIcon, XIcon, ClockIcon, DotsVerticalIcon, CurrencyEuroIcon,PlusIcon, MinusIcon, UserGroupIcon, CogIcon } from "@heroicons/react/solid";
import { useForm, useFormState } from "react-hook-form"; 
import { ClientList }  from "./ClientList";
import { format } from 'date-fns'
import { cs } from 'date-fns/locale'
import { classNames } from "../../services/classnames";
import { SelectCoach } from "./SelectCoach"; 
import { LessonType } from "./newEvent/LessonType";
import { Rooms } from "./newEvent/Rooms";
import { axiosProtected } from "../../client";
import { Difficulty } from "./newEvent/Difficulty"; 
import {Error} from "../form/error";
import { ImBook } from "react-icons/im";
import { Loading } from "../layout/Loading";
import { Lessons } from "./newEvent/Lessons";
import { Shimmer } from "../layout/Shimmer";

 

export const NewEvent = ({start,end,getEvents,setEvents,user,selStudio,changeDate,response,title, ...props} ) => {
    
    if(!start)
    start = new Date()

    if(!end)
    end = new Date()

    const [clients, setClients] = useState([]);
    const [settings] = useState(JSON.parse(localStorage.getItem('settings')));
    const [loading, setLoading] = useState(true);  
    const [startDate, setStartDate] = useState(format(new Date(start), 'yyyy-MM-dd', {locale: cs}));
    // eslint-disable-next-line
    const [endDate, setEndDate] = useState();
    const [startValue, setStartValue] = useState(format(new Date(start), 'HH:mm', {locale: cs}));
    const [endValue, setEndValue] = useState(format(new Date(end), 'HH:mm', {locale: cs}));
    const [hash] = useState(Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15));  
    const [eventData, setEventData] = useState();
    const [lastEntry, setLastEntry] = useState();
    const [booking, setBooking] = useState(false)
    const [activeTab, setActiveTab] = useState()
    const [resourceId,setResourceId] = useState(props.resourceId)
    const [price, setPrice] = useState()
    const [isVirtual, setVirtual] = useState()
    const [isPrivate, setPrivate] = useState()
    const [external, setExternal] = useState()
    const [types,setTypes] = useState({});
    const [lessons,setLessons] = useState([]);
    const [selected, setSelected] = useState()
    const [selectedLesson, setSelectedLesson] = useState();
    const [roomData, setRoomData] = useState();
    const [name, setName] = useState(title);
    const [coach, setCoach] = useState();
    const [coach2, setCoach2] = useState();
    const [secondCoach, setSecondCoach] = useState();
    const [substCoach, setSusbstCoach] = useState();
    const [substOn, setSubstOn] = useState();
    const [update, setUpdate] = useState();
    const [generate, setGenerate] = useState();
    const [difficulty, setDifficulty] = useState(1);
    const [priceDetails, setPriceDetails] = useState();
    const [instructors] = useState(props.settings.instructors);
    const [showDiff,setShowDiff] = useState(false);
    const [priceUpdate,setPriceUpdate] = useState(true);
    const [priceChange,setPriceChange] = useState(false);
    const [allowSave,setAllowSave] = useState(true);
    const [credits,setCredits] = useState(false);
    const [data,setData] = useState();
    const [shop,setShop] = useState();
 
    const resources = settings.locations[selStudio.location_alias].resources_all;

    const direct = roomData?.timetable_studio_room_direct_booking;
    const unlimited = roomData?.timetable_studio_room_unlimited;
    const requiredClass = "border-red-600 ring-1 ring-red-600 focus:ring-blue-600 focus:border-blue-600 focus:shadow-lg"
 
    const now = new Date(); 
   // var myToday = new Date(today.getFullYear(), today.getMonth(), today.getDate(), today.getHours(),today.getMinutes());
 
   const { 
        register, 
        handleSubmit, 
        setValue,
        control 
    } = useForm(); 

    const { errors,isValid } = useFormState({control}); 

    const eventDataRef = useRef(null);

    function updateLesson(id) { 
        if(lessons[id]) {
            setName(lessons[id].timetable_lessons_nazev)
            setSelectedLesson(id)
            setValue('lesson', lessons[id].timetable_lessons_id)
        } else {
            setName(roomData?.timetable_studio_room_name)
            setSelectedLesson(id) 
            setValue('lesson', false)
        }
    }

    function updateCoach(id) {  
        if(instructors[id]) {
            setCoach(id) 
            setValue('instructor', instructors[id].mod_instruktori_id)
        } else { 
            setCoach(false) 
            setValue('instructor','')
        }
    }
 
    function updateCoach2(id) {  
        if(instructors[id]) {
            setCoach2(id) 
            setValue('instructor2', instructors[id].mod_instruktori_id)
        } else { 
            setCoach2(false) 
            setValue('instructor2','')
        }
    }

    function updateSubst(id) {  
        if(instructors[id]) {
            setSusbstCoach(id) 
            setValue('sub_id', instructors[id].mod_instruktori_id)
        } else { 
            setSusbstCoach(false) 
            setValue('sub_id','')
        }
    }

    function secondCoachChange() {
        if(secondCoach) {
            setCoach2(false)
            updateCoach2();
        }
        
        setSecondCoach(!secondCoach)
    }
   
 
    const onSubmit = async data => { 
        try { 
            await setLoading(true);
            let res = await (axiosProtected()).post('timetable/lessonAdd/', data);
            await getEvents(moment(startDate)); 
            await setEventData({...res.data.lesson});
            eventDataRef.current = res.data.lesson;
            await setLoading(false); 
        } catch (err) {  
            setLoading(false);  
        }
        
        
    }; 
    
    const debounce = (func, debounceTimeout) => {
        let timeout;
        return function executedFunction(...args) {
            const delayedFunction = () => {
                clearTimeout(timeout);
                func(...args);
            };
            clearTimeout(timeout);
            timeout = setTimeout(delayedFunction, debounceTimeout);
        };
    };

    async function getLesson(event) { 
        setLoading(true);
        if(!event) {
            event = props.event
        }

        await (axiosProtected()).post('timetable/checkLesson/',
        {
            id: event?.id,
            timetableId: event?.timetableId,
            start: startValue,
            end: endValue,
            date: startDate,
            resourceId: resourceId,
            roomData: roomData,
            hash: hash
        })
        .then(res => {
            res.data.block ? setAllowSave(false) : setAllowSave(true)
            setData(res.data);
            setClients(res.data.clients); 
            if(!eventData) { 
                setPrice(res.data.price)
                setEventData(res.data.lesson)
                setValue('amount',res.data.price.price)
                setValue('capacity',res.data.capacity)
                res?.data?.lesson?.timetable_lessons_items_date && setValue('from',res.data.lesson.timetable_lessons_items_date)
            }
        })
        .catch(err => {
            console.log(err);
        });
        setLoading(false);
    }

    
    useEffect(() => { 
        if(response)  {
          if(response?.timetable_id && response?.timetable_id === eventData?.timetable_lessons_items_id) {
            getLesson();
            //console.log('a')
           // setEan(response.ean)
            props.setResponse(false)
          }
        }
        // eslint-disable-next-line
    }, [response]);

    useEffect(() => {  
        getLesson()
        
      }
      // eslint-disable-next-line 
    , [startValue,startDate,endValue,resourceId]);

    useEffect(() => {  
        setStartDate(format(new Date(start), 'yyyy-MM-dd', {locale: cs})); 
        setStartValue(format(new Date(start), 'HH:mm', {locale: cs}));
        setEndValue(format(new Date(end), 'HH:mm', {locale: cs}));

      }
      // eslint-disable-next-line 
    , [start]);

    useEffect(() => { 
        let showTimer = setTimeout(() => setShowDiff(true), 50);
    
        return () => {
            clearTimeout(showTimer);
        };  
    },[]);
 
    useEffect(() => {  
        setValue('difficulty',difficulty)
      }
      // eslint-disable-next-line 
    , [difficulty]);
 
    useEffect(() => {  
        setValue('name', name)
      }
      // eslint-disable-next-line 
    , [name]);
 
    useEffect(() => {  
        resources.forEach((item,key) => {
            if(item.resourceId === resourceId) {
                if(resources[key]?.timetable_studio_room_alias==='virtual') {
                    setVirtual(true);
                }
                setRoomData(resources[key])
            }
        })  
        setValue('room_id', resourceId)
        //setRoomData();
      }
      // eslint-disable-next-line 
    , [resourceId]);
 
    useEffect(() => {  
       !selectedLesson && roomData && !title && setName(roomData.resourceTitle);
      }
      // eslint-disable-next-line 
    , [roomData]);

    useEffect(() => {  
        if(price && price?.price > 0) {
            setCredits(true)
            setValue('amount',price?.price)
        } else {
            setCredits(false)
            setValue('amount',price?.price)
        }
        if(price?.price === data?.price?.price) {
            setPriceChange(false)
        } else {
            setPriceChange(true)
        }
      }
      // eslint-disable-next-line 
    , [price]);

    useEffect(() => {   
        if(credits) {
            setValue('amount',data?.price?.price)
        } else {
            setValue('vstup',1)
        }
      }
      // eslint-disable-next-line 
    , [credits]);

    useEffect(() => {   
        if(substOn) {
            setValue('sub_id',data?.lesson?.timetable_lessons_items_sub_id)
        } else {
            setValue('sub_id',0)
        }
      }
      // eslint-disable-next-line 
    , [substOn]);
 
   
 
    useEffect(() => {   
        if(eventData) {
            setValue('item_id', eventData?.timetable_lessons_items_id)
            setValue('timetable_id', eventData?.timetable_lessons_items_timetable_item_id)
            setValue('lesson', eventData?.timetable_lessons_items_lesson)
            setValue('instructor', eventData?.timetable_lessons_items_instructor)
            setValue('instructor2', eventData?.timetable_lessons_items_instructor2)
            setValue('sub_id', eventData?.timetable_lessons_items_sub_id)
            setValue('note', eventData?.timetable_lessons_items_note)
            setValue('text', eventData?.timetable_lessons_items_text)
            setValue('desc', eventData?.timetable_lessons_items_desc)
            setValue('extra_charge', eventData?.timetable_lessons_items_extra_charge)
            setValue('fee', eventData?.timetable_lessons_items_fee)
            setValue('discount', eventData?.timetable_lessons_items_discount)
            setValue('category', eventData?.timetable_lessons_items_category)
            if(eventData?.timetable_lessons_items_extra_charge || eventData?.timetable_lessons_items_discount || eventData?.timetable_lessons_items_fee) {
                setPriceDetails(true)
            }
            if(eventData?.timetable_lessons_items_lesson_name) {
                setValue('name', eventData?.timetable_lessons_items_lesson_name)
                setName(eventData?.timetable_lessons_items_lesson_name)
            }
            if(eventData?.timetable_lessons_items_type === 'virtual') {
                setVirtual(true)
            }
            if(eventData?.timetable_lessons_items_service_id) {
                setValue('service_id', eventData?.timetable_lessons_items_service_id)
                setValue('service_name', data?.service?.name)
                setValue('service_price_vat', data?.service?.price_vat)
                setValue('service_vat', data?.service?.vat)
                setValue('data_id', data?.service?.invoice_data_id)
                setValue('item_count', data?.service?.item_count)
                setShop(true)
            }
            if(eventData?.timetable_lessons_items_sub_id !== '0' && eventData?.timetable_lessons_items_sub_id) {
                setSubstOn(true)
            }
            if(eventData?.timetable_lessons_items_timetable_item_id) {
                
                if(eventData?.timetable_items_to === '0000-00-00') {
                    setSelected('999') 
                } else {
                    setSelected('1')
                    setEndDate(eventData?.timetable_items_to)
                    setValue('to', eventData?.timetable_items_to)
                    countWeeks('',eventData?.timetable_items_to)
                } 

                let gen = parseInt(eventData?.timetable_items_generate)
                if(gen===1) {
                    setGenerate(true);
                } 
            
            }    
            lessons &&
            lessons.map((lesson,key) => (
                eventData?.timetable_lessons_items_lesson === lesson.timetable_lessons_id &&
                setSelectedLesson(key)
            ))
            instructors &&
            instructors.forEach((instructor,key) => {
                if(eventData?.timetable_lessons_items_instructor === instructor.mod_instruktori_id) {
                    setCoach(key)
                }
                if(eventData?.timetable_lessons_items_instructor2 === instructor.mod_instruktori_id) {
                    setCoach2(key)
                    setSecondCoach(true)
                }
                if(eventData?.timetable_lessons_items_sub_id === instructor.mod_instruktori_id) {
                    updateSubst(key)
                }
            })
            let diff = parseInt(eventData?.timetable_lessons_items_difficulty)
            if(diff===0) {
                diff = 1
            } 
            setDifficulty(diff) 
            let priv = parseInt(eventData?.timetable_lessons_items_private)
            if(priv===1) {
                setPrivate(true);
            } else if(priv===2) {
                setPrivate(true);
                setExternal(true);
            }
            
      
       
      } else {
        setSelected('once')
        settings?.invoice?.default_company?.services && setValue('data_id',parseInt(settings?.invoice?.default_company?.services))
      }


    }
      // eslint-disable-next-line 
    , [eventData]);
 
    useEffect(() => {  
        const date = new Date(startDate + ' ' + startValue)
        const diffTime = Math.round((date - now) / (1000 * 60));
        
        if(diffTime>60) {
            setBooking(true)
        }
        
      }
      // eslint-disable-next-line 
    , [startValue,startDate,endValue]); 

    useEffect(() => {    
        props.setEanActive(true)
        !activeTab && direct==='1' || unlimited==='1' || props.event?.id || eventData?.timetable_lessons_items_id || eventData?.timetable_items_id || props.event?.timetableId ? setActiveTab(3) : setActiveTab(1)
        settings?.timetable?.category &&
        setTypes(settings?.timetable?.category)
        settings?.lessons &&
        setLessons(settings?.lessons)
      }
      // eslint-disable-next-line 
    , []); 

    useEffect(() => {  
        setValue('tabs',activeTab)
      }
      // eslint-disable-next-line 
    , [activeTab]); 
 
    // eslint-disable-next-line 
    const setTime = useCallback(debounce((e) => {
        
        const value = e.target.value.replace(/[^\w\s]/gi, "");
        if(value.length >= 4) { 
            const date = new Date();
            date.setHours(value.substring(0,2));
            date.setMinutes(value.substring(2,4)); 
            setStartValue(format(date, 'HH:mm', {locale: cs}));
            e.target.value = format(date, 'HH:mm', {locale: cs});
        }

    }, 500), []);
 
    // eslint-disable-next-line
    const changeEndTime = useCallback(debounce((e) => {
        
        const value = e.target.value.replace(/[^\w\s]/gi, "");
        if(value.length >= 4) { 
            const date = new Date();
            date.setHours(value.substring(0,2));
            date.setMinutes(value.substring(2,4)); 
            setEndValue(format(date, 'HH:mm', {locale: cs}));
            e.target.value = format(date, 'HH:mm', {locale: cs});
        }
        
    }, 500), []);

    // eslint-disable-next-line
    const setDate = useCallback(debounce((e) => {
        const value = e.target.value;
        setStartDate(value);
        setValue("from",value);
        changeDate(value);
        
    }, 500), []);

    // eslint-disable-next-line
    function countWeeks(e,date) {
        let end
        if(e) {
            end = e.target.value;
        } else {
            end = date
        }

        var diff =(new Date(startDate).getTime() - new Date(end).getTime()) / 1000;
        diff /= (60 * 60 * 24 * 7);
        diff = Math.abs(Math.round(diff));
         
        setValue("repeat_count",diff)
        setValue("item_count",diff)
        
    };
 
    function updateEndDate(e) {
        let count = e.target.value;

        let end = new Date();
        end.setDate(new Date(startDate).getDate() + count * 7);
 
        setValue("to",format(new Date(end), 'yyyy-MM-dd', {locale: cs}))
    }

    const capacityLimit = [];
    for (var i = 1; i <= 10; i++) {
        capacityLimit.push(i);
    }

    function setEndTime(plus) {
        const value = startValue.replace(/[^\w\s]/gi, "");
        const date = new Date(startDate);
        var end = new Date(date.getFullYear(), date.getMonth(), date.getDate(),value.substring(0,2),value.substring(2,4));
        end.setTime(end.getTime() + (plus*60*1000)) 
        setValue("end",format(new Date(end), 'HH:mm', {locale: cs}))
    }
 
    async function submitUser(id) {  
        let data
        if(!eventData) {
            data = eventDataRef.current
        } else {
            data = eventData
        }
        let url = '';
        data && (
            url = '&lessons_items_id=' +  data.timetable_lessons_items_id
        ) 
        await (axiosProtected()).get('timetable/client_add/?udata_id=' + id +'&booking=' + booking + '&hash=' +  hash + url).then(res => {  
                getEvents(moment(startDate));
                setClients(res.data.clients);
                setEvents(res.data.events);
                setLastEntry(res.data.lastEntry);
            }).catch(err => {        

            }) 
      }
     

    async function selectUser(id) { 
        setLoading(true);
        try { 
            !eventData && await handleSubmit(onSubmit)() 
            await submitUser(id)
        } catch(err) {

        }
        setLoading(false);
    }
     
      async function deleteEvent() {    
        await (axiosProtected()).get('timetable/delete_timetable/?item_id=' +  eventData?.timetable_lessons_items_id).then(res => {  
          getEvents(moment(start)); 
          props.setSlideModalIsOpen(false);
        }).catch(err => {       
        }) 
    };
  
    function confirmChanges() {

       handleSubmit(onSubmit)()
       if(isValid) {
             props.setSlideModalIsOpen(false);
       }
       
    }

    return (
        <> 
    <div  className="pb-8" > 
            <div className="grid bg-blue-700 text-white  ">
               
               

                <div className="mb-3 px-6 py-2 pb-0 lg:pl-12 grid">
                    <div className="flex items-center truncate space-x-4 pr-12 ">
                        {name ? <>
                            <h2 className="text-2xl truncate font-medium   " id="slide-over-title">
                                {name}
                            </h2>
                        </> : 
                        <>
                            <h2 className="text-2xl " id="slide-over-title">
                                Nová lekce
                            </h2>
                        </>
                        }
                        <div>
                            <button onClick={() => activeTab === 1 ? setActiveTab(3) : setActiveTab(1)} 
                            className={`  flex  w-10 justify-center rounded-md  p-2 leading-5 text-left focus:outline-none border hover:bg-blue-900 hover:text-white ` 
                            + (activeTab === 1 ? "bg-blue-100 text-blue-800 border-transparent " : " text-blue-300 border-transparent  ")} >
                                {activeTab === 1 ? 
                                <>
                                    <CogIcon className="w-6 h-6 " />
 
                                </>
                                : 
                                <>
                                    <CogIcon className="w-6 h-6   " />
 
                                </>
                                
                                }
                            </button> 
                        </div>
                        
                    </div>
                 
                    <div className="flex w-full items-end justify-between ">
                         
                            <div className="py-2 text-sm flex  gap-2 divide-x sm:divide-x-0">
                                 
                                    <div className="  flex space-x-1 col-span-2">
                                    <CalendarIcon className="w-5 h-5 opacity-50 hidden sm:flex" /> 
                                    <span>{format(new Date(startDate), 'dd. MM. yyyy', {locale: cs})}</span>
                                    </div>
                                    
                                    <div className=" pl-1 flex space-x-1">
                                    <ClockIcon className="w-5 h-5 opacity-50 hidden sm:flex" /> 
                                    <span>{startValue} - {endValue}</span>
                                    </div>

                                    
                                    {price?.price > 0 &&
                                        <div className="pl-1  flex space-x-1">
                                            <CurrencyEuroIcon className="w-5 h-5  hidden sm:flex opacity-50" /> 
                                            <span>{price?.price}</span>
                                        </div>
                                    }

 
                             
                        </div>
                        

                        <div className="relative flex space-x-2 sm:space-x-4 items-center">
                            {eventData && 
                            <div className="flex space-x-4 items-center font-medium ">
                                <div className="flex items-center space-x-1" title="Přítomní">
                                    <UserGroupIcon className="w-4 " /><span>{clients?.present?.length}</span>
                                </div>
                                <div className="flex items-center space-x-1" title="Rezervace">
                                    <ImBook className="w-4  " /><span>{clients?.bookings?.length+clients?.orders?.length}</span>
                                </div>
                                <div className="flex items-center space-x-1" title="Volná místa">
                                    
                                        <svg className="w-4  " fill="currentColor"  viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">  
                                                    <path d="M400.084,252H364V121.9c16-4.956,24-18.26,24-33.9V32c0-19.848-12.068-32-31.916-32h-192C144.236,0,124,12.152,124,32v56
                                                        c0,15.64,12,28.94,24,33.9V252h-27.916c-6.616,0-16.084,1.388-16.084,8v24c0,6.616,4,12,12,12v208c0,2.208,5.876,8,8.084,8h24
                                                        c2.208,0-0.084-5.792-0.084-8V364h216v140c0,2.208,5.876,8,8.084,8h24c2.208,0-0.084-5.792-0.084-8V296c8,0,12-5.384,12-12v-24
                                                        C408,253.388,406.704,252,400.084,252z M172,128h168v124H172V128z M364,340H148v-40h216V340z"/>
                                            
                                            </svg>

                                        <span>{clients?.rest}</span>
                                </div>
                            </div>
                            }
                            <div className="  flex-shrink-0  z-30 text-left">
                                    
                                {eventData &&
                                
                                    <Menu >
                                    {({ open }) => (
                                        <>
                                        
                                        <Menu.Button
                                        
                                        className={" rounded-md p-2 flex text-sm hover:bg-gray-100 hover:text-gray-700  focus:outline-none " + (open && (" bg-gray-100 text-gray-700 "))} aria-label="Notifications">
                                            
                                            <DotsVerticalIcon className="w-5 "  />
                                        </Menu.Button>

                                        <Transition  
                                        show={open}
                                        enter="transition ease-out duration-100 transform"
                                        enterFrom="opacity-0 scale-95"
                                        enterTo="opacity-100 scale-100"
                                        leave="transition ease-in duration-75 transform"
                                        leaveFrom="opacity-100 scale-100"
                                        leaveTo="opacity-0 scale-95"
                                        >
                                        <Menu.Items
                                        static
                                        className=" origin-top-right absolute  right-0 mt-1 w-56 rounded-md shadow-lg bg-white text-gray-700 ring-1 ring-black ring-opacity-5 focus:outline-none"
                                        >
                                            <div className="divide-y space-y-2 text-sm "> 
                                                
                                                <div className="py-1"> 
                                                    <Menu.Item > 
                                                        <button onClick={() => activeTab === 1 ? setActiveTab(3) : setActiveTab(1)} 
                                                        className={`  text-gray-800 flex space-x-2  w-full px-4 py-2 leading-5 text-left outline-none hover:bg-gray-100`} >
                                                            {activeTab === 1 ? 
                                                            <>
                                                                <UserGroupIcon className="w-5 text-gray-400" />

                                                                <span className="truncate">
                                                                Klienti
                                                                </span> 
                                                            </>
                                                            : 
                                                            <>
                                                                <CogIcon className="w-5 text-gray-400" />

                                                                <span className="truncate">
                                                                Nastavení lekce
                                                                </span> 
                                                            </>
                                                            
                                                            }
                                                        </button> 
                                                    </Menu.Item>
                                                        
                                                </div>
                                                <div className="py-1"> 
                                                    <Menu.Item > 
                                                        <button onClick={() => deleteEvent()} 
                                                        className={`  text-gray-800 flex  w-full px-4 py-2 leading-5 text-left outline-none hover:bg-gray-100`} >
                                                            
                                                            <svg className="w-5 text-red-600 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                            </svg>

                                                            <span className="truncate">
                                                            Odstranit 
                                                            </span> 
                                                        </button> 
                                                    </Menu.Item>
                                                        
                                                </div>
                                        </div>
                                        </Menu.Items>
                                        </Transition>
                                        </>
                                        )}
                                    </Menu>
                                }     
                                    
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
 
            <div className="relative p-6 lg:px-12 " >
                
                <div   className={(activeTab === 1 ? "block" : "hidden") + " space-y-4 lg:space-y-0 grid lg:grid-cols-2 lg:space-x-4 "}>
                    
                   <div>
                    <form key={1} onSubmit={handleSubmit(onSubmit)} >
                        <input name="hash" type="hidden" {...register("hash")} defaultValue={hash} />
                        <input name="item_id" type="hidden" {...register("item_id")}  />
                        <input name="timetable_id" type="hidden" {...register("timetable_id")}  />
                        <input name="room_id" type="hidden" {...register("room_id")}  />
                        <input name="lesson" type="hidden" {...register("lesson")}  />
                        <div className="space-y-4">
                       

                        <div className="space-y-2 sm:space-y-0 sm:flex space-x-0 sm:space-x-2 mb-4">
                            <div className="w-2/3"> 
                             <Rooms resourceId={resourceId}   setResourceId={setResourceId}  selStudio={selStudio}  />
                            </div>
                            <div className="w-1/3">
                                <label htmlFor="capacity" className="block text-sm font-medium text-gray-700 mb-2">
                                Kapacita
                                </label>
                                <div className="mt-1 ">
                                    {settings?.timetable?.limit_capacity ? <>
                                        
                                        <div className="mt-1 ">
                                            <select id="price" {...register("capacity")} name="capacity"  className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md pr-8 ">
                                                {capacityLimit.map((item,i) => (
                                                    <option key={i} value={item}>{item}</option>
                                                ))}                                         
                                            </select>
                                        </div>
                                    
                                
                                </> : <>
                                
                                <input name="capacity" type="text" {...register("capacity",{required: true})}  className="text-gray-700  focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2 px-4 block w-full "   />
                                </>}
                                
                                    
                                </div>
                            </div>
                        </div>
                      <div className=" space-y-2 sm:space-y-0 sm:flex space-x-0 sm:space-x-2">
                        <div className="w-2/3" > 
                             <LessonType selectedLesson={selectedLesson} lessons={lessons} updateLesson={updateLesson}  />
                            </div>
                            <div className="w-1/3 ">
                                <label htmlFor="category" className="block text-sm font-medium text-gray-700 mb-2">
                                Kategorie
                                </label>
                                <div className="mt-1 ">
                                    <select id="category" {...register("category")} name="category"  className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md ">
                                        <option value="lesson">lekce</option>
                                    {Object.keys(types).map((item,i) => (
                                        <option key={i} value={item}>{types[item]}</option>
                                    ))}
                                    </select>
                                </div>
                            </div>
                           
                            
                        </div>
                        <div className="  " >
                                 
                                        <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-2">
                                            Označení lekce
                                        </label>
                                            <input name="name" type="text"   {...register("name",{required: true})} 
                                                className={"text-gray-700  focus:outline-none border border-gray-300  rounded py-2   w-full   " + (errors.name && "border-red-600 ")}   />
                                                {errors.login && 
                                                <div className="rounded-md bg-red-50 mt-1 p-2"> 
                                                    <svg className="h-5 w-5 text-red-400 inline mr-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                                                    </svg> 
                                                    <span className="text-sm text-red-700">
                                                        Vyplňte název
                                                    </span>
                                                </div>
                                                }
                                    
                        </div>
                        <div className="grid space-y-4 lg:space-y-0 lg:flex lg:space-x-6 ">
                            <div className="inline-flex items-center sm:col-span-4 cursor-pointer  w-auto" onClick={() => setPrivate(!isPrivate)}>
                                {isPrivate  && external 
                                    ? <input type="hidden" {...register("type")} name="type" defaultValue="2" /> 
                                    : <input type="hidden" {...register("type")} name="type" defaultValue="1" />  } 
                                
                                <button  
                                type="button" aria-pressed="false" aria-labelledby="toggleLabel" 
                                className={(isPrivate ? 'bg-blue-600' : 'bg-gray-200' ) + " relative inline-flex flex-shrink-0 h-5 w-10 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none "}
                                >
                                    <span className="sr-only">soukromá lekce</span>
                                    <span aria-hidden="true" 
                                    className={(isPrivate ? 'translate-x-5' : 'translate-x-0' ) + " pointer-events-none  inline-block h-4 w-4 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"}>
                                    </span>
                                </button>
                                <span className={(isPrivate ? 'text-gray-900 font-medium' : 'text-gray-400' ) + " text-sm flex space-x-2 ml-3"} id="toggleLabel">
                                    <span > uzavřená lekce</span>                                   
                                </span>
                            </div>
                            {isPrivate && 
                            <div className="inline-flex items-center sm:col-span-4 cursor-pointer w-auto" onClick={() => setExternal(!external)}>
                                <button  
                                type="button" aria-pressed="false" aria-labelledby="toggleLabel" 
                                className={(external ? 'bg-blue-600' : 'bg-gray-200' ) + " relative inline-flex flex-shrink-0 h-5 w-10 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none "}
                                >
                                    <span className="sr-only">soukromá lekce</span>
                                    <span aria-hidden="true" 
                                    className={(external ? 'translate-x-5' : 'translate-x-0' ) + " pointer-events-none  inline-block h-4 w-4 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"}>
                                    </span>
                                </button>
                                <span className={(external ? 'text-gray-900 font-medium' : 'text-gray-400' ) + " text-sm flex space-x-2 ml-3"} id="toggleLabel">
                                    <span > pronájem</span>                                   
                                </span>
                            </div>
                            }

                            {showDiff && <Difficulty difficulty={difficulty} setDifficulty={setDifficulty} /> }
                            <input type="hidden" {...register("difficulty")} name="difficulty"  />
                        </div>
                            <div className="inline-flex items-center sm:col-span-4 cursor-pointer  w-auto" onClick={() => setVirtual(!isVirtual)}>
                                {isVirtual ?
                                    (
                                        <input type="hidden" {...register("type")} name="type" defaultValue="virtual" />
                                    ) : (
                                        <input type="hidden" {...register("type")} name="type" defaultValue="" />
                                    )
                                } 
                                <button  
                                type="button" aria-pressed="false" aria-labelledby="toggleLabel" 
                                className={(isVirtual ? 'bg-blue-600' : 'bg-gray-200' ) + " relative inline-flex flex-shrink-0 h-5 w-10 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none "}
                                >
                                    <span className="sr-only">online lekce</span>
                                    <span aria-hidden="true" 
                                    className={(isVirtual ? 'translate-x-5' : 'translate-x-0' ) + " pointer-events-none  inline-block h-4 w-4 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"}>
                                    </span>
                                </button>
                                <span className={(isVirtual ? 'text-gray-900 font-medium' : 'text-gray-400' ) + " text-sm flex space-x-2 ml-3"} id="toggleLabel">
                                    <span > online lekce</span>                                   
                                </span>
                            </div>
                        {isVirtual &&
                            <div className="lg:flex lg:space-x-2 mt-2 ">
                                <div>
                                    <label className="block text-gray-700 text-sm font-medium mb-2">Odkaz pro spuštění online lekce</label>
                                    <input name="note" type="text" {...register("note",{required: true})} className={" text-gray-700 w-72 focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2 px-4  "+ (errors.note && requiredClass)}  />
                                    
                                </div>
                            </div>
                        }

                        <div className="lg:flex lg:space-x-2 mt-4">
                            <div>
                                <label className="block text-gray-700 text-sm font-medium mb-2">Datum</label>
                                <input name="from" type="date" {...register("from",{required: true})} onChange={(e) => setDate(e)} defaultValue={format(new Date(start), 'yyyy-MM-dd', {locale: cs})}  className=" text-gray-700 focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2 px-4  "   />
                                

                                <input name="room_id" type="hidden" {...register("room_id",{required: true})} defaultValue={resourceId} className=" text-gray-700 focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2 px-4 "   />
 
                            </div>
                            <div className="mt-2 lg:mt-0">
                                <div className="flex  mb-2 text-sm">
                                    <label className="block text-gray-700 text-sm font-medium">Čas  - délka
                                        </label>

                                    <button className="px-1 mx-1 text-blue-500 underline hover:no-underline focus:outline-none" type="button" onClick={() => setEndTime(30)}>30</button>
                                    <button className="px-1 mx-1  text-blue-500 underline hover:no-underline  focus:outline-none" type="button" onClick={() => setEndTime(60)}>60</button>
                                    <button className="px-1 mx-1  text-blue-500 underline hover:no-underline  focus:outline-none" type="button" onClick={() => setEndTime(90)}>90 minut</button>
                                </div>
                                <div className="flex space-x-2">
                                    <input name="start" type="time" {...register("start",{required: true})} defaultValue={startValue} 
                                    
                                    onChange={(e) => setTime(e)} className=" text-gray-700 focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2 px-4 "   />
                                   
                               
                                

                                 
                                    <input name="end"  {...register("end",{required: true})}  onChange={(e) => changeEndTime(e)} type="time"defaultValue={endValue} className=" text-gray-700 focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2 px-4 "   />

                                </div>
                            </div>
                        </div>
                        {!allowSave && 
                                <>
                                    <Error text={'Termín obsazen'} />
                                </>
                            }

                    

                        {eventData?.timetable_lessons_items_timetable_item_id && 
                            <>
                                <div className="flex items-center sm:col-span-4 cursor-pointer my-4" onClick={() => setUpdate(!update)}>
                                {update ?
                                    (
                                        <input type="hidden" {...register("update")} name="update" defaultValue="1" />
                                    ) : (
                                       ""
                                    )
                                } 
                                <button  
                                type="button" aria-pressed="false" aria-labelledby="toggleLabel" 
                                className={(update ? 'bg-blue-600' : 'bg-gray-200' ) + " relative inline-flex flex-shrink-0 h-5 w-10 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none "}
                                >
                                    <span className="sr-only">aktualizovat celou řadu</span>
                                    <span aria-hidden="true" 
                                    className={(update ? 'translate-x-5' : 'translate-x-0' ) + " pointer-events-none  inline-block h-4 w-4 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"}>
                                    </span>
                                </button>
                                <span className="ml-3" id="toggleLabel">
                                    <span className={(update ? 'text-gray-900 font-medium' : 'text-gray-400' ) + " text-sm  "}>aktualizovat celou řadu {eventData?.timetable_items_name}, {format(new Date(startDate), 'EEEE', {locale: cs})} {format(new Date(eventData?.timetable_lessons_items_date + 'T' + eventData?.timetable_lessons_items_start), 'HH:mm', {locale: cs})} - {format(new Date(eventData?.timetable_lessons_items_date + 'T' + eventData?.timetable_lessons_items_end), 'HH:mm', {locale: cs})}  
                                    </span>
                                </span>
                                </div>

                            
                            </>
                        }


                        
                        {((eventData?.timetable_lessons_items_timetable_item_id && update) || !eventData) && 
                        <div className="mt-4  lg:grid lg:space-x-2">
                            <div className=" col-span-4">
                                
                            <RadioGroup value={selected} onChange={setSelected}>
                            
                            <div className="mt-1 bg-white rounded-md shadow-sm -space-y-px">
                                 
                                <RadioGroup.Option  
                                    value="once"
                                    disabled={eventData?.timetable_lessons_items_timetable_item_id && true}
                                    className={({ checked }) => 
                                    classNames(
                                        'rounded-tl-md rounded-tr-md',
                                        checked ? 'bg-blue-50 border-blue-200 ' : 'border-gray-200',
                                        eventData?.timetable_lessons_items_timetable_item_id && 'bg-gray-50 border-gray-200 opacity-50',
                                        ' border p-4  flex cursor-pointer focus:outline-none items-center '
                                    )
                                    }
                                >
                                    {({ active, checked }) => (
                                    <>
                                        <span
                                        className={classNames(
                                            checked ? 'bg-blue-600 border-transparent' : 'bg-white border-gray-300',
                                            active ? 'ring-2 ring-offset-2 ring-blue-500' : '',
                                            'h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center'
                                        )}
                                        aria-hidden="true"
                                        >
                                        <span className="rounded-full bg-white w-1.5 h-1.5" />
                                        </span>
                                        <div className="ml-3 grid grid-cols-2   items-center">
                                        <RadioGroup.Label
                                            as="span"
                                            className={classNames(
                                            checked ? 'text-blue-900' : 'text-gray-900',
                                            'text-sm font-medium'
                                            )}
                                        >
                                           jednorázová lekce
                                        </RadioGroup.Label>
                                        <RadioGroup.Description
                                            as="span"
                                            className={classNames(checked ? 'text-blue-700 block' : 'text-gray-500 hidden', ' text-sm ')}
                                        > 
                                             

                                                   
                                             
                                        </RadioGroup.Description>
                                        {active && <input name="repeat" type="hidden" {...register("repeat")} checked={true} defaultValue="once" />}
                                        </div>
                                    </>
                                    )}
                                </RadioGroup.Option>
                                <RadioGroup.Option 
                                    value="999"
                                    className={({ checked }) =>
                                    classNames(
                                        ' ',
                                        checked ? 'bg-blue-50 border-blue-200 ' : 'border-gray-200',
                                        ' border p-4  flex cursor-pointer focus:outline-none  '
                                    )
                                    }
                                >
                                    {({ active, checked }) => (
                                    <>
                                        <span
                                        className={classNames(
                                            checked ? 'bg-blue-600 border-transparent' : 'bg-white border-gray-300',
                                            active ? 'ring-2 ring-offset-2 ring-blue-500' : '',
                                            'h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center'
                                        )}
                                        aria-hidden="true"
                                        >
                                        <span className="rounded-full bg-white w-1.5 h-1.5" />
                                        </span>
                                        <div className="ml-3 grid grid-cols-2  ">
                                        <RadioGroup.Label
                                            as="span"
                                            className={classNames(
                                            checked ? 'text-blue-900' : 'text-gray-900',
                                            'block text-sm font-medium'
                                            )}
                                        >
                                           opakovat každý týden
                                        </RadioGroup.Label>
                                        <RadioGroup.Description 
                                            as="span"
                                            className={classNames(checked ? 'text-blue-700 block' : 'hidden text-gray-500', 'text-sm')}
                                        >
                                           
                                                
                                            
                                        </RadioGroup.Description>
                                        {active && <input name="repeat" type="hidden" {...register("repeat")} checked={true}  defaultValue="999" />}
                                        </div>
                                    </>
                                    )}
                                </RadioGroup.Option>
                                <RadioGroup.Option 
                                    value="1"
                                    className={({ checked }) =>
                                    classNames(
                                        'rounded-bl-md rounded-br-md grid',
                                        checked ? 'bg-blue-50 border-blue-200 ' : 'border-gray-200',
                                        ' border p-4  flex cursor-pointer focus:outline-none  '
                                    )
                                    }
                                >
                                    {({ active, checked }) => (
                                    <>
                                    <div className="flex">
                                        <span
                                        className={classNames(
                                            checked ? 'bg-blue-600 border-transparent' : 'bg-white border-gray-300',
                                            active ? 'ring-2 ring-offset-2 ring-blue-500' : '',
                                            'h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center'
                                        )}
                                        aria-hidden="true"
                                        >
                                        <span className="rounded-full bg-white w-1.5 h-1.5" />
                                        </span>
                                        <div className="ml-3 flex">
                                        <RadioGroup.Label
                                            as="span"
                                            className={classNames(
                                            checked ? 'text-blue-900' : 'text-gray-900',
                                            'block text-sm font-medium '
                                            )}
                                        >
                                          limitovaný počet opakování
                                        </RadioGroup.Label>
                                        <RadioGroup.Description 
                                            as="span"
                                            className={classNames(checked ? 'text-blue-700 block' : 'hidden text-gray-500', 'text-sm')}
                                        >
                                           
                                                
                                            
                                        </RadioGroup.Description>
                                        </div>
                                    </div>
                                        {checked && 
                                            <div className="w-full ">
                                                <input name="repeat" type="hidden" {...register("repeat")} checked={true}  defaultValue="1" />

                                                <div className="grid grid-cols-2 lg:flex lg:space-x-2 mt-4 w-full">
                                                    <div className="w-40">
                                                        <label className="block text-gray-700 text-sm font-medium mb-2">Začátek</label>
                                                        <input name="from" type="date" {...register("from",{required: true})}  onChange={(e) => setDate(e)} defaultValue={format(new Date(start), 'yyyy-MM-dd', {locale: cs})}  className=" text-gray-700 focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2 px-4 pr-2 w-full  "   />
                        
                                                    </div>
                                                    <div className="w-40">
                                                        <label className="block text-gray-700 text-sm font-medium mb-2">Konec</label>
                                                        <input name="to" type="date" {...register("to",{required: true})}  onChange={(e) => countWeeks(e)} defaultValue={format(new Date(end), 'yyyy-MM-dd', {locale: cs})}  className=" text-gray-700 focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2 px-4 pr-2 w-full  "   />
                        
                                                    </div>
                                                    <div className="w-32 mt-2 lg:mt-0">
                                                        <label className="block text-gray-700 text-sm font-medium mb-2">Počet opakování</label>
                                                        <div className="relative">
                                                            <input name="repeat_count" type="number" min="1" {...register("repeat_count")}  onChange={(e) => updateEndDate(e)}  
                                                            className=" text-gray-700 focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2 px-4 w-full "   />
                                                        </div>
                                                    </div>
                                                </div>

                                                
                                                <div className="inline-flex items-center sm:col-span-4 cursor-pointer w-auto mt-4" onClick={() => setGenerate(!generate)}>
                                                    <button  
                                                    type="button" aria-pressed="false" aria-labelledby="toggleLabel" 
                                                    className={(generate ? 'bg-blue-600' : 'bg-gray-200' ) + " relative inline-flex flex-shrink-0 h-5 w-10 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none "}
                                                    >
                                                        <span className="sr-only">vygenerovat všechny lekce</span>
                                                        <span aria-hidden="true" 
                                                        className={(generate ? 'translate-x-5' : 'translate-x-0' ) + " pointer-events-none  inline-block h-4 w-4 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"}>
                                                        </span>
                                                    </button>
                                                    <span className={(generate ? 'text-gray-900 font-medium' : 'text-gray-400' ) + " text-sm flex space-x-2 ml-3"} id="toggleLabel">
                                                        <span > vygenerovat všechny lekce</span>                                   
                                                    </span>
                                                </div>
                                                
                                            </div>    
                                        }
                                       
                                    </>
                                    )}
                                </RadioGroup.Option>
                                
                            </div>
                            </RadioGroup>

                            </div>  
                        </div> 
                        }
                        <div className="grid md:flex items-start lg:space-x-3 ">
                            <div className="mt-4 grid">
                                <div className="mb-2 ">
                                    <label className="block text-gray-700 text-sm font-medium ">Odečíst</label>
                                    {settings?.timetable?.prices ? <>
                                        
                                            <div className=" mt-2 w-36">
                                                <select id="price" {...register("amount")} name="amount" onChange={() => eventData && setPriceChange(true)}  className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md pr-8 ">
                                                    {settings?.timetable?.prices.map((item,i) => (
                                                        <option key={i} value={item}>{item}</option>
                                                    ))}
                                                    {!settings?.timetable?.prices.includes(price?.price) &&
                                                        <option value={price?.price}>{price?.price}</option>
                                                    }
                                                    
                                                </select>
                                            </div>
                                    
                                    
                                    </> : <>
                                        
                                        
                                        <div className="flex items-center space-x-2 mt-2">
                                        {credits ? 
                                        <>
                                            <div className=" relative rounded-md shadow-sm"> 
                                                <input name="amount" type="number" {...register("amount",{required: true})} defaultValue="0"  onChange={(e) => setPrice({price: e.target.value})} 
                                                className={" text-gray-700 block   pr-16 w-40 focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2  "+ (errors.amount && requiredClass)} 
                                                /> 
                                                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                                <span className="text-gray-500 sm:text-sm" id="price-currency">
                                                kreditů
                                                </span>
                                                </div>
                                            </div> 
                                        </> : <>
                                            <div className=" relative rounded-md shadow-sm"> 
                                                <input name="vstup" type="number" {...register("vstup")} defaultValue="1" disabled 
                                                className={" text-gray-700  block bg-gray-200  pr-16 w-40 focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2  "} 
                                                /> 
                                                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                                <span className="text-gray-500 sm:text-sm" id="price-currency">
                                                vstup
                                                </span>
                                                </div>
                                            </div> 

                                            
                                        </>}
                                        </div>
                                        <div className="inline-flex items-center sm:col-span-4 cursor-pointer  w-auto mt-2" onClick={() => setCredits(!credits)}>
                                        
                                            <button  
                                            type="button" aria-pressed="false" aria-labelledby="toggleLabel" 
                                            className={(credits ? 'bg-blue-600' : 'bg-gray-200' ) + " relative inline-flex flex-shrink-0 h-5 w-10 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none "}
                                            >
                                                <span className="sr-only">kredity</span>
                                                <span aria-hidden="true" 
                                                className={(credits ? 'translate-x-5' : 'translate-x-0' ) + " pointer-events-none  inline-block h-4 w-4 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"}>
                                                </span>
                                            </button>
                                            <span className={(credits ? 'text-gray-900 font-medium' : 'text-gray-400' ) + " text-sm flex space-x-2 ml-3"} id="toggleLabel">
                                                <span >kredity</span>                                   
                                            </span>
                                        </div>
                                    </>}

                                      
                            {priceChange && 
                                <div className="inline-flex items-center sm:col-span-4 cursor-pointer  w-auto mt-2" onClick={() => setPriceUpdate(!priceUpdate)}>
                                        {priceUpdate &&
                                            (
                                                <input type="hidden" {...register("update_price")} name="update_price" defaultValue="1" />
                                            ) 
                                        } 
                                        <button  
                                        type="button" aria-pressed="false" aria-labelledby="toggleLabel" 
                                        className={(priceUpdate ? 'bg-blue-600' : 'bg-gray-200' ) + " relative inline-flex flex-shrink-0 h-5 w-10 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none "}
                                        >
                                            <span className="sr-only">aktualizovat cenu přihlášeným</span>
                                            <span aria-hidden="true" 
                                            className={(priceUpdate ? 'translate-x-5' : 'translate-x-0' ) + " pointer-events-none  inline-block h-4 w-4 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"}>
                                            </span>
                                        </button>
                                        <span className={(priceUpdate ? 'text-gray-900 font-medium' : 'text-gray-400' ) + " text-sm flex space-x-2 ml-3"} id="toggleLabel">
                                            <span > aktualizovat cenu přihlášeným</span>                                   
                                        </span>
                                    </div>
                            
                            }

                                 

                                </div>
                               
                            
                            
                            </div>
                            <div className="grid"> 
                            <div className="mt-4 lg:flex lg:space-x-2 ">
                                <div>
                                    <label className="block text-gray-700 text-sm font-medium mb-2">Lektor</label>
                                    <div ><SelectCoach data={instructors} selected={coach} substOn={substOn} updateItem={updateCoach} /></div>
                                    <div className="flex space-x-6">
                                        
                                        <button type="button" onClick={() => secondCoachChange()} className="flex  text-blue-600 text-xs font-medium ml-2 items-center space-x-1 focus:outline-none ">
                                            {secondCoach ? (<><MinusIcon className="w-4 inline   " /><span className="truncate">Odebrat druhého</span></>) : (<><PlusIcon className="w-4 inline  " /><span className="truncate">Druhý lektor</span></>)}
                                            
                                        </button>
                                        <button type="button" onClick={() => setSubstOn(!substOn)} className="flex  text-blue-600 text-xs font-medium ml-2 items-center space-x-1 focus:outline-none ">
                                            {substOn ? (<><MinusIcon className="w-4 inline  " /><span className="truncate">Zrušit záskok</span></>) : (<><PlusIcon className="w-4 inline  " /><span className="truncate">Nastavit záskok</span></>)}
                                            
                                        </button>
                                    </div>
                                    <input name="instructor" type="hidden" {...register("instructor")} />
                                    

                                </div>
                            </div>
                                {!update && substOn &&
                                    <div className="mt-4" >
                                        <label className="block text-gray-700 text-sm font-medium mb-2">Záskok</label>
                                        <SelectCoach data={instructors} selected={substCoach} updateItem={updateSubst} />
                                    
                                    
                                    </div>
                                    
                                } 
                                <input name="sub_id" type="hidden" {...register("sub_id")}  />
                                {secondCoach && 
                                        <><div className="mt-4">
                                            <label className="block text-gray-700 text-sm font-medium mb-2">Druhý lektor</label>
                                                <SelectCoach data={instructors} selected={coach2} updateItem={updateCoach2} except={coach} />
                                                <input name="instructor2" type="hidden" {...register("instructor2")}  />
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>

                           

<div className="inline-flex items-center sm:col-span-4 cursor-pointer  w-auto mt-2" onClick={() => setShop(!shop)}>
                                        
                                        <button  
                                        type="button" aria-pressed="false" aria-labelledby="toggleLabel" 
                                        className={(shop ? 'bg-blue-600' : 'bg-gray-200' ) + " relative inline-flex flex-shrink-0 h-5 w-10 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none "}
                                        >
                                            <span className="sr-only">samostatný prodej</span>
                                            <span aria-hidden="true" 
                                            className={(shop ? 'translate-x-5' : 'translate-x-0' ) + " pointer-events-none  inline-block h-4 w-4 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"}>
                                            </span>
                                        </button>
                                        <span className={(shop ? 'text-gray-900 font-medium' : 'text-gray-400' ) + " text-sm flex space-x-2 ml-3"} id="toggleLabel">
                                            <span >samostatný prodej</span>                                   
                                        </span>
                                    </div>
                            {shop && 
                                <>
                                  <div className="p-5 rounded-md bg-gray-100">
                                    <div className=" space-y-0 flex  space-x-2">
                                        <div className="w-2/3" > 
                                        <input name="service" type="hidden" {...register("service")}  defaultValue={1} />
                                        <input name="service_id" type="hidden" {...register("service_id")} />
                                        <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-2">
                                                Označení lekce v e-shopu
                                                </label>
                                                    <input name="service_name" type="text"   {...register("service_name",{required: true})} 
                                                        className={"text-gray-700  focus:outline-none border border-gray-300  rounded py-2   w-full   " + (errors.service_name && "border-red-600 ")}   />
                                                        {errors.login && 
                                                        <div className="rounded-md bg-red-50 mt-1 p-2"> 
                                                            <svg className="h-5 w-5 text-red-400 inline mr-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                                                            </svg> 
                                                            <span className="text-sm text-red-700">
                                                                Vyplňte název
                                                            </span>
                                                        </div>
                                                        }

                                            </div>
                                             {settings?.timetable?.services_types && 
                                            <div className="w-1/3 ">
                                                <label htmlFor="type_id" className="block text-sm font-medium text-gray-700 mb-2">
                                                Typ
                                                </label>
                                                <div className="mt-1 ">
                                                    <select id="type_id" {...register("type_id")} name="type_id"  className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md ">
                                                    {Object.keys(settings?.timetable?.services_types).map((item,i) => (
                                                        <option key={i} value={settings?.timetable?.services_types[item]?.id}>{settings?.timetable?.services_types[item]?.name}</option>
                                                    ))}
                                                    </select>
                                                </div>
                                            </div>
                                            }
                                            
                                        </div>
                                     <div className="flex space-x-4 mt-4"> 
                                      <div>
                                         <label className="block text-gray-700 text-sm font-medium mb-2">Cena</label>
                                             
                                                 <div className="mt-1 w-36">
                                                      <input {...register("service_price_vat",{required:true})} name="service_price_vat" type="number" className={" text-gray-700 focus:outline-none focus:shadow-outline border border-gray-300 rounded w-36 py-2 px-4  "} />
                                                    
                                                 </div>
                                         
                                         
     
                                        </div>
                                     
                                     <div>
                                         <label className="block text-gray-700 text-sm font-medium mb-2">DPH</label>
                                             
                                                 <div className="mt-1 ">
                                                    <select id="service_vat" {...register("service_vat")} name="service_vat"  className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md ">
                                                    {settings?.invoice?.vat_rates.map((item,i) => (
                                                        <option key={i} value={item}>{item} %</option>
                                                    ))}
                                                    {data?.service?.vat && !settings?.invoice?.vat_rates.includes(data?.service?.vat) &&
                                                        <option value={data?.service?.vat}>{data?.service?.vat}</option>
                                                    }
                                                    </select>
                                                     
                                                 </div>
                                         
                                         
     
                                     </div> 
                                     {settings?.invoiceData && 
                                            <div >
                                                <label htmlFor="data_id" className="block text-sm font-medium text-gray-700 mb-2">
                                                Fakturuje subjekt
                                                </label>
                                                <div className="mt-1 ">
                                                    <select id="data_id" {...register("data_id",{required:true})} name="data_id"  className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md ">
                                                    {settings?.invoiceData.map((item,i) => (
                                                        <option key={i} value={item.invoice_data_id}>{item.invoice_data_name}</option>
                                                    ))}
                                                    </select>
                                                </div>
                                            </div>
                                            }
                                    </div>
                                    <div className="flex space-x-4 mt-4"> 
                                      <div>
                                         <label className="block text-gray-700 text-sm font-medium mb-2">Počet lekcí</label>
                                             
                                                 <div className="mt-1 w-36">
                                                      <input {...register("item_count",{required:true})} name="item_count" type="number" className={" text-gray-700 focus:outline-none focus:shadow-outline border border-gray-300 rounded w-36 py-2 px-4  "} />
                                                    
                                                 </div>
                                         
                                         
     
                                        </div>
                                     
                                     </div>
                                 </div>
                                 </>
                                }

                                <div>
                                    <label className="block text-gray-700 text-sm font-medium mb-2">Poznámka</label>
                                        
                                            <div className="mt-1 ">
                                                <input {...register("text")} name="text" className={" text-gray-700 focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2 px-4 w-full  "} />
                                                
                                            </div>
                                    
                                    

                                </div>
                                <button type="button" onClick={() => setPriceDetails(!priceDetails)} className="flex  text-blue-600 text-xs font-medium ml-2 items-center space-x-1 focus:outline-none  ">
                                            {priceDetails ? (<><MinusIcon className="w-4 inline " /><span>Skrýt možnosti</span></>) : (<><PlusIcon className="w-4 inline " /><span>Další podmínky vstupu</span></>)}
                                            
                                        </button>
                                
                              
                           
                                        {priceDetails && <>
                            <div className="flex space-x-4">
                                {settings?.timetable?.discounts &&
                                <div>
                                    <label className="block text-gray-700 text-sm font-medium mb-2">Sleva</label>
                                        
                                            <div className="mt-1 w-36">
                                                <select id="price" {...register("discount")} name="discount"  className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md pr-8 ">
                                                    <option > -- </option>
                                                    {settings?.timetable?.discounts.map((item,i) => (
                                                        <option key={i} value={item}>{item}</option>
                                                    ))} 
                                                    
                                                </select>
                                            </div>
                                    
                                    

                                </div>
                                }
                                <div>
                                    <label className="block text-gray-700 text-sm font-medium mb-2">Záloha</label>
                                        
                                            <div className="mt-1 ">
                                                <input {...register("fee")} name="fee" type="number" className={" text-gray-700 focus:outline-none focus:shadow-outline border border-gray-300 rounded w-36 py-2 px-4  "} />
                                                
                                            </div>
                                    
                                    

                                </div>
                                <div>
                                    <label className="block text-gray-700 text-sm font-medium mb-2">Strhnout navíc</label>
                                        
                                            <div className="mt-1 ">
                                                <input {...register("extra_charge")} name="extra_charge"  type="number" className={" text-gray-700 focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2 px-4 w-36  "} />
                                                
                                            </div>
                                    
                                    

                                </div>
                            </div>
                            </>
                            }
                        
                    </div> 
                           
                    
                       
                    </form> 

                </div>
                <div>
                    <Lessons {...props} lessons={data?.lessons} event={eventData} roomData={roomData} resources={resources} getEvents={getEvents} selStudio={selStudio} changeDate={changeDate} setEventData={setEventData} />
                </div>
            </div>
               
            {(direct==='1' || unlimited==='1' || props.event?.id || eventData?.timetable_lessons_items_id) && activeTab === 3 ? (
                <>
                    <ClientList {...props} startDate={startDate} getEvents={getEvents} roomData={roomData} clients={clients} setClients={setClients} user={user} selectUser={selectUser} eventData={eventData} lastEntry={lastEntry} booking={booking} setBooking={setBooking} price={price}  />
                </>
            ) : (
                <>
                </>
            )}
                   
                    
                   
                </div>
            </div>
                   
          {activeTab === 1 && 
          
            <div className="px-6 py-5    border-t border-gray-200  text-right right-0 w-full flex">
                        <div className="w-1/2 text-left">
                            <button onClick={() => eventData ? setActiveTab(3) : props.setSlideModalIsOpen(false)}  type="button" className=" relative  px-10 py-2  
                                font-medium  text-white group focus:outline-none flex items-center " >
                                    <div className="h-9 w-6  overflow-hidden inline-block">
                                        <div className=" h-8 w-8 -mt-1 -ml-1 bg-gray-500 group-hover:bg-gray-700  rounded-sm  -rotate-45 transform origin-top-right"></div>
                                    </div>
                                    <div className="h-9 bg-gray-500 px-6 group-hover:bg-gray-700 flex items-center rounded-r-md">
                                        <span>Zpět</span>
                                    </div>
                            </button>   

                            {!allowSave && 
                                <>
                                   <Error text={'Termín obsazen'} />
                                </>
                            }
                        </div>
                        <div className="w-1/2 flex items-center justify-end">

                           
                                        {allowSave ?
                                            <button  onClick={() => confirmChanges()}  type="submit" className=" relative inline-flex items-center px-10 py-2 border border-transparent 
                                            shadow-sm  font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none " >
                                                Uložit změny
                                            </button>
                                         :   <button  disabled className=" relative inline-flex items-center px-10 py-2 border border-transparent 
                                         shadow-sm  font-medium rounded-md text-white bg-gray-500  focus:outline-none opacity-50 cursor-default " >
                                             Nelze uložit
                                         </button>

                                        } 
                                        
                                    
                        </div>
                                
                    </div>
          }  
                    <Shimmer loading={loading} />
        </>
    );

}