import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";    
import { axiosProtected } from "../../client";

export const Edit = ({item,...props}) => {  
    const response = props.response;  
     

    const [activeTab, setActiveTab] = useState(1)
    const [loading, setLoading] = useState(false);  
    const [ean, setEan] = useState(item?.users_data_clenstvi);  
    const [listen, setListen] = useState();  

    const { register, handleSubmit } = useForm({
        mode: "onBlur"
    });
 
    const eanListen = () => {
        setListen(!listen)
        props.setEanActive(false)
        props.setToastOff(true)
    }

    useEffect(() => {  
        !ean &&
        eanListen()
      }
      // eslint-disable-next-line 
    , []);
    
    useEffect(() => { 
        if(response)  {
          if(response.ean && listen) {
            setEan(response.ean)
            props.setResponse(false)
          }
        }
        // eslint-disable-next-line
    }, [response]);

    const onSubmit = async data => {
      setLoading(true); 
      await (axiosProtected()).post('users_data/update/' + item?.users_data_id + '/', data).then(res => {  
        setLoading(false);
        props.setItem(res.data);
        props.setEdit(false);
        props.setToastOff(false)
      }).catch(err => {       
        setLoading(false);
      }) 
    }; 
  

    return (
        <>
        <div className="mb-3">
            <div class="sm:hidden">
                <label for="tabs" class="sr-only">Select a tab</label>
                <select id="tabs" name="tabs" class="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
                <option>Čas</option>

                <option>Company</option>

                <option selected>Klienti</option>

                <option>Billing</option>
                </select>
            </div>
            <div class="hidden sm:block">
                
                <div class="border-b border-gray-200">
                    <div class="flex items-center justify-between flex-wrap sm:flex-nowrap">
                        <nav class="-mb-px flex space-x-8" aria-label="Tabs">
                            
                            <button type="button" onClick={() => setActiveTab(1)} className={(activeTab === 1 ? "border-blue-500 text-blue-600" : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300") + " whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm focus:outline-none"}>
                            Základní údaje
                            </button>

                            <button type="button"onClick={() => setActiveTab(2)} className={(activeTab === 2 ? "border-blue-500 text-blue-600" : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300") + " whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm focus:outline-none"}>
                            Company
                            </button>
                            
                                <button type="button" onClick={() => setActiveTab(3)} className={(activeTab === 3 ? "border-blue-500 text-blue-600" : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300") + " whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm focus:outline-none"}>
                                Klienti
                                
                                </button>
                            
                        </nav>
                        <div class="ml-4 mt-2 flex-shrink-0">
                        {loading ? (
                                    
                                    <button disabled class="h-10 relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-300 ">
                                    <svg className="animate-spin mr-3  h-5 w-5 inline" fill="none" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>
                                    <span>Ukládání...</span></button>
                                ) : (
                                    <>
                                    <button onClick={handleSubmit(onSubmit)} type="button" className="h-10 relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none">
                                        <svg className="w-6 opacity-70 mr-3" id="Layer_1" viewBox="0 0 30 30" fill="currentColor" xmlns="http://www.w3.org/2000/svg" >
                                            <path d="M22,4h-2v6c0,0.552-0.448,1-1,1h-9c-0.552,0-1-0.448-1-1V4H6C4.895,4,4,4.895,4,6v18c0,1.105,0.895,2,2,2h18  c1.105,0,2-0.895,2-2V8L22,4z M22,24H8v-6c0-1.105,0.895-2,2-2h10c1.105,0,2,0.895,2,2V24z"/>
                                            <rect height="5" width="2" x="16" y="4"/>
                                        </svg>
                                    Uložit změny</button>
                                    
                                    </>
                                )} 

                            
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
 
            <form key={1} onSubmit={handleSubmit(onSubmit)} className={"space-y-8  py-2" } >
            
                <div className={activeTab === 1 ? "block" : "hidden"}  >
                    <div className="sm:col-span-4">
                         
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                            EAN
                          </label>
                      
                      <div className="relative  mt-1 inline-block">
                        <input autoComplete="off" {...register('clenstvi')} name="clenstvi" defaultValue={ean}
                        className=" bg-white border w-64 border-gray-300 rounded-md py-2 px-3 text-sm placeholder-gray-500 focus:outline-none focus:text-gray-900 focus:placeholder-gray-400 focus:shadow-lg focus:ring-1 focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                         placeholder="EAN" type="text" />
                         
                        <div className="pointer-events-none absolute inset-y-0 right-14 pr-5 flex items-center"> 
                            {loading && 
                                <svg className="animate-spin -ml-1 mr-3 h-5 w-5 inline" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                            }
                            
                        </div>
                        <button onClick={() => eanListen()} type="button" className={" bg-white hover:bg-gray-100  px-2 border-transparent absolute border-2 right-8 rounded-md top-1 focus:outline-none " + (listen ? (" text-gray-800") : (" text-gray-400"))}>
                            <div className="relative w-7 h-7">

                                <svg version="1.1" fill="currentColor" className="h-7 w-7 absolute" id="Layer_1" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 200 200"  >
                                    <path d="M49.666,129v17h4.104h4.104v-17v-77H53.77h-4.104V129z"/>
                                    <path d="M67.254,129v17h9.381h9.38v-17v-77h-9.38h-9.381V129z"/>
                                    <path d="M94.224,129v17h4.69h4.69v-17v-77h-4.69h-4.69V129z"/>
                                    <path d="M112.984,129v17h4.69h4.69v-17v-77h-4.69h-4.69V129z"/>
                                    <path d="M130.573,129v17h9.38h9.381v-17v-77h-9.381h-9.38V129z"/> 
                                    <polygon points="27,30 17,30 13.5,30 10,30 10,33.5 10,37 10,47 10,55.303 13.5,55.303 17,55.303 17,47 17,37 27,37 35.314,37 
                                        35.314,33.5 35.314,30 "/>
                                    <polygon points="186.651,46.994 186.651,36.994 186.651,33.494 186.651,29.994 183.151,29.994 179.651,29.994 169.651,29.994 
                                        161.349,29.994 161.349,33.494 161.349,36.994 169.651,36.994 179.651,36.994 179.651,46.994 179.651,55.309 183.151,55.309 
                                        186.651,55.309 "/>
                                    <polygon points="169.657,170 179.657,170 183.157,170 186.657,170 186.657,166.5 186.657,163 186.657,153 186.657,144.697 
                                        183.157,144.697 179.657,144.697 179.657,153 179.657,163 169.657,163 161.343,163 161.343,166.5 161.343,170 "/>
                                    <polygon points="10.006,153.006 10.006,163.006 10.006,166.506 10.006,170.006 13.506,170.006 17.006,170.006 27.006,170.006 
                                        35.309,170.006 35.309,166.506 35.309,163.006 27.006,163.006 17.006,163.006 17.006,153.006 17.006,144.691 13.506,144.691 
                                        10.006,144.691 "/>
                                     
                                </svg>
                                {listen &&
                                    <svg version="1.1" fill="currentColor" className="  w-7 animate-move  absolute" id="Layer_1" xmlns="http://www.w3.org/2000/svg"   viewBox="0 -42.106 620 110" >
                                        
                                    <path fill="#FF0000" d="M83.41,20.946v20.947h221.742h221.741V20.946V0H305.152H83.41V20.946z"/>
                                    </svg>
                                }

                            </div>
                            </button>
                        </div>
                    </div>
                     
                </div>
            </form>
            
        </>
    )
}

  