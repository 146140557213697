import React, { Fragment } from "react";
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/solid";
import { classNames } from "../../../services/classnames";

export const LessonType = ({selectedLesson,updateLesson,lessons}) => {
    return (
        <>
               <label htmlFor="lesson" className="block text-sm font-medium text-gray-700 mb-2">
                                Zaměření  
                                </label>
                                <div className="mt-1 ">
                                <div className="w-full ">
                                <Listbox value={selectedLesson} onChange={updateLesson}>
                                    {({ open }) => (
                                    <>
                                        <div className="relative mt-1 z-10">
                                        <Listbox.Button className={() => 
                                            classNames(
                                                ' border rounded-md  py-2 px-4 relative w-full  pl-3 pr-10 text-left bg-white shadow-sm cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-blue-500 focus-visible:ring-offset-blue-300 focus-visible:ring-offset-2 focus-visible:border-blue-500 ',
                                                open ? 'border-blue-500 ring-2 ' : 'border-gray-300 ',
                                            )
                                            }>
                                            <span className="block truncate">{lessons[selectedLesson] ? <span >{lessons[selectedLesson]?.timetable_lessons_nazev}</span> : <span className="opacity-50">bez zaměření</span>}</span>
                                            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                            <ChevronDownIcon
                                                className="w-5 h-5 text-gray-400"
                                                aria-hidden="true"
                                            />
                                            </span>
                                        </Listbox.Button>
                                        <Transition
                                            show={open}
                                            as={Fragment}
                                            leave="transition ease-in duration-100"
                                            leaveFrom="opacity-100"
                                            leaveTo="opacity-0"
                                        >
                                            <Listbox.Options
                                            static
                                            className="absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                                            >
                                                <Listbox.Option
                                                key={-1}
                                                className={({ active }) =>
                                                    `${
                                                    active
                                                        ? "text-blue-900 bg-blue-100"
                                                        : "text-gray-900"
                                                    }
                                                    cursor-default select-none relative py-2 pl-10 pr-4`
                                                }
                                                value=""
                                                >
                                                {({ selected, active }) => (
                                                    <>
                                                    <span
                                                        className={`${
                                                        selected ? "font-medium" : "font-normal"
                                                        } block truncate`}
                                                    >
                                                        bez zaměření
                                                    </span>
                                                    {selected ? (
                                                        <span
                                                        className={`${
                                                            active ? "text-blue-600" : "text-blue-600"
                                                        }
                                                            absolute inset-y-0 left-0 flex items-center pl-3`}
                                                        >
                                                        <CheckIcon
                                                            className="w-5 h-5"
                                                            aria-hidden="true"
                                                        />
                                                        </span>
                                                    ) : null}
                                                    </>
                                                )}
                                                </Listbox.Option>
                                            {lessons && lessons.map((item, i) => (
                                                <Listbox.Option
                                                key={i}
                                                className={({ active }) =>
                                                    `${
                                                    active
                                                        ? "text-blue-900 bg-blue-100"
                                                        : "text-gray-900"
                                                    }
                                                    cursor-default select-none relative py-2 pl-10 pr-4`
                                                }
                                                value={item.id}
                                                >
                                                {({ selected, active }) => (
                                                    <>
                                                    <span
                                                        className={`${
                                                        selected ? "font-medium" : "font-normal"
                                                        } block truncate`}
                                                    >
                                                        {item.timetable_lessons_nazev}
                                                    </span>
                                                    {selected ? (
                                                        <span
                                                        className={`${
                                                            active ? "text-blue-600" : "text-blue-600"
                                                        }
                                                            absolute inset-y-0 left-0 flex items-center pl-3`}
                                                        >
                                                        <CheckIcon
                                                            className="w-5 h-5"
                                                            aria-hidden="true"
                                                        />
                                                        </span>
                                                    ) : null}
                                                    </>
                                                )}
                                                </Listbox.Option>
                                            ))}
                                            </Listbox.Options>
                                        </Transition>
                                        </div>
                                    </>
                                    )}
                                </Listbox>
                                </div>
 
                                   
                                </div>
        </>
    )
}