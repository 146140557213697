import React, { useState, useEffect } from "react"; 
import { useParams, useHistory } from "react-router-dom";  
import 'moment/locale/cs'; 
import { Menu,Transition } from '@headlessui/react';
import { axiosProtected } from "../../client";
import { OrderHeader } from "./detail/OrderHeader";
import { UserCardDefault } from "../users/UserCardDefault";
import { OrderPayments } from "./detail/OrderPayments";
import { OrderServices } from "./detail/OrderServices";
import { OrderGoods } from "./detail/OrderGoods";
import { Order } from "../order/OrderModal"; 

import { addToCart }  from "../cart/cart";  
 

export default function OrderDetail(props) {
  let { number } = useParams();
  const API_URL = localStorage.getItem('domain');
  
  const [edit, setEdit] = useState();      
  const user = props.user;  
  const dialog = props.dialog;
  const history = useHistory();
   
  const [item,setItem] = useState();
  
  useEffect(() => { 
    number &&
      (axiosProtected()).get('order/detail/' + number + '/') 
      .then(res => {
         setItem(res.data);
      })
        .catch(err => {
          console.log(err);
        });
      }
      // eslint-disable-next-line
  , [number]);
  
 
  async function payOrder(id,price) {
    let cartId
    try {
        const res = await addToCart('',{ 
            id: id,
            price: price
        },'order',item?.order) 
        if(res.cart) {
          cartId = res.cart.cartHash
          props.setModalContent({
            component: Order,
            params: {
              client: item?.order, 
              timetableUserId: null,  
              settings: props.settings,
              roomData: null,
              cartId: cartId
            }
          })
          props.setModalIsOpen(true);
        }
      // Do your JSON handling here
    } catch(err) {
          console.log(err);
          // This probably means your response is text, do you text handling here
    } 
    
  }
 

    return(
        <>
          

          {item ? (
                    <>
                      
                      <>
        
        <main className="py-10 h-full bg-gray-100">
        {/* Page header */}
        <div className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
          <OrderHeader item={item?.order} data={item} />
        </div>

        <div className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
          <div className="space-y-6 lg:col-start-1 lg:col-span-2">
            {/* Description list*/}
            <section aria-labelledby="applicant-information-title">
              <div className="bg-white shadow sm:rounded-lg">
                <div className="px-4 py-6 sm:px-6">
                  <OrderServices {...props} data={item?.services} />
                  <OrderGoods {...props} data={item?.goods} />
                </div>
                
              </div>
            </section>

            {/* Comments*/}
            <section aria-labelledby="notes-title">
              <div className="bg-white shadow sm:rounded-lg sm:overflow-hidden">
                <div className="divide-y divide-gray-200">
                  <div className="px-4 py-5 sm:px-6">
                    <h2 id="notes-title" className="text-lg font-medium text-gray-900">
                      Platby
                    </h2>
                  </div>
                  <div className="px-4 py-6 sm:px-6">
                     <OrderPayments {...props} data={item?.payments} />
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-6 sm:px-6">
                   {item?.order?.to_pay < 0 &&
                                <>
                                  <button  onClick={() => payOrder(item?.order?.id,Math.abs(item?.order?.to_pay))}  type="button" className="items-center px-5 py-1 bg-yellow-600 text-white rounded-md focus:outline-none hover:bg-yellow-700 " >
                                    Zaplatit
                                  </button>
                                </>
                    }
                </div>
              </div>
            </section>
          </div>

          <section aria-labelledby="timeline-title" className="lg:col-start-3 lg:col-span-1">
            <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6">
             
              {item?.order.name && <UserCardDefault user={item?.order} />}
              {/* Activity Feed */}
              <div className="mt-6 flow-root">
                <ul role="list" className="-mb-8">
                  
                </ul>
              </div>
              <div className="mt-6 flex flex-col justify-stretch">
                {/* <button
                  type="button"
                  className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  Advance to offer
                </button> */}
              </div>
            </div>
          </section>
        </div>
      </main> 
              
        </>
            
              </> 
              ) : (
                <>
                      <ul   className="flex flex-1 flex-wrap my-10 py-5">
                        <li  className="h-full w-full text-center flex flex-col justify-center items-center  "> 
                            <svg className="animate-spin -ml-1 mr-3  w-10 inline" fill="none" viewBox="0 0 24 24">
                              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg> 
                            <span className="text-small text-gray-400">Načítání...</span>
                        </li>
                      </ul>
                    </>
              ) 
          }
                
            
        </>
    )
}