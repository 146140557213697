
import React, { useState, useEffect } from "react";   
import { axiosProtected } from "../../client";
import { format } from 'date-fns'
import { cs } from 'date-fns/locale'
import { Loading } from "../layout/Loading";

export default function InvoicePrint(props) { 
      
 
    const response = props.response
    const itemId = props.itemId
  
    const [invoice, setInvoice] = useState();
    const [loading, setLoading] = useState();

    function getData(itemId) {
      setLoading(true);
        (axiosProtected()).get('invoice/detail/' + itemId + '/')
          .then(res => {
             setInvoice(res.data.invoice);
             setLoading(false);
          });
     };

    useEffect(() => {
        itemId &&
        getData(itemId)
    }
    // eslint-disable-next-line 
  , [itemId, response]);
 

    return (
       
        <>  
                    {invoice ? 
                        <>
                        <div className="print ">
                              {invoice?.logo && <div className="  filter grayscale pb-2 contrast-200 brightness-0 overflow-hidden grid grid-cols-3 py-2   ">
                                  <div></div>
                                  <div className="flex justify-center "><img src={invoice?.logo} alt="" className=" max-h-14 " /></div>
                                  <div></div>
                                </div>}
                               <div className=" grid grid-cols-2 justify-center border-b border-t "><div className="flex items-center"><span>Daňový doklad</span></div><div className=" text-right text-lg">{invoice?.number}</div></div>
                               <div className="my-2 ">
                                  <div>{invoice?.provider_name}</div>
                                  <div>{invoice?.provider_full_contact}{invoice?.provider_inum && ', IČ '+ invoice?.provider_inum}{invoice?.provider_taxnum && ', DIČ '+ invoice?.provider_taxnum}</div>
                                  <div className="text-xs" > {invoice?.provider_evidence}</div>

                               </div> 
                                <hr />
                                <div className="my-2 space-y-2">
                                  <div className="grid grid-cols-2">
                                    <div className="font-medium">Datum vystavení</div><div className="text-right">{invoice?.date && format(new Date(invoice?.date), 'd. MM. yyyy', {locale: cs})}</div>
                                    {!invoice?.paid && <><div className="font-medium">Datum splatnosti</div><div className="text-right">{invoice?.due_date && format(new Date(invoice?.due_date), 'd. MM. yyyy', {locale: cs})}</div></>}
                                    <div className="font-medium">DUZP</div><div className="text-right">{invoice?.tax_date && format(new Date(invoice?.tax_date), 'd. MM. yyyy', {locale: cs})}</div>
                                    <div className="font-medium">Způsob úhrady</div><div className="text-right">{invoice?.payment}</div>
                                    
                                  </div>

                                </div>
                                <hr />
                                <div className="my-2 ">
                                  {invoice?.client_name && 
                                  <>
                                    <div className="font-medium">Kupující</div>
                                    <div>{invoice?.client_full_contact} </div>
                                    <div className="mt-2">{invoice?.client_inum && 'IČ ' + invoice?.client_inum} {invoice?.client_taxnum && 'DIČ ' + invoice?.client_taxnum}</div>
                                  </>
                                  }

                               </div> 
                               <hr />
                                <div className="my-2 ">
                                  {invoice?.items && 
                                  <>
                                    <div className="font-medium grid grid-cols-5">
                                      <div className="col-span-2">ks x cena</div>
                                      <div  className="text-right" >DPH</div>
                                      <div  className="text-right">s DPH</div>
                                      <div  className="text-right">celkem</div> 
                                    </div>
                                    {invoice.items.map((item,itemIdx) => (
                                      <div className="font-medium grid grid-cols-5" key={itemIdx}>
                                        <div className="col-span-2">{item.count} × {item.price.toLocaleString()} {invoice?.currency}</div>
                                        <div className="text-right" >{item.vat} %</div>
                                        <div className="text-right" >{item.price_vat.toLocaleString()} {invoice?.currency}</div>
                                        <div className="text-right" >{item.item_sum.toLocaleString()} {invoice?.currency}</div>
                                        <div className="col-span-5 font-normal pl-3">{item.text}</div>
                                      </div>
                                    ))}
                                     
                                     
                                  </>
                                  }

                               </div> 
                               <hr />
                                <div className="my-2 ">
                                  <div className="font-medium">Vyčíslení DPH</div>
                                  <div className="font-medium grid grid-cols-3">
                                      <div  className="text-right" >Sazba</div>
                                      <div  className="text-right">Základ DPH</div>
                                      <div  className="text-right">DPH</div>
                                    </div>
                                    {invoice.tax_details.map((item,itemIdx) => (
                                      <div className=" grid grid-cols-3" key={itemIdx}>
                                        <div className="text-right" >{item.rate} %</div>
                                        <div className="text-right" >{item.price.toLocaleString()} {invoice?.currency}</div>
                                        <div className="text-right" >{item.vat.toLocaleString()} {invoice?.currency}</div>
                                      </div>
                                    ))}
                                    
                                </div>
                               <hr />
                                <div className="my-2 ">
                                  <div className="font-medium grid grid-cols-2">
                                      <div className="font-normal">DPH</div>
                                      <div className="font-normal text-right">{invoice?.vatsum.toLocaleString()} {invoice?.currency}</div>
                                      <div className="font-normal">Celkem bez DPH</div>
                                      <div className="font-normal text-right">{invoice?.sum.toLocaleString()} {invoice?.currency}</div>
                                      <div className="">Celkem včetně DPH</div>
                                      <div className="text-right  text-lg">{invoice?.sum_vat.toLocaleString()} {invoice?.currency}</div>
                                  </div>
                                </div>
                                {invoice?.paid && 
                                  <div className="my-2 ">
                                    <div className="font-medium grid text-right">
                                     Zaplaceno {invoice?.payment}!      
                                    </div>
                                  </div>
                                
                                }
                               <br />
                               <br />
                               <br />
                               <span style={{fontSize: '1px'}}>-</span>
                                
                        </div>
                        <div>
                        </div>  
                        </>                
                       : <Loading loading={loading} /> }
           
           
        </>
       
    )
   }
 
