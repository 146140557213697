import { format } from 'date-fns'
import { cs } from 'date-fns/locale'

export const OrderPayments = ({data,...props}) => {
 
    function print(id) {
        props.setPrintContent({
            component: 'InvoicePrint',
            params: {
              itemId: id, 
            }
          })
        props.setPrintIsOpen(true)
    }


    return (
        <>
        <div className="divide-y">
            {data && data.map((item,itemIdx) => (
                <div key={itemIdx} className={"flex w-full justify-between py-1 " + (item?.paid ? " text-gray-900 font-medium" : " text-gray-400") }>
                    <div>
                        {item?.date && format(new Date(item?.date), 'd. MM. yyyy', {locale: cs})}
                    </div>
                    <div className="text-right w-24">
                        {item?.amount.toLocaleString()} {item?.currency}
                    </div>
                    <div className="font-normal flex items-center space-x-2 w-36 justify-end">
                        {item?.invoice_id && 
                        <>
                            <span>{item?.invoice_number}</span>
                            <button  onClick={() => print(item?.invoice_id)} title={item?.invoice_number}>
                                <svg version="1.1" fill="currentColor" className="w-5 h-5" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 427.058 427.058"  > 
                                        <path d="M364.845,11.045L364.845,11.045c-7.148-3.292-15.569-2.012-21.416,3.254l-27.754,24.575l-37.76-33.777
                                            c-7.586-6.796-19.071-6.796-26.657,0l-37.729,33.745L175.791,5.097c-7.588-6.796-19.074-6.796-26.662,0l-37.77,33.777L83.579,14.3
                                            c-8.197-7.286-20.749-6.547-28.035,1.65c-3.262,3.67-5.049,8.419-5.015,13.329v368.5c-0.065,10.983,8.786,19.939,19.769,20.004
                                            c4.911,0.029,9.66-1.761,13.331-5.024l27.754-24.575l37.76,33.777c7.586,6.796,19.071,6.796,26.657,0l37.729-33.749l37.735,33.745
                                            c7.588,6.795,19.074,6.795,26.662,0l37.77-33.776l27.78,24.574c8.196,7.288,20.748,6.552,28.035-1.644
                                            c3.265-3.671,5.052-8.423,5.018-13.336V29.279C376.628,21.412,372.033,14.242,364.845,11.045z M328.905,373.201
                                            c-7.587-6.721-19.007-6.691-26.558,0.071l-37.759,33.776l-37.73-33.745c-7.587-6.796-19.073-6.796-26.66,0l-37.734,33.745
                                            l-37.775-33.776c-7.522-6.761-18.922-6.792-26.481-0.072l-27.679,24.568v-53.305l-0.1-315.179l27.724,24.569
                                            c7.587,6.721,19.007,6.691,26.558-0.071l37.759-33.776l37.73,33.745c7.587,6.796,19.073,6.796,26.66,0l37.734-33.745
                                            l37.775,33.776c7.522,6.761,18.922,6.792,26.481,0.072l27.679-24.575v253.312l0.1,115.179L328.905,373.201z"/> 
                                        <path d="M308.322,203.527H118.736c-5.523,0-10,4.477-10,10s4.477,10,10,10h189.586c5.523,0,10-4.477,10-10
                                            S313.844,203.527,308.322,203.527z"/> 
                                        <path d="M218.322,143.527h-99.586c-5.523,0-10,4.477-10,10s4.477,10,10,10h99.586c5.523,0,10-4.477,10-10
                                            S223.844,143.527,218.322,143.527z"/> 
                                        <path d="M308.322,263.527H118.736c-5.523,0-10,4.477-10,10c0,5.523,4.477,10,10,10h189.586c5.523,0,10-4.477,10-10
                                            C318.322,268.004,313.844,263.527,308.322,263.527z"/> 
                                </svg> 
                            </button>
                            
                        </>
                        }
                    </div>
                </div>
            ))
            
            }
        </div>
        </>
    )
}