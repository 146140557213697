import { Menu,Transition } from '@headlessui/react'; 
import { PlusCircleIcon } from '@heroicons/react/outline';
import { DotsVerticalIcon } from '@heroicons/react/solid'; 

export default function StudioSubmenu({slideModalOpen,disabledResources,setDisabledResources}) { 
    
 
    return(
        <>
            <Menu>
                        {({ open }) => (
                            <>
                        
                             
                            <Menu.Button className={"flex text-sm  px-2 py-2 rounded-md text-gray-600 hover:bg-gray-300  focus:outline-none " + (open && "bg-gray-300")} id="user-menu">
                                <DotsVerticalIcon className="w-5" />
                            </Menu.Button>
                            
                            
                        
                            <Transition className="absolute right-0 top-10 z-10 "
                            show={open}
                            enter="transition ease-out duration-100 transform"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="transition ease-in duration-75 transform"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                            >
                            <Menu.Items
                            static
                            className=" w-80 lg:w-64 mb-2 bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg outline-none"
                            >
                          

                            <div className="py-1">
                                <Menu.Item>
                                    <button onClick={() => slideModalOpen()}
                                    className="hover:bg-gray-100 text-gray-900 flex space-x-3 w-full px-4 py-2 text-sm leading-5 text-left">
                                        <PlusCircleIcon className="w-5 h-5 opacity-70" />
                                        <span>Založit novou lekci</span>
                                    </button>
                                
                                </Menu.Item>
                                  
                            </div>
                            <div className="py-1">
                                <Menu.Item>
                                    <button onClick={() => setDisabledResources(!disabledResources)}
                                    className="hover:bg-gray-100 text-gray-900 flex space-x-3 w-full px-4 py-2 text-sm leading-5 text-left">
                                        {disabledResources 
                                        ? 
                                            <>
                                            <PlusCircleIcon className="w-5 h-5 opacity-70" />
                                            <span>Rozdělit podle sálů</span>
                                            </>
                                        :
                                            <>
                                            <PlusCircleIcon className="w-5 h-5 opacity-70" />
                                            <span>Zrušit rozdělení podle sálů</span>
                                            </>
                                        }
                                    </button>
                                
                                </Menu.Item>
                                  
                            </div>

                            
                            </Menu.Items>
                          </Transition>
                          </>
                          )}
                          
                        </Menu>
                        
        </>
    )
}