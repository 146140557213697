import { PhoneIcon } from "@heroicons/react/solid";

export const UserCardDefault = (props) => { 
    const user = props.user;  
    const active = props.active; 

    
    const submit = props.submit;

    return (
  
        <div className="flex items-center    w-full text-left border-l-4 border-transparent">
            <div className="min-w-0 flex-1 flex items-center">
            <div className="flex-shrink-0">
                <div className={"hidden lg:flex h-14 w-14 overflow-hidden  border-2    rounded-full  flex-wrap content-center justify-center " 
                + (user?.nick==='company' ? " bg-gray-200 border-gray-200 text-gray-400 " : " bg-blue-200 border-blue-300 text-blue-700")
                }>

                {user?.img ? (
                    <img src={user?.img} alt="" className="h-10 w-12 fill-current   rounded-full"  />
                    ) : ( 
                    user?.nick === 'company' ? (
                        <>
                        <div className="h-10 w-10 bg-gray-100 rounded-full ring-8 ring-white flex items-center justify-center">
                        
                        <svg className="w-8 rounded-full bg-gray-400 text-gray-100 p-1" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 484.738 484.738" stroke="currentColor"> 
                            <path d="M131.012,438.372h85.624v-87.557h51.467v87.557h85.624V46.366H131.012V438.372z M257.993,92.564h51.466v51.467h-51.466
                            V92.564z M257.993,175.279h51.466v51.467h-51.466V175.279z M257.993,257.992h51.466v51.467h-51.466V257.992z M175.279,92.564
                            h51.466v51.467h-51.466V92.564z M175.279,175.279h51.466v51.467h-51.466V175.279z M175.279,257.992h51.466v51.467h-51.466V257.992
                            z"/>
                            <path d="M373.719,81.482V358.66h33.065v79.712h77.954V81.482H373.719z M444.437,321.009H397.58v-46.856h46.856V321.009z
                            M444.437,245.703H397.58v-46.855h46.856V245.703z M444.437,170.398H397.58v-46.855h46.856V170.398z"/>
                            <path d="M0,438.372h77.954V358.66h33.065V81.482H0V438.372z M40.302,123.543h46.856v46.855H40.302V123.543z M40.302,198.848
                            h46.856v46.855H40.302V198.848z M40.302,274.151h46.856v46.855H40.302V274.151z"/>
                        </svg>  
                        </div>
                        
                        </>
                    ) : (
                        <span className="truncate block uppercase text-xl">{user?.nick}</span>
                    ) 
                    )
                }
                </div>
            </div>
            <div className="min-w-0 flex-1 px-4 pl-0 lg:pl-4 ">
                <div className=" items-center">
                <p className="font-medium text-gray-700  w-full">{user?.name}</p>
                {user?.email &&
                    <p className="truncate font-normal block text-sm">{user?.email}</p>
                    
                    }
                {user?.tel &&
                    <p className="truncate font-normal flex space-x-2 text-sm "><PhoneIcon className="w-4 text-gray-400" /> <span>{user?.tel}</span></p>
                    
                    }
              
                    
                </div>
                <div className="hidden md:block">
                <div>
                    <p className="text-sm text-gray-900">
                    
                    </p>
                    <p className="mt-2 flex items-center text-sm text-gray-500">
                    
                    </p>
                </div>
                </div>
            </div>
            </div>
            <div>
            
            </div>
        </div> 
    )
}