import React, {useEffect} from 'react'; 
import {Transition} from "@headlessui/react"; 

export const Notification = (props) => {
  
    const text = props.text; 
    const notification = props.notification; 
    const setNotification = props.setNotification; 
  
    

    
    useEffect(() => { 
        notification &&
        setTimeout(
            () => setNotification(false)
        , 4500);
        clearTimeout();
        // eslint-disable-next-line
    }, [notification]);  

    return (
        <>
                    
            <Transition
                show={notification}
                className="fixed inset-0 z-50 flex items-end justify-center px-4 py-6 pointer-events-none sm:p-6 sm:px-10 sm:items-start sm:justify-end top-4" 
                enter="transform ease-out duration-300 transition"
                enterFrom="translate-y-2 opacity-0"
                enterTo="translate-y-0 opacity-100 sm:translate-x-0"
                leave="transform ease-in duration-300 transition"
                leaveFrom="opacity-100 "
                leaveTo="opacity-0 translate-y-2 "
            > 
            <div class="max-w-sm w-full bg-yellow-600 shadow-lg text-sm font-medium rounded-md pointer-events-auto text-white border border-yellow-400">
                <div class="p-4 relative">
                <div class="grid grid-cols-10 justify-items-stretch">
                    
                    <svg className="w-5 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                    <div className="col-span-8">
                        {text}

                    </div>
                    
                    <button onClick={() => setNotification(false)} class="justify-self-end w-5 rounded-md inline-flex text-gray-100 hover:text-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white ">
                        <span class="sr-only">Close</span>
                        
                        <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
                        </svg>
                    </button>
                     
                </div>
                </div>
            </div>
            </Transition>
             
        </>
    )
}