import { useEffect, useState } from "react"
import { Service } from "./Service"


export const Services = ({activeTab,setActiveTab,cartId,services,price,setItems,setCart,client}) => {
   
    const [tabLength, setLength] = useState(3);
    useEffect(() => {  
        setLength(Object.keys(services).length)
      }
      // eslint-disable-next-line 
    , [services]);
     
    return(
        <>
        <div className="mb-3">
            <div  className={ tabLength < 4 ? "sm:hidden " : ""} >
                <label htmlFor="tabs" className="sr-only">Vyberte možnost</label>
                <select defaultValue={activeTab} onChange={(e) => setActiveTab(parseInt(e.target.value))} id="tabs" name="tabs" className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md">
                    {services && Object.keys(services).map((serviceType, i) => (
                    <option key={i} value={i}>{services[serviceType].name}</option>
                    ))}
             
                </select>
            </div>
            
            <div className={ tabLength < 4 ? "hidden sm:block " : "hidden"} >
                <div className="border-b border-gray-200">
                    <nav className="-mb-px grid grid-cols-4 gap-3" aria-label="Tabs">
                        {services && Object.keys(services).map((serviceType, i) => (
                            <button key={i} onClick={() => setActiveTab(i)} className={(activeTab === i ? "border-blue-500 text-blue-600" : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300") + " whitespace-nowrap overflow-hidden truncate py-4 px-1 border-b-2 font-medium text-sm focus:outline-none"}>
                                {services[serviceType].name}
                          
                            </button>
                             
                        ))}
                       
                        
                         
                    </nav>
                </div>
            </div> 
        </div>


        {services && Object.keys(services).map((serviceType, i) => (
           <div key={i} className={activeTab === i ? "block" : "hidden"} >
            
                <div className="divide-y divide-gray-200 ">
               {Object.keys(services[serviceType]?.items).map((service, i) => (
                    <Service key={i} service={services[serviceType]?.items[service]} cartId={cartId} price={price} setItems={setItems} setCart={setCart} client={client} />
               ))}
                <div />
               </div>
           </div> 
        ))}
        
        </>
    )
}