import { useState, useEffect, useCallback } from "react"; 
import {axiosProtected} from "../client"

function useFetch(query, page, params) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [list, setList] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [catTags, setCatTags] = useState(false);

  useEffect(() => { 
    setList([]);  
  }, [query, params]);

  const sendQuery = useCallback(async () => {
    try {
      await setLoading(true);
      await setError(false);
      const res = await (axiosProtected()).post(query,
        {
          page: page,
          tags: params
        }
      );
      setList((prev) => [
        ...new Set([...prev, ...res.data.items.map((d) => d)])
      ]);
      setHasMore(res.data.has_more);
      setCatTags(res.data.tags);
      setLoading(false);
    } catch (err) { 
      setError(err);
    }
  }, [query, page, params]);
 
  useEffect(() => {  
      query && sendQuery(query);  
  }, [query, sendQuery, params]);

  return { loading, error, list, setList, hasMore, catTags };
}

export default useFetch;