
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import ReactSlider from "react-slider";

export const Difficulty = ({difficulty, setDifficulty}) => {

    
    return (
         
        <div className="flex items-center space-x-3"> 
            <div className="w-20 inline-block relative ">

                <ReactSlider
                    step={1}
                    min={1}
                    max={3}  
                    className={" w-20 h-5 border-2  my-2  rounded-full cursor-pointer transition ease-in-out duration-200 " 
                    + (difficulty===1 ? "bg-green-500 border-green-500" : difficulty===2 ? "bg-yellow-500 border-yellow-500" : "bg-red-600 border-red-600" )}
                    thumbClassName={"absolute  w-4 h-4 cursor-pointer rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500   transition ease-in-out duration-200 bg-white flex justify-center overflow-hidden text-gray-500" 
                      } 
                    value={difficulty}
                    markClassName={""}
                    onChange={setDifficulty}
                    onAfterChange={(value) => setDifficulty(value)} 
                    renderThumb={(props) => <div  {...props} ><ChevronLeftIcon className="h-5 w-5 -mt-0.5 " /><ChevronRightIcon  className="h-5 w-5 -mt-0.5 -ml-1 "/> </div>}
                    
                />
                
            </div>
            <span className="text-sm text-gray-500">{(difficulty===1 ? "nízká" : difficulty===2 ? "střední" : difficulty===3 && "vysoká" )  } obtížnost</span>
        </div>
    
    )
}