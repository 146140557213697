import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import axios from "axios";
import UsersLayout from "./usersLayout";
import "moment/locale/cs";
import { Menu, Transition } from "@headlessui/react";
import { Edit } from "./edit";
import { axiosProtected } from "../../client";

const Detail = ({ item, ...props }) => {
  return (
    <>
      <div className="pt-6 divide-y divide-gray-200">
        {item?.users_data_surname}
      </div>
    </>
  );
};

export default function UsersDetail(props) {
  let { id } = useParams();
  const [edit, setEdit] = useState();
  const dialog = props.dialog;
  const history = useHistory();

  const [item, setItem] = useState();

  async function deleteUser() {
    await axiosProtected()
      .post("users_data/delete/", {
        id: id,
      })
      .then((res) => {
        history.push("/users");
      })
      .catch((err) => {});
  }

  useEffect(
    () => {
      id &&
        axiosProtected()
          .get("users_data/detail/" + id + "/")
          .then((res) => {
            setItem(res.data);
          })
          .catch((err) => {
            console.log(err);
          });
    },
    // eslint-disable-next-line
    [id]
  );

  return (
    <>
      <UsersLayout {...props}>
        {item ? (
          <>
            <header className=" py-8">
              <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 xl:flex xl:items-center xl:justify-between">
                <div className="flex-1 min-w-0">
                  <h1 className="mt-2 text-2xl font-medium leading-7 text-gray-900 sm:text-3xl sm:truncate lg:h-8">
                    {item?.users_data_company}
                    {item?.users_data_fname} {item?.users_data_surname}
                  </h1>
                  <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-8">
                    <div className="mt-2 flex items-center text-sm text-gray-500">
                      <svg
                        className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        fill="currentColor"
                        viewBox="0 0 200 200"
                      >
                        <path d="M49.666,129v17h4.104h4.104v-17v-77H53.77h-4.104V129z" />
                        <path d="M67.254,129v17h9.381h9.38v-17v-77h-9.38h-9.381V129z" />
                        <path d="M94.224,129v17h4.69h4.69v-17v-77h-4.69h-4.69V129z" />
                        <path d="M112.984,129v17h4.69h4.69v-17v-77h-4.69h-4.69V129z" />
                        <path d="M130.573,129v17h9.38h9.381v-17v-77h-9.381h-9.38V129z" />
                        <polygon
                          points="27,30 17,30 13.5,30 10,30 10,33.5 10,37 10,47 10,55.303 13.5,55.303 17,55.303 17,47 17,37 27,37 35.314,37 
                                        35.314,33.5 35.314,30 "
                        />
                        <polygon
                          points="186.651,46.994 186.651,36.994 186.651,33.494 186.651,29.994 183.151,29.994 179.651,29.994 169.651,29.994 
                                        161.349,29.994 161.349,33.494 161.349,36.994 169.651,36.994 179.651,36.994 179.651,46.994 179.651,55.309 183.151,55.309 
                                        186.651,55.309 "
                        />
                        <polygon
                          points="169.657,170 179.657,170 183.157,170 186.657,170 186.657,166.5 186.657,163 186.657,153 186.657,144.697 
                                        183.157,144.697 179.657,144.697 179.657,153 179.657,163 169.657,163 161.343,163 161.343,166.5 161.343,170 "
                        />
                        <polygon
                          points="10.006,153.006 10.006,163.006 10.006,166.506 10.006,170.006 13.506,170.006 17.006,170.006 27.006,170.006 
                                        35.309,170.006 35.309,166.506 35.309,163.006 27.006,163.006 17.006,163.006 17.006,153.006 17.006,144.691 13.506,144.691 
                                        10.006,144.691 "
                        />
                      </svg>
                      {item?.users_data_clenstvi}
                    </div>
                    <div className="mt-2 flex items-center text-sm text-gray-500">
                      <svg
                        className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
                          clip-rule="evenodd"
                        />
                      </svg>
                      Remote
                    </div>
                    <div className="mt-2 flex items-center text-sm text-gray-500">
                      <svg
                        className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path d="M8.433 7.418c.155-.103.346-.196.567-.267v1.698a2.305 2.305 0 01-.567-.267C8.07 8.34 8 8.114 8 8c0-.114.07-.34.433-.582zM11 12.849v-1.698c.22.071.412.164.567.267.364.243.433.468.433.582 0 .114-.07.34-.433.582a2.305 2.305 0 01-.567.267z" />
                        <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a1 1 0 10-2 0v.092a4.535 4.535 0 00-1.676.662C6.602 6.234 6 7.009 6 8c0 .99.602 1.765 1.324 2.246.48.32 1.054.545 1.676.662v1.941c-.391-.127-.68-.317-.843-.504a1 1 0 10-1.51 1.31c.562.649 1.413 1.076 2.353 1.253V15a1 1 0 102 0v-.092a4.535 4.535 0 001.676-.662C13.398 13.766 14 12.991 14 12c0-.99-.602-1.765-1.324-2.246A4.535 4.535 0 0011 9.092V7.151c.391.127.68.317.843.504a1 1 0 101.511-1.31c-.563-.649-1.413-1.076-2.354-1.253V5z"
                          clip-rule="evenodd"
                        />
                      </svg>
                      $120k &ndash; $140k
                    </div>
                    <div className="mt-2 flex items-center text-sm text-gray-500">
                      <svg
                        className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                          clip-rule="evenodd"
                        />
                      </svg>
                      Closing on January 9, 2020
                    </div>
                  </div>
                </div>
                <div className="mt-5 flex xl:mt-0 xl:ml-4">
                  <span className="hidden sm:block">
                    <button
                      onClick={() => setEdit(!edit)}
                      type="button"
                      className={
                        (edit
                          ? " bg-blue-600 text-white hover:bg-blue-700 "
                          : " text-gray-700 bg-white ") +
                        " inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium hover:bg-gray-50 focus:outline-none"
                      }
                    >
                      <svg
                        className="-ml-1 mr-2 h-5 w-5 opacity-50"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                      </svg>
                      Upravit
                    </button>
                  </span>

                  <span className="hidden sm:block ml-3">
                    <button
                      type="button"
                      className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-purple-500"
                    >
                      <svg
                        className="-ml-1 mr-2 h-5 w-5 text-gray-400"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M12.586 4.586a2 2 0 112.828 2.828l-3 3a2 2 0 01-2.828 0 1 1 0 00-1.414 1.414 4 4 0 005.656 0l3-3a4 4 0 00-5.656-5.656l-1.5 1.5a1 1 0 101.414 1.414l1.5-1.5zm-5 5a2 2 0 012.828 0 1 1 0 101.414-1.414 4 4 0 00-5.656 0l-3 3a4 4 0 105.656 5.656l1.5-1.5a1 1 0 10-1.414-1.414l-1.5 1.5a2 2 0 11-2.828-2.828l3-3z"
                          clip-rule="evenodd"
                        />
                      </svg>
                      View
                    </button>
                  </span>
                  <div class="ml-2 flex-shrink-0 relative">
                    <Menu>
                      {({ open }) => (
                        <>
                          <Menu.Button
                            className={
                              " rounded-md px-3 py-2 flex text-sm text-gray-600 hover:bg-gray-200 hover:text-gray-800  focus:outline-none  " +
                              (open && " bg-gray-200 ")
                            }
                            aria-label="Notifications"
                          >
                            <svg
                              className="w-6 "
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"
                              />
                            </svg>
                          </Menu.Button>

                          <Transition
                            show={open}
                            enter="transition ease-out duration-100 transform"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="transition ease-in duration-75 transform"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                          >
                            <Menu.Items
                              static
                              className=" origin-top-right absolute z-30 right-0 mt-1 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                            >
                              <div className="divide-y space-y-2 text-sm ">
                                <div className="p-3 px-4">Možnosti</div>
                                <div className="py-1">
                                  <Menu.Item>
                                    <button
                                      onClick={() =>
                                        dialog(
                                          "Opravdu odstranit klienta?",
                                          () => deleteUser()
                                        )
                                      }
                                      className={`  text-gray-800 flex  w-full px-4 py-2 leading-5 text-left outline-none hover:bg-gray-100`}
                                    >
                                      <svg
                                        className="w-5 text-red-600 mr-2"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          strokeWidth={2}
                                          d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                                        />
                                      </svg>

                                      <span className="truncate">
                                        Odstranit
                                      </span>
                                    </button>
                                  </Menu.Item>
                                </div>
                              </div>
                            </Menu.Items>
                          </Transition>
                        </>
                      )}
                    </Menu>
                  </div>
                </div>
              </div>
            </header>

            <main className=" mx-auto  bg-white p-6 px-8 ">
              {edit ? (
                <Edit
                  {...props}
                  item={item}
                  setItem={setItem}
                  setEdit={setEdit}
                />
              ) : (
                <Detail {...props} item={item} />
              )}
            </main>
          </>
        ) : (
          <>
            <ul className="flex flex-1 flex-wrap my-10 py-5">
              <li className="h-full w-full text-center flex flex-col justify-center items-center  ">
                <svg
                  className="animate-spin -ml-1 mr-3  w-10 inline"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
                <span className="text-small text-gray-400">Načítání...</span>
              </li>
            </ul>
          </>
        )}
      </UsersLayout>
    </>
  );
}
