import axios from 'axios';
import {API_URL} from "../config";
import {PUBLIC_URL} from "../config";

const sign = require('jwt-encode');
const secret = 'hbGciOiJxNTE2MjM5MIUzI1NiIsInR5cCI6ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaNTY3fyWF0IjoDIew56y';
const data = {
  name: 'intui',
};
const jwt = sign(data, secret); 
 

const axiosInstance = axios.create({
  // withCredentials: true,
  baseURL: PUBLIC_URL,
  headers: {
    'Content-Type': 'application/json',
    "Authorization" : `Bearer ` + jwt
  }
});


const protectedAxios = () => {
  const userToken = JSON.parse(localStorage.getItem('user'));

  return axios.create({
      // withCredentials: true,
      baseURL: API_URL,
      headers: {
          'Content-Type': 'application/json',
          "Authorization" : `Bearer ` + userToken?.token
      }
  });
}

export const axiosProtected = protectedAxios; 
export default axiosInstance; 