let URL
let NODE
let NAME
const STAGE = process.env.NODE_ENV

if(STAGE==='development') {
   //URL = 'https://www.energystudio.cz/';
   URL = 'http://localhost/energystudio/';
   NAME = 'energystudio.cz';
   NODE = 'https://enodejs.rozhled.cz/' + NAME;
} else {
   URL = document.querySelector('#settings').getAttribute('data-domain');
   NODE = document.querySelector('#settings').getAttribute('data-domain-node'); 
}

export const DOMAIN = NAME
export const NODE_URL = NODE
export const API_URL = URL + 'api/'
export const PUBLIC_URL = URL + 'public/'
export const SCOMOS_URL = URL