
import React, { useState, useEffect, useRef } from "react"; 
import { NavLink } from "react-router-dom";
import axios from "axios";
import { Menu,Transition } from '@headlessui/react';
import { useForm } from "react-hook-form";
import { ShoppingBagIcon, PlusCircleIcon } from '@heroicons/react/outline'
import { axiosProtected } from "../../client";

export default function CartList(props) {
    const { register, handleSubmit } = useForm();
    const onSubmit = data => {}; 
    const [cartList, setCartList] = useState([]); 
    const [cartListSum, setCartListSum] = useState(); 
    const [cartBackup, setCartBackup] = useState([]);  

    const response = props.response;
   
    
    useEffect(() => {
        if(response) { 
            if(response?.carts === 'update') {
                cartListUpdate();
            }
        }
        // eslint-disable-next-line
    }, [response])
 
    function cartListUpdate() {
        (axiosProtected()).post('cart/carts/',
        {})
        .then(res => {
           setCartList(res.data);
           setCartListSum(res.data.length);
           setCartBackup(res.data);
        })
        .catch(err => {
          console.log(err);
        });
    }
    
    function search(e) { 
        const val = e.target.value;
        let carts = cartBackup;
        carts = carts.filter((cart) => {
            let cartName = cart.id
            return cartName.indexOf(
                val) !== -1
        }) 
        val 
        ? setCartList(carts)
        : setCartList(cartBackup)
        //let result = cartList.filter(t=>t.id < 120); 
        
    }

    function resetForm() {
        setTimeout(() => { 
            setCartList(cartBackup)
        }, 1000);
    }
    
    useEffect(() => {
        cartListUpdate();        
    }
      // eslint-disable-next-line
    , []);
      
      return ( 
        <> 
            <Menu>
                {({ open }) => (
                    <>
                
                   
                    <Menu.Button className={'py-3 px-4 rounded-md text-left w-full focus:outline-none transition duration-150 ease-in-out  hover:text-gray-200 hover:bg-gray-900  ' +  
                        (open ? ' bg-gray-900  text-white '  : 'text-gray-200')} id="user-menu">
                    <div className="relative">
                    <ShoppingBagIcon className="w-5 h-5 inline -mt-2"  />
                        <svg className="w-6 h-6 -ml-4 inline absolute" xmlns="http://www.w3.org/2000/svg"  fill="#1F2937" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z" />
                        </svg>
        
                        {cartListSum > 0 &&
                            <div className="absolute top-0 right-0 -mt-2 -mr-2  text-xs w-5 h-5 bg-blue-700 text-white rounded-full flex items-center justify-center"><span>{cartListSum}</span></div>
                        }
                    </div>
                    </Menu.Button>
                    
                
                    <Transition className="absolute right-0 top-18  w-full  max-w-2xl lg:right-6"
                    show={open}
                    enter="transition ease-out duration-100 transform"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="transition ease-in duration-75 transform"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                    >
                    <Menu.Items
                    static
                    className=" m-2 mt-1 mb-2 bg-white border border-gray-200  divide-y divide-gray-100 rounded-md shadow-lg outline-none"
                    >
                    
                    <div className="px-4 py-3 bg-gray-100 rounded-md">
                         <div className="mb-2 font-medium">
                           Otevřené účty
                        </div>
                        <form onSubmit={handleSubmit(onSubmit)}  onBlur={() => resetForm()} className="relative">
                    
                            <input {...register('filter')} onChange={search}  onClick={() => {}} 
                            type="search" name="filter" autoComplete="off"   placeholder="Hledat" 
                            className="block w-full bg-white border border-gray-300 rounded-md py-2 pl-10 pr-3 text-sm placeholder-gray-500 focus:outline-none focus:text-gray-900 focus:placeholder-gray-400  focus:ring-1 focus:ring-blue-500 focus:border-blue-500 sm:text-sm" placeholder="Hledat" type="search" />
                        <div className="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center"> 
                            
                                <svg className="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
                                </svg> 
                            
                        </div>
                            
                        </form>
                    </div>

                    <div className="py-4 divide-y divide-gray-100 max-h-96  overflow-auto origin-top-right ">
                        <Menu.Item  key={-1}>
                            {({ active }) => (
                                <NavLink
                                exact to={'/prodej'} 
                                className={`${
                                    active
                                    ? "bg-gray-100 text-gray-900"
                                    : "text-gray-700"
                                } flex  w-full px-4 py-2 text-sm leading-5 text-left outline-none`}
                                >
                                     <PlusCircleIcon className="w-5 mr-2"  />
                                        <span>Nový košík</span>
                                </NavLink>
                            )}
                            </Menu.Item>
                            {cartList && cartList.map((cart, itemIdx) => (
                                    <Menu.Item key={itemIdx} onClick={() => { props.slideModalClose();props.modalClose(); }}>
                                    {({ active }) => (
                                        <NavLink
                                        key={cart.id}
                                        to={ '/prodej/' + cart.name }
                                        className={`${
                                            active
                                            ? "bg-gray-100 text-gray-900"
                                            : "text-gray-700"
                                        } flex justify-between w-full px-4 py-2 text-sm leading-5 text-left outline-none`}
                                        >
                                        <span className="truncate">
                                        {cart.cartName}
                                        </span>
                                        <span className="ml-auto inline-block py-0.5 px-3 text-xs rounded-full bg-gray-300 group-hover:bg-gray-400">
                                        {cart.items}
                                        </span>
                                        </NavLink>
                                    )}
                                    </Menu.Item>
                    
                                    
                                ))} 
                    </div>

                    
                    </Menu.Items>
                    </Transition>
                    </>
                    )}
                    
                </Menu>

        </>
      )

   }