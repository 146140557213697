import { Menu,Transition } from '@headlessui/react'; 
import { ChevronDownIcon } from '@heroicons/react/outline'; 

  export const ResourceHeader = ({label, resources, setVisibleResources}) => { 
          
        return (
            <>
            
            <div className="sticky flex  "> 

                    <Menu className="  ">
                            {({ open }) => (
                                <>
                            
                                <span className="  px-2 py-2 w-full hidden md:block" >{label}</span> 
                                <Menu.Button className={"w-full justify-between flex  md:hidden text-sm  px-2 py-2 rounded-md hover:bg-blue-600 focus:outline-none " + (open && "bg-blue-600")} id="user-menu">
                                    <span className="w-full" >{label}</span> <ChevronDownIcon className="w-5" />
                                </Menu.Button>
                                
                                
                            
                                <Transition className="absolute mx-auto w-full  top-18 "
                                show={open}
                                enter="transition ease-out duration-100 transform"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="transition ease-in duration-75 transform"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                                >
                                <Menu.Items
                                static
                                className=" w-64 mb-2 bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg outline-none"
                                >
                            

                                <div className="py-1">
                                    {resources && resources.map((item,itemIdx) => (
                                        <Menu.Item key={itemIdx}>
                                        {({ active }) => (
                                            <button  onClick={() => setVisibleResources([resources[itemIdx]])}
                                            className={`${
                                                item.timetable_studio_room_name === label
                                                ? "bg-gray-100 text-gray-900"
                                                : "text-gray-800"
                                            } flex justify-between w-full px-4 py-2 text-sm leading-5 text-left`}
                                            >
                                                {item.timetable_studio_room_name}
                                            </button>
                                        )}
                                        </Menu.Item>
                                    ))}
                                    
                                </div>

                                
                                </Menu.Items>
                            </Transition>
                            </>
                            )}
                            
                            </Menu>
                 

            </div>


            </>
        )
   }
  