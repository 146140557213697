
import {Transition} from "@headlessui/react"; 

export const Shimmer = (props) => {
    const loading = props.loading
    
    //backdrop-filter backdrop-blur-sm
    return (
    <Transition show={loading} className="absolute z-150 inset-0 top-0 overflow-y-auto h-1  ">
            <div className="flex flex-wrap h-full items-end overflow-y-auto w-full text-center ">
               
                <Transition.Child
                    className="inline-block align-bottom  text-left overflow-hidden  transform transition-all  w-full h-full "
                    role="dialog" aria-modal="true" aria-labelledby="modal-headline"
                    enter="ease-out duration-50"
                    enterFrom="opacity-0  "
                    enterTo="opacity-100 "
                    leave="ease-in duration-50"
                    leaveFrom="opacity-100  "
                    leaveTo="opacity-0  "
                >
                    
                    <div  className="flex flex-1 flex-wrap w-full h-full items-center  ">
                        <div className="h-full w-full text-center flex flex-col justify-center items-center  "> 
                            
                                <div className=" h-2   shadow-md animate-width absolute bg-yellow-300  left-0 " />

                        </div>
                    </div>
                </Transition.Child>
                 
                
            </div>
        </Transition>
        
    )
}