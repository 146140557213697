
import React, {  forwardRef } from "react"; 
import moment from 'moment'
import 'moment/locale/cs';
import DatePicker, { registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import {cs} from 'date-fns/esm/locale';
registerLocale('cs', cs);

export const DateSelect = ({startDate,changeDate}) => {
    const DateInput = forwardRef(
        ({ value, onClick }, ref) => (
            <button className="p-2 pl-4  ml-0 border-2 bg-gray-100 focus:outline-none focus:border-blue-200 hover:bg-blue-200 focus:bg-blue-100 flex justify-between rounded-md"  onClick={onClick} ref={ref}>
            <svg version="1.1" className="hidden lg:inline w-5 mx-2 h-5 text-blue-700" fill="currentColor" stroke="none" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
                viewBox="0 0 29.121 29.121"  >
                
                <path d="M21.706,6.146c1.116,0,2.02-0.898,2.02-2.016V2.02c0-1.119-0.903-2.02-2.02-2.02
                    s-2.019,0.9-2.019,2.02v2.111C19.688,5.248,20.59,6.146,21.706,6.146z"/>
                <path  d="M28.882,3.494h-4.066v1.027c0,1.695-1.377,3.076-3.075,3.076c-1.7,0-3.074-1.381-3.074-3.076V3.494
                    h-8.205v1.027c0,1.695-1.379,3.076-3.076,3.076s-3.075-1.38-3.075-3.076V3.494L0.208,3.443v25.678H2.26h24.604l2.049-0.006
                    L28.882,3.494z M26.862,27.076H2.26V10.672h24.604v16.404H26.862z"/>
                <path  d="M7.354,6.146c1.116,0,2.021-0.898,2.021-2.016V2.02C9.375,0.9,8.47,0,7.354,0S5.336,0.9,5.336,2.02
                    v2.111C5.336,5.248,6.237,6.146,7.354,6.146z"/>
                <rect x="10.468" y="12.873"  width="3.231" height="2.852"/>
                <rect x="15.692" y="12.873"  width="3.234" height="2.852"/>
                <rect x="20.537" y="12.873"  width="3.231" height="2.852"/>
                <rect x="10.468" y="17.609"  width="3.231" height="2.85"/>
                <rect x="15.692" y="17.609"  width="3.234" height="2.85"/>
                <rect x="20.537" y="17.609"  width="3.231" height="2.85"/>
                <rect x="10.468" y="22.439"  width="3.231" height="2.85"/>
                <rect x="5.336" y="17.609"  width="3.229" height="2.85"/>
                <rect x="5.336" y="22.439"  width="3.229" height="2.85"/>
                <rect x="15.692" y="22.439"  width="3.234" height="2.85"/>
                <rect x="20.537" y="22.439"  width="3.231" height="2.85"/>
                </svg>
            {value}

            <svg className="flex-shrink-0 ml-3 h-5 w-5 text-gray-400 " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
            </svg>
            </button>
        ),
    );
     
    return (
        <DatePicker
        selected={moment(startDate).toDate()}
        locale="cs"
        dateFormat="d. MMMM. yyyy"
        onChange={date => changeDate(date) }
        customInput={<DateInput  />} 
        calendarClassName="bg-white "
        />
    );
};