import React, {useContext } from "react"; 
import { Menu,Transition } from '@headlessui/react'; 
import { SettingsPage } from '../settings/Settings';
import { AuthContext } from "../../context/context";
import { UserSettings } from "../users/userSettings/UserSettings";

export default function User({setUser, setSlideModalContent, setSlideModalIsOpen}) { 
    const userData = JSON.parse(localStorage.getItem('user'));  
    const user = userData?.data;
    
    const authContext = useContext(AuthContext);
    function logout() {
        authContext.logout();
    }

    function settings() {
        setSlideModalContent({
            component: SettingsPage,
            params: {
            }
          })
      
          setSlideModalIsOpen(true) 
    }

    function userSettings() {
        setSlideModalContent({
            component: UserSettings,
            params: {
            }
          })
      
          setSlideModalIsOpen(true) 
    }
 
    return(
        <>
            <Menu>
                        {({ open }) => (
                            <>
                        
                            {user 
                            ?  
                            <Menu.Button className={'py-2 px-4 rounded-md text-left w-full focus:outline-none transition duration-150 ease-in-out  hover:text-gray-200 hover:bg-gray-900  ' +  
                             (open ? ' bg-gray-900  text-white '  : 'lg:text-gray-200')} id="user-menu">
                            <div className="flex items-center">
                                <div className="">
                                {user.img ? (
                                        <img src={user.img} alt="" className="h-8 w-8 fill-current border-2  rounded-full"  />
                                    ) : (
                                        <svg className="h-8" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                        </svg>
                                    )
                                    }
                                </div>
                                <div className="ml-3 md:block">
                                    <p className="text-sm">
                                    {user.fname} {user.surname}
                                    </p>
                                     
                                </div>
                            </div>
                            </Menu.Button>
                            : 
                            <Menu.Button className="flex text-sm border-2 px-2 py-2 text-blue-700  rounded-full focus:outline-none active:border-blue-500 focus:border-blue-500 " id="user-menu">
                            <svg className="h-6 w-6 fill-current text-blue-500"  x="0px" y="0px" viewBox="0 0 20 20" >
                            
                                <path d="M7.725,2.146c-1.016,0.756-1.289,1.953-1.239,2.59C6.55,5.515,6.708,6.529,6.708,6.529s-0.313,0.17-0.313,0.854
                                C6.504,9.1,7.078,8.359,7.196,9.112c0.284,1.814,0.933,1.491,0.933,2.481c0,1.649-0.68,2.42-2.803,3.334C3.196,15.845,1,17,1,19v1
                                h18v-1c0-2-2.197-3.155-4.328-4.072c-2.123-0.914-2.801-1.684-2.801-3.334c0-0.99,0.647-0.667,0.932-2.481
                                c0.119-0.753,0.692-0.012,0.803-1.729c0-0.684-0.314-0.854-0.314-0.854s0.158-1.014,0.221-1.793c0.065-0.817-0.398-2.561-2.3-3.096
                                c-0.333-0.34-0.558-0.881,0.466-1.424C9.439,0.112,8.918,1.284,7.725,2.146z"/> 
                            </svg>
                            </Menu.Button>
                            }
                            
                        
                            <Transition className="absolute right-0 top-18 "
                            show={open}
                            enter="transition ease-out duration-100 transform"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="transition ease-in duration-75 transform"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                            >
                            <Menu.Items
                            static
                            className=" w-80 lg:w-64 mb-2 bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg outline-none"
                            >
                            
                            <div className="px-4 py-3">
                                <p className="text-sm leading-5">Přihlášený uživatel</p>
                                <p className="text-sm font-medium leading-5  truncate">
                                {user && user.email}
                                </p>
                            </div>

                            <div className="py-1">
                                <Menu.Item>
                                {({ active }) => (
                                    <button onClick={() => settings()}
                                    className={`${
                                        active
                                        ? "bg-gray-100 text-gray-900"
                                        : "text-gray-800"
                                    } flex justify-between w-full px-4 py-2 text-sm leading-5 text-left`}
                                    >
                                    Nastavení systému
                                    </button>
                                )}
                                </Menu.Item>
                            </div> 
                            <div className="py-1">
                                <Menu.Item>
                                {({ active }) => (
                                    <button onClick={() => userSettings()}
                                    className={`${
                                        active
                                        ? "bg-gray-100 text-gray-900"
                                        : "text-gray-800"
                                    } flex justify-between w-full px-4 py-2 text-sm leading-5 text-left`}
                                    >
                                    Nastavení uživatele
                                    </button>
                                )}
                                </Menu.Item>
                                
                            </div>

                            <div className="py-1">
                                <Menu.Item>
                                {({ active }) => (
                                    <button
                                   
                                    className={`${
                                        active
                                        ? "bg-gray-100 text-gray-900"
                                        : "text-gray-800"
                                    } flex w-full px-4 py-2 text-sm leading-5 text-left`}
                                    onClick={() => logout(setUser)}
                                    >
                                        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" 
                                            viewBox="0 0 511.996 511.996" className="h-5 w-5 mr-2 inline" fill="currentColor" stroke="none">
                                                <path d="M349.85,62.196c-10.797-4.717-23.373,0.212-28.09,11.009c-4.717,10.797,0.212,23.373,11.009,28.09
                                                        c69.412,30.324,115.228,98.977,115.228,176.035c0,106.034-85.972,192-192,192c-106.042,0-192-85.958-192-192
                                                        c0-77.041,45.8-145.694,115.192-176.038c10.795-4.72,15.72-17.298,10.999-28.093c-4.72-10.795-17.298-15.72-28.093-10.999
                                                        C77.306,99.275,21.331,183.181,21.331,277.329c0,129.606,105.061,234.667,234.667,234.667
                                                        c129.592,0,234.667-105.068,234.667-234.667C490.665,183.159,434.667,99.249,349.85,62.196z"/>
                                                <path d="M255.989,234.667c11.782,0,21.333-9.551,21.333-21.333v-192C277.323,9.551,267.771,0,255.989,0
                                                        c-11.782,0-21.333,9.551-21.333,21.333v192C234.656,225.115,244.207,234.667,255.989,234.667z"/>
                                        </svg> 
                                    Odhlásit
                                    </button>
                                )}
                                </Menu.Item>
                            </div>
                            </Menu.Items>
                          </Transition>
                          </>
                          )}
                          
                        </Menu>


                        <div className="py-1 md:hidden">
                                
                                    <button
                                   
                                    className={`${
                                          "text-gray-800"
                                    } flex w-full px-4 py-2 text-sm leading-5 text-left`}
                                    onClick={() => logout(setUser)}
                                    >
                                        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" 
                                            viewBox="0 0 511.996 511.996" className="h-5 w-5 mr-2 inline" fill="currentColor" stroke="none">
                                                <path d="M349.85,62.196c-10.797-4.717-23.373,0.212-28.09,11.009c-4.717,10.797,0.212,23.373,11.009,28.09
                                                        c69.412,30.324,115.228,98.977,115.228,176.035c0,106.034-85.972,192-192,192c-106.042,0-192-85.958-192-192
                                                        c0-77.041,45.8-145.694,115.192-176.038c10.795-4.72,15.72-17.298,10.999-28.093c-4.72-10.795-17.298-15.72-28.093-10.999
                                                        C77.306,99.275,21.331,183.181,21.331,277.329c0,129.606,105.061,234.667,234.667,234.667
                                                        c129.592,0,234.667-105.068,234.667-234.667C490.665,183.159,434.667,99.249,349.85,62.196z"/>
                                                <path d="M255.989,234.667c11.782,0,21.333-9.551,21.333-21.333v-192C277.323,9.551,267.771,0,255.989,0
                                                        c-11.782,0-21.333,9.551-21.333,21.333v192C234.656,225.115,244.207,234.667,255.989,234.667z"/>
                                        </svg> 
                                    Odhlásit
                                    </button>
                                 
                            </div>
                        
        </>
    )
}