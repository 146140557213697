import { PlusCircleIcon } from "@heroicons/react/solid";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { addToCart }  from "../../cart/cart";  
import { Loading } from "../../layout/Loading";

export const Service = ({service,cartId,setItems,setCart,price,client}) => {

    const [loading, setLoading] = useState(false);

    const { register, handleSubmit, setValue } = useForm({
        mode: "onBlur"
    });
    const onSubmit = async data => {
        setLoading(true); 
        let result = await addToCart(cartId,data,'services',client)
        setItems(result.items)
        setCart(result.cart)
        setLoading(false);
    }; 

    useEffect(() => {  
       price && service.services_id == price?.serviceId &&
       setValue('price',price?.price)
      }
      // eslint-disable-next-line 
    , []);

    return (
        <>
            <form  onSubmit={handleSubmit(onSubmit)} >
            <input name="id" type="hidden"  {...register('id',{ required: true })} defaultValue={service.services_id} />
            <input name="price" type="hidden"  {...register('price',{ required: true })} defaultValue={service.services_price_vat} />
                <div className="  grid grid-cols-10 gap-3 items-center p-2 sm:text-sm hover:bg-gray-100" title={service.services_name}>
                    <div className="col-span-12 sm:col-span-7 overflow-hidden truncate">
                    
                        <span  className=" border-gray-300 rounded-md " >
                            
                            {service.services_name}
                        </span>
                    </div>

                    <div className="col-span-12 sm:col-span-3 text-right grid grid-cols-3 items-center gap-2">
                       <span className="col-span-2 text-sm font-medium">
                           {price && service.services_id == price?.serviceId ? (
                            <>{parseInt(price?.price).toLocaleString('cs')} Kč</>
                           ) : (
                            <>{parseInt(service.services_price_vat).toLocaleString('cs')} Kč</>
                           )}
                            
                        </span>

                        <button className=" text-blue-600   hover:text-blue-800 flex items-center focus:outline-none">
                            <PlusCircleIcon className={"w-6 " + (loading && "opacity-50")} />
                        </button>
                    </div>
                </div> 
                </form>
                <Loading loading={loading} />
        </>
    )
}