import { CameraIcon } from "@heroicons/react/outline";
import { useRef, useState } from "react";
import { ModalLayout } from "../layout/modal";
import Scanner from "./Scanner";


export const ScannerModal = ({confirmBarcode}) => {
    const [result, setResult] = useState();
    const [scanning, setScanning] = useState(false);
    const scannerRef = useRef(null);

    
   
        return (
             <> 
               
               <ModalLayout title={'Skenovat'}>
                   <div className="w-full overflow-hidden relative">
                    <div className="px-10 py-5 flex justify-center items-center">
                        
                        {result ? <>
                            <div className="grid gap-3">
                                <div className="p-5 text-3xl text-center font-medium">{result}</div>
                                <button onClick={() => confirmBarcode(result) } 
                            className={"relative inline items-center px-10 py-2 border border-transparent shadow-sm  font-medium rounded-md text-white bg-green-600 hover:bg-green-700  focus:outline-none  mx-auto "}>Potvrdit</button>

                                <button className="block mt-8" onClick={() => (setScanning(false),setResult(false)) }>Znovu</button>
                            </div>
                            </>
                            : 
                        <> 
                           <button onClick={() => setScanning(!scanning) } 
                            className={"relative items-center px-10 py-2 border border-transparent shadow-sm  font-medium rounded-md text-white focus:outline-none flex space-x-4 " 
                            + (scanning ? "bg-yellow-600 hover:bg-yellow-700  " : "bg-green-600 hover:bg-green-700  ")}>
                                {scanning ? 'Zrušit' : <><CameraIcon className="w-5 h-5 opacity-70" /><span>Skenovat čárový kód</span></>}</button>
                        </>}
                    </div>
                    {!result &&
                    <div className="overflow-hidden">
                        <div ref={scannerRef} className={"relative h-64 overflow-hidden " + (scanning ? "block" : "hidden")}>
                                {scanning ? <Scanner scannerRef={scannerRef} onDetected={(result) => setResult(result)} /> : null}
                        </div> 
                        
                    </div>
                    }
                    </div>
                </ModalLayout>
       
            </>
        )
    }