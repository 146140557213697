
import React, { useState, useEffect, useRef } from "react"; 
import { NavLink } from "react-router-dom"; 
import { Popover,Transition } from '@headlessui/react';
import { useForm } from "react-hook-form";
import { DocumentTextIcon, ChevronRightIcon } from '@heroicons/react/outline'
import { axiosProtected } from "../../client";

export default function QuickMenu(props) {
    const { register, handleSubmit } = useForm();
    const onSubmit = data => {}; 
    const [orderList, setOrderList] = useState([]);   

    const response = props.response;
 
    
    useEffect(() => {
        if(response) { 
            if(response?.orders === 'update') {
                orderListUpdate();
            }
        }
        // eslint-disable-next-line
    }, [response])
 
    async function orderListUpdate() {
        try {
            const res = await (axiosProtected()).post('order/lastOrders/',{}); // Fetch the resource 
            res.data.orders &&
            setOrderList(res.data.orders); 
        // Do your JSON handling here
        } catch(err) {
            console.log(err);
            // This probably means your response is text, do you text handling here
        } 
    }

    function print(id) {
        props.setPrintContent({
            component: 'InvoicePrint',
            params: {
              itemId: id, 
            }
          })
        props.setPrintIsOpen(true)
    }
    
    function search(e) { 
        const val = e.target.value;
        let carts = [];
        carts = carts.filter((cart) => {
            let cartName = cart.id
            return cartName.indexOf(
                val) !== -1
        })  
    }
 
    
    useEffect(() => {
        orderListUpdate();        
    }
      // eslint-disable-next-line
    , []);
      
      return ( 
        <> 
            <Popover>
                {({ open, close }) => (
                    <>
               
                     
                    <Popover.Button className={'py-3 px-4 rounded-md text-left w-full focus:outline-none transition duration-150 ease-in-out  hover:text-gray-200 hover:bg-gray-900  ' +  
                        (open ? ' bg-gray-900  text-white '  : 'text-gray-200')} id="user-menu">
                    <div className="relative">
                        <DocumentTextIcon className="w-5 h-5 inline -mt-2 "  />
                        <svg className="w-6 h-6 -ml-4 inline absolute" xmlns="http://www.w3.org/2000/svg" fill="#1F2937" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                        </svg>
         
                    </div>
                    </Popover.Button>
                     
                    
                
                    <Transition className="absolute right-0 top-18  w-full  max-w-2xl lg:right-6 "
                    show={open}
                    enter="transition ease-out duration-100 transform"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="transition ease-in duration-75 transform"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                    >
                    
                     <div 
                    className="m-2 mt-1  mb-2 bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg outline-none"
                   
                    >
                    <div className="px-4 py-3 bg-gray-100 rounded-md">
                        <div className="mb-2 font-medium">
                            Objednávky
                        </div>

                        <form onSubmit={handleSubmit(onSubmit)}  className="relative">
                    
                            <input {...register('filter')} onChange={search}  onClick={() => {}} 
                            type="search" name="filter" autoComplete="off"   placeholder="Hledat" 
                            className="block w-full bg-white border border-gray-300 rounded-md py-2 pl-10 pr-3 text-sm placeholder-gray-500 focus:outline-none focus:text-gray-900 focus:placeholder-gray-400  focus:ring-1 focus:ring-blue-500 focus:border-blue-500 sm:text-sm" placeholder="Hledat" type="search" />
                        <div className="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center"> 
                            
                                <svg className="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
                                </svg> 
                            
                        </div>
                            
                        </form>
                    </div>

                    <Popover.Panel>
                    <div className="py-4 divide-y divide-gray-100 max-h-96  overflow-auto origin-top-right ">
                            {orderList && orderList.map((order,orderIdx) => (
                                     <div key={orderIdx} className="grid grid-cols-7 lg:grid-cols-8 hover:bg-gray-100 w-full  px-4 py-2 text-sm leading-5 text-left outline-none gap-2" >
                                        <NavLink to={'/order/' + order.number}  onClick={close} className="  col-span-3 lg:col-span-2 items-start" >
                                            <div className="truncate font-medium">{order.number}</div>
                                            <div className="truncate text-xs">{order.name}</div>
                                        </NavLink>
                                        
                                        <NavLink to={'/order/' + order.number}  onClick={close} className="  pl-3 col-span-3 lg:col-span-5 text-xs grid items-center  ">
                                        {order.items.map((item,itemIdx) => (
                                             <div key={itemIdx} className="flex justify-between overflow-hidden space-x-2 text-left outline-none  " >
                                                <span className="truncate" >
                                                {item.name}
                                                </span>
                                                <span className="w-24 text-right truncate text-xs">
                                                {Math.round(item.price_vat).toLocaleString('cs')} Kč 
                                                </span>
                                                
                                                </div> 
                                        
                                             ))} 
                                        </NavLink>
                                        
                                        <div className="grid grid-cols-2 items-center">
                                              <div className="flex items-center">
                                         {order.invoices.map((invoice,invoiceIdx) => (
                                              
                                            <button  key={invoiceIdx} onClick={() => print(invoice.invoice_id)} title={invoice.invoice_number}>
                                                <svg version="1.1" fill="currentColor" className="w-5 h-5" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 427.058 427.058"  > 
                                                        <path d="M364.845,11.045L364.845,11.045c-7.148-3.292-15.569-2.012-21.416,3.254l-27.754,24.575l-37.76-33.777
                                                            c-7.586-6.796-19.071-6.796-26.657,0l-37.729,33.745L175.791,5.097c-7.588-6.796-19.074-6.796-26.662,0l-37.77,33.777L83.579,14.3
                                                            c-8.197-7.286-20.749-6.547-28.035,1.65c-3.262,3.67-5.049,8.419-5.015,13.329v368.5c-0.065,10.983,8.786,19.939,19.769,20.004
                                                            c4.911,0.029,9.66-1.761,13.331-5.024l27.754-24.575l37.76,33.777c7.586,6.796,19.071,6.796,26.657,0l37.729-33.749l37.735,33.745
                                                            c7.588,6.795,19.074,6.795,26.662,0l37.77-33.776l27.78,24.574c8.196,7.288,20.748,6.552,28.035-1.644
                                                            c3.265-3.671,5.052-8.423,5.018-13.336V29.279C376.628,21.412,372.033,14.242,364.845,11.045z M328.905,373.201
                                                            c-7.587-6.721-19.007-6.691-26.558,0.071l-37.759,33.776l-37.73-33.745c-7.587-6.796-19.073-6.796-26.66,0l-37.734,33.745
                                                            l-37.775-33.776c-7.522-6.761-18.922-6.792-26.481-0.072l-27.679,24.568v-53.305l-0.1-315.179l27.724,24.569
                                                            c7.587,6.721,19.007,6.691,26.558-0.071l37.759-33.776l37.73,33.745c7.587,6.796,19.073,6.796,26.66,0l37.734-33.745
                                                            l37.775,33.776c7.522,6.761,18.922,6.792,26.481,0.072l27.679-24.575v253.312l0.1,115.179L328.905,373.201z"/> 
                                                        <path d="M308.322,203.527H118.736c-5.523,0-10,4.477-10,10s4.477,10,10,10h189.586c5.523,0,10-4.477,10-10
                                                            S313.844,203.527,308.322,203.527z"/> 
                                                        <path d="M218.322,143.527h-99.586c-5.523,0-10,4.477-10,10s4.477,10,10,10h99.586c5.523,0,10-4.477,10-10
                                                            S223.844,143.527,218.322,143.527z"/> 
                                                        <path d="M308.322,263.527H118.736c-5.523,0-10,4.477-10,10c0,5.523,4.477,10,10,10h189.586c5.523,0,10-4.477,10-10
                                                            C318.322,268.004,313.844,263.527,308.322,263.527z"/> 
                                                </svg>

                                            </button>
                                             
                                            ))} 
                                            </div>
                                            <NavLink to={'/order/' + order.number} onClick={close} ><ChevronRightIcon className="w-6 h-6 text-gray-600 bg-gray-200 hover:bg-gray-300 rounded-full p-1 inline"  />
                                            </NavLink>
                                                 
                                            
                                        </div>

                                    </div>
                                    
                                    
                    
                                    
                                ))} 
                    </div>
                    </Popover.Panel>

                    
                    </div>
                     
                    </Transition>
                    </>
                    )}
                    
                </Popover>

        </>
      )

   }