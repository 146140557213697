import { useState } from "react";
import Settings from "../../services/Settings";

export const SettingsPage = (props) => {
     // eslint-disable-next-line
    const [settings, setSettings] = useState(JSON.parse(localStorage.getItem('settings')));

    function settingsRefetch() {
        setSettings(Settings.getSettings());
    }
 
    return (
        <>
        <div  className="py-28 pt-36" > 
            <div className="bg-white   border-gray-200  text-right  absolute top-0 right-0 w-full">
                <div className="py-2 px-4 bg-blue-700 sm:px-6 h-20 flex items-center">
                    <div className="flex items-center justify-between ">
                        <h2 className="text-2xl font-medium text-white" id="slide-over-title">
                         Nastavení systému
                        </h2>
                        
                    </div> 
                </div> 

                <div className="mb-3 px-6 py-3 lg:px-12 ">

                    <div className="relative">
                    
                        <div className="  flex-shrink-0 absolute right-1 lg:top-2 z-30 text-left"> 
                            <button onClick={() => settingsRefetch()} className="bg-gray-100 px-5 py-2 rounded-md hover:bg-gray-200">Aktualizovat</button>
                        </div>
                            
                            
                    </div>
                </div> 

                </div>
                            
            </div>
        </>
    )
}