import { StarIcon, UserGroupIcon } from '@heroicons/react/solid'
import { format } from 'date-fns'
import { cs } from 'date-fns/locale'
import { NewEvent } from '../newEvent'
import { ImBook } from "react-icons/im";

export const Lessons = ({lessons,event,roomData,resources,getEvents,selStudio,changeDate,setEventData,...props}) => {
    let id = event?.timetable_lessons_items_id
    
    function changeLesson(event) {
        setEventData(false)
        props.setSlideModalContent({
            component: NewEvent,
            params: {
                roomData: roomData, 
                resources: resources,
                start: event.start,
                end: event.end,
                event: event,
                resourceId: event.resourceId,
                location: props.location,  
                getEvents: getEvents,
                selStudio: selStudio,
                changeDate: changeDate
            }
        })

    }

    const isPast = (date) => {
        let res = false; 
 
        if(date && format(new Date(date), 'yyyy-MM-dd', {locale: cs}) < format(new Date, 'yyyy-MM-dd', {locale: cs})) {
            res = true;
        }
       
        return res
    }
  
   
    return(
        <> 
        {lessons && 
        <div>
            <label className="block text-gray-700 text-sm font-medium">Lekce této řady</label>
            <div className="  mt-2 text-sm ">
                {lessons.map((item,i) => (
                    <button key={i} className={"flex items-center space-x-4 hover:bg-gray-100 w-full py-1 px-2 focus:outline-none rounded-md mb-1 justify-between " + 
                        (id && id === item.id ? "text-black bg-gray-100" : isPast(item?.date) ? "text-gray-400 " : "text-gray-600")} onClick={() => changeLesson(item)}> 
                        <div className="w-4"> {id && id === item.id && <><StarIcon className="w-4 text-yellow-600" /></>}
                        </div>
                        <div className=" w-24">{format(new Date(item?.date), 'dd. MM. yyyy', {locale: cs})}</div>
                        <div className=" sm:w-52 text-left">{item.name}</div>
                         
                        <div className="flex  items-center  space-x-2 font-medium text-xs text-gray-500">
                             
                                {/* <div className="flex items-center space-x-1 w-1/3" title="Přítomní">
                                    <UserGroupIcon className="w-3 " /><span>{item?.present ? item.present : "0"}</span>
                                </div>
                                <div className="flex items-center space-x-1  w-1/3" title="Rezervace">
                                    <ImBook className="w-3  " /><span>{item?.booking ? item.booking : "0"}</span>
                                </div>
                                <div className="flex items-center space-x-1  w-1/3" title="Volná místa">
                                    
                                        <svg className="w-3  " fill="currentColor"  viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">  
                                                    <path d="M400.084,252H364V121.9c16-4.956,24-18.26,24-33.9V32c0-19.848-12.068-32-31.916-32h-192C144.236,0,124,12.152,124,32v56
                                                        c0,15.64,12,28.94,24,33.9V252h-27.916c-6.616,0-16.084,1.388-16.084,8v24c0,6.616,4,12,12,12v208c0,2.208,5.876,8,8.084,8h24
                                                        c2.208,0-0.084-5.792-0.084-8V364h216v140c0,2.208,5.876,8,8.084,8h24c2.208,0-0.084-5.792-0.084-8V296c8,0,12-5.384,12-12v-24
                                                        C408,253.388,406.704,252,400.084,252z M172,128h168v124H172V128z M364,340H148v-40h216V340z"/>
                                            
                                            </svg>

                                        <span>{item.rest ? item.rest : "0"}</span>
                                </div> */}
                            
                            
                        </div>
                    </button>   
                ))}
            </div>
        </div>
        }
        </>
    )
}