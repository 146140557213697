
import React, { useState, useEffect, useRef, useCallback } from "react";  
import Goods from '../cart/goods';    
import { Shimmer } from "../layout/Shimmer";
import useFetch from "../../services/useFetch";
import {EmptyList} from "../elements/EmptyList";
import { Spin } from "../elements/Spin";
import { ProductFilter } from "./ProductFilter"; 
import { Product } from "./Product";

export default function Products({cartId, addItem, cat, hideHeader, searchQuery}) { 
   
    //const [goods, setGoods] = useState([]); 
    const [page, setPage] = useState(1);
    const [query, setQuery] = useState();
    const [params, setParams] = useState([]);
 
    const [openStatus, setOpenStatus] = useState({});
    const { loading, error, list, hasMore, catTags } = useFetch(query, page, params); 
   
    

    useEffect(() => { 
      searchQuery && setQuery(searchQuery); 
    }, [searchQuery]);   

    useEffect(() => {
      (async() => {
        let category = '';
        if(cat) category = cat + '/';
        await setQuery('goods/items/' + category);
        await setPage(1);
        await setParams([]);
      })()
    }, [cat]);
 
    useEffect(() => {
      (async() => { 
        await setPage(1); 
      })()
    }, [params]);
 

    
    const observer = useRef();
    const lastElementRef = useCallback(
      (node) => {
        if (loading) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver((entries) => {
          if (entries[0].isIntersecting && hasMore) {
            setPage((prev) => prev + 1);
          }
        });
        if (node) observer.current.observe(node); 
      },
      [loading, hasMore]
    ); 
    
 
    return (
    <>
    {!hideHeader && <ProductFilter cat={cat} catTags={catTags} params={params} setParams={setParams} openStatus={openStatus} setOpenStatus={setOpenStatus} />}
     
        <div className={"mt-4 w-full text-gray-500 sm:mt-6 " + (list && list.length > 0 ? "block" : "hidden")}> 
                  {/* <div className="sr-only text-sm text-gray-500 text-left sm:not-sr-only">
                    <div className="flex justify-between">
                      <div scope="col" className="sm:w-2/5 lg:w-1/3 pr-8 py-3 font-normal">
                        Product
                      </div>
                      <div scope="col" className="hidden w-1/5 pr-8 py-3 font-normal sm:table-cell">
                        Price
                      </div>
                      <div scope="col" className="hidden pr-8 py-3 font-normal sm:table-cell">
                        Status
                      </div>
                      <div scope="col" className="w-0 py-3 font-normal text-right">
                        Info
                      </div>
                    </div>
                  </div>  */}
                  
                <div className="border-b border-gray-200 divide-y divide-gray-200 text-sm sm:border-t">
                {list && list.length > 0 && list.map((item,itemIdx) => ( 
                  <Product key={itemIdx} item={item} list={list} itemIdx={itemIdx} addItem={addItem} hasMore={hasMore} lastElementRef={lastElementRef} />
                ))}
                
             </div>
        </div>
    
    {list && list.length === 0 && !loading && <EmptyList text={'Žádné položky'} />}
    {loading && <Spin />}
    {error && <p>Error!</p>} 
                
    
            <Shimmer loading={loading} />
                        
                      
    </> 
    )
}