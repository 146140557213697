import React, { useState, useEffect, useRef } from 'react'
 
 
const Stepper = ({ steps, currentStepNumber }) => {
 
    const [stepperSteps, setStep] = useState([]);
    const stepsStateRef = useRef();
 
    useEffect(() => {
        const stepsState = steps.map((step, index) => {
            const stepObj = {};
            stepObj.description = step;
            stepObj.completed = false;
            stepObj.highlighted = index === 0 ? true : false;
            stepObj.selected = index === 0 ? true : false;
            return stepObj;
        });
 
        stepsStateRef.current = stepsState;
 
        const currentSteps = updateStep(currentStepNumber - 1, stepsState)
        setStep(currentSteps)
    }, [currentStepNumber,steps]);
 
 
    useEffect(() => {
        const currentSteps = updateStep(currentStepNumber - 1, stepsStateRef.current)
        setStep(currentSteps)
    }, [currentStepNumber]);
 
 
    function updateStep(stepNumber, steps) {
        const newSteps = [...steps];
 
        let stepCounter = 0;
        while (stepCounter < newSteps.length) {
            //current step 
            if (stepCounter === stepNumber) {
                newSteps[stepCounter] = {
                    ...newSteps[stepCounter],
                    highlighted: true,
                    selected: true,
                    completed: false
                };
                stepCounter++;
            }
            // Past step
            else if (stepCounter < stepNumber) {
                newSteps[stepCounter] = {
                    ...newSteps[stepCounter],
                    highlighted: false,
                    selected: true,
                    completed: true
                };
                stepCounter++;
            }
            // Future steps 
            else {
                newSteps[stepCounter] = {
                    ...newSteps[stepCounter],
                    highlighted: false,
                    selected: false,
                    completed: false
                }
                stepCounter++;
            }
        }
        return newSteps
    }
 
 
    const stepsDisplay = stepperSteps.map((step, index) => {
 
        return (
             
                <li className="flex-1" key={index} > 
                    {step.completed ? (<button className="group py-2 flex flex-col border-blue-600 hover:border-blue-800 pl-0 pt-4 pb-0 border-t-4">
                        <span className="text-xs text-blue-600 font-semibold uppercase group-hover:text-blue-800">{index+1}. krok </span>
                        <span className="text-sm  font-medium">{step.description}</span>
                    </button>) : (<></>) }
                    
                    {step.selected && !step.completed ? (<button className="group py-2 flex flex-col border-blue-600 pl-0 pt-4 pb-0 border-t-4">
                        <span className="text-xs text-blue-600 font-semibold uppercase">{index+1}. krok</span>
                        <span className="text-sm text-blue-600 font-medium">{step.description}</span>
                    </button>) : (<></>) } 

                    {!step.selected && !step.completed ? (
                    <button className="group py-2 flex flex-col border-gray-200 hover:border-gray-300 pl-0 pt-4 pb-0 border-t-4">
                        <span className="text-xs text-gray-500 font-semibold uppercase group-hover:text-gray-700">{index+1}. krok</span>
                        <span className="text-sm font-medium">{step.description}</span>
                    </button>
                    ) : (<></>) }    
                </li>
              
                
        )
    })
 
    return (
        <nav aria-label="Progress">
            <ol className="flex flex-wrap content-start space-y-0 space-x-4">
                {stepsDisplay}
            </ol>
        </nav>
    )
}
 
export default Stepper