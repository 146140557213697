import React, { Fragment } from "react";
import { Listbox, Transition } from '@headlessui/react'
import { classNames } from "../../services/classnames";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/solid";

export const SelectCoach = ({title, selected, updateItem, data, except, substOn}) => {
     
    return (
        <>
        <label htmlFor="lesson" className="block text-sm font-medium text-gray-700 mb-2">
          {title}
        </label>
        <div className="mt-1 h-14 ">
        <div className="w-full lg:w-64 ">
        <Listbox value={selected} onChange={updateItem}>
            {({ open }) => (
            <>
                <div className="relative mt-1">
                <Listbox.Button className={() => 
                    classNames(
                        ' border rounded-md h-11  py-1 px-4 relative w-full  pl-3 pr-10 text-left bg-white shadow-sm cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-blue-500 focus-visible:ring-offset-blue-300 focus-visible:ring-offset-2 focus-visible:border-blue-500 ',
                        open ? 'border-blue-500 ring-2 ' : substOn ? ' border-gray-300 opacity-50 ' : 'border-gray-300 ',
                    )
                    }>
                    <span className="block ">{data[selected] ? <div className="flex items-center space-x-4">
                        {data[selected]?.mod_instruktori_img ? <div className="w-9 h-9 rounded-full block border-2 overflow-hidden"><img alt="" src={data[selected]?.mod_instruktori_img} className=" w-full  border-gray-300" /></div> : ""}
                    <span className="truncate ">{data[selected]?.mod_instruktori_jmeno + ' ' + data[selected]?.mod_instruktori_prijmeni}</span></div> : <span className="opacity-50">bez lektora</span>}</span>
                    <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <ChevronDownIcon
                        className="w-5 h-5 text-gray-400"
                        aria-hidden="true"
                    />
                    </span>
                </Listbox.Button>
                <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <Listbox.Options
                    static
                    className="absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-50"
                    >
                        <Listbox.Option
                        key={-1}
                        className={({ active }) =>
                            `${
                            active
                                ? "text-blue-900 bg-blue-100"
                                : "text-gray-900"
                            }
                            cursor-default select-none relative py-2 pl-10 pr-4`
                        }
                        value=""
                        >
                        {({ selected, active }) => (
                            <>
                            <span
                                className={`${
                                selected ? "font-medium" : "font-normal"
                                } block truncate`}
                            >
                                bez lektora
                            </span>
                            {selected ? (
                                <span
                                className={`${
                                    active ? "text-blue-600" : "text-blue-600"
                                }
                                    absolute inset-y-0 left-0 flex items-center pl-3`}
                                >
                                <CheckIcon
                                    className="w-5 h-5"
                                    aria-hidden="true"
                                />
                                </span>
                            ) : null}
                            </>
                        )}
                        </Listbox.Option>
                    {data && data.map((item, i) => (
                        <Listbox.Option
                        key={i}
                        className={({ active }) =>
                            `${
                                active
                                ? "text-blue-900 bg-blue-100"
                                : "text-gray-900" + 
                                except && except===i && "hidden"
                            }
                            cursor-default select-none relative py-2 pl-10 pr-4 `
                        }
                        value={item.id}
                        >
                        {({ selected, active }) => (
                            <>
                            <span
                                className={`${
                                selected ? "font-medium" : "font-normal"
                                } block truncate`}
                            >
                                {item.mod_instruktori_jmeno + ' ' + item.mod_instruktori_prijmeni}
                            </span>
                            {selected ? (
                                <span
                                className={`${
                                    active ? "text-blue-600" : "text-blue-600"
                                }
                                    absolute inset-y-0 left-0 flex items-center pl-3`}
                                >
                                <CheckIcon
                                    className="w-5 h-5"
                                    aria-hidden="true"
                                />
                                </span>
                            ) : null}
                            </>
                        )}
                        </Listbox.Option>
                    ))}
                    </Listbox.Options>
                </Transition>
                </div>
            </>
            )}
        </Listbox>
        </div>

           
        </div>
        </>
    )
}
