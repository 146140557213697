
import { Disclosure, Menu } from '@headlessui/react'
import { useForm } from "react-hook-form"; 
import {  MinusSmIcon, PlusSmIcon, } from "@heroicons/react/solid";

export const GoodsTagComponent = ({defaultOpen, tagList, openStatus,setOpenStatus, params, close, setParams}) => {

    const { register, handleSubmit, setValue } = useForm();

    function confirmChanges(e) { 
        let value = e.target.value;
        let checked = e.target.checked; 
        let paramValues = [...params]
        
        if(checked) {
            paramValues.push(value)
        } else {
            const index = paramValues.indexOf(value);
            if (index > -1) {
                paramValues.splice(index, 1);
            }  
        } 
       // paramValues.push(value)
        setParams(paramValues);
         
     }

     function changeOpenStatus(alias,status) {
         var obj = openStatus;
         let openValue = false;
         if(obj?.[alias]) {
             openValue = obj?.[alias];
         }
         obj[alias] = !openValue 
         setOpenStatus(obj) 
     }

    return (
        <>
          <form className="px-4 py-3  rounded-md divide-y max-h-96  overflow-auto "> 

                 
        {tagList && tagList.length > 0 && tagList.map((section, sectionIdx) => {
            let openValue = false;
            if(openStatus?.[section.alias]) {
                openValue = true;
            }
            if(defaultOpen) {
                openValue = true;
            }

            return(
                <Disclosure defaultOpen={openValue} as="div" key={section.id} className={"   " + (section.values.length === 0 && "hidden")}> 
                {({ open }) => (
                <>
                    <h3 className="  " onClick={() => changeOpenStatus(section.alias,openValue)} >
                    <Disclosure.Button className="px-4 py-3 bg-white hover:bg-gray-100 rounded-md w-full flex items-center justify-between text-gray-400 hover:text-gray-500 focus:outline-none" >
                        <span className="font-medium text-gray-900">{section.name}</span>
                        <span className="ml-6 flex items-center">
                        {open ? (
                            <MinusSmIcon className="h-5 w-5" aria-hidden="true" />
                        ) : (
                            <PlusSmIcon className="h-5 w-5" aria-hidden="true" />
                        )}
                        </span>
                    </Disclosure.Button>
                    </h3> 
                    <Disclosure.Panel className="pt-2">
                    <div className=" ">
                        {section.values.map((option, optionIdx) => (
                        <Menu.Item as="div"  key={option.value} className="flex items-center hover:bg-gray-100 cursor-pointer py-2 px-2 rounded-md" >
                            
                            <input
                            id={`filter-mobile-${section.alias}-${optionIdx}`}
                            {...register(`tags[]`)}
                            name={`tags[]`}
                            defaultValue={option.id}
                            type="checkbox" 
                            onChange={(e) => confirmChanges(e)}
                            defaultChecked={params && params?.includes(option.id)}
                            className="h-4 w-4 border-gray-300 rounded text-blue-600 focus:ring-blue-500 cursor-pointer "
                            
                            />
                            <label
                            htmlFor={`filter-mobile-${section.alias}-${optionIdx}`}
                            className="pl-3 min-w-0 flex-1 text-gray-500  cursor-pointer"
                            >
                            {option.value}
                            </label>
                        </Menu.Item>
                        ))}
                    </div>
                    </Disclosure.Panel>
                </>
                )}
                </Disclosure>)
            })}
        </form>
    </>
    );
}