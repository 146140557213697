import React from 'react'
import Stepper from './stepper'
 
 
function Wizard(props) { 
    
    return (
        <>
            <div className="container horizontal mt-5 mb-12">
                <Stepper
                    steps={props.stepArray}
                    currentStepNumber={props.currentStep}
                />
            </div>
                    
            
        </>
    )
}
 
export default Wizard